import { SystemDate, SystemDateTimeSec, parseDate, parseDateAndTime } from '@cyren/common-lib';
import { differenceInCalendarDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { InputHTMLAttributes, memo, useEffect, useState } from 'react';
import { QuestionProps } from '../../pa-prop-types';

function QsInputOnset(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
    QuestionProps & {
      onChange: (value: string) => void;
    }
) {
  const {
    value,
    onChange,
    // className, error
  } = props;

  // const timeValue = value?.toString().substring(11);
  const [initDateValue, initTimeValue] = parseDateAndTime(value);

  const [dateValue, setDateValue] = useState(initDateValue);
  const [timeValue, setTimeValue] = useState(initTimeValue);

  const finalValue = dateValue ? `${dateValue} ${timeValue}` : '';

  useEffect(() => {
    if (dateValue && timeValue) {
      onChange(finalValue);
    }
  }, [finalValue]);

  const daysDiff = differenceInCalendarDays(
    parseDate(new Date(), SystemDate),
    parseDate(dateValue, SystemDate)
  );

  const hourDiff = differenceInHours(
    parseDate(new Date(), SystemDateTimeSec),
    parseDate(`${finalValue}:00`, SystemDateTimeSec)
  );

  const diffMins = differenceInMinutes(
    parseDate(new Date(), SystemDateTimeSec),
    parseDate(`${finalValue}:00`, SystemDateTimeSec)
  );

  const needTimeData = dateValue && daysDiff < 2;

  const isFuture = !!timeValue && !!dateValue && diffMins < 1;

  return (
    <div className='t_QsInputOnset flex-col gap-3'>
      {/* {hasValue && <span className='opacity-80 text-sm'>Please adjust the time</span>} */}
      <div className='flex gap-3'>
        <div>
          <input
            type='date'
            className='input input-bordered'
            value={dateValue}
            onChange={(e) => {
              const nValue = e.target.value;
              setDateValue(nValue);
            }}
            max={new Date().toISOString().split('T')[0]}
          />
        </div>
        {needTimeData && (
          <div>
            <input
              type='time'
              className='input input-bordered'
              value={timeValue}
              onChange={(e) => {
                const nValue = e.target.value;
                setTimeValue(nValue);
              }}
            />
          </div>
        )}
      </div>
      {!isFuture ? (
        <div>{hourDiff < 24 ? <span>{hourDiff} hours</span> : <span>{daysDiff} days</span>}</div>
      ) : (
        <div>
          <span className='text-error'>Should be in the past</span>
        </div>
      )}
    </div>
  );
}

export default memo(QsInputOnset);
