import { Enum_Staff_Jobfunction, OrgConfigurationValue, UsersPermissionsMe } from '@cyren/common-lib';
import { atom } from 'recoil';
import { TStaff } from '../graphql-enhanced';

export type AuthStateType = {
  jwt?: string | null;
  user?: UsersPermissionsMe | null;
  profiles?: TStaff[] | null;
  selectedProfile?: TStaff | null;
  jobFunctionOverride?: Enum_Staff_Jobfunction | null;
  orgConfigurationValues?: OrgConfigurationValue[]
} | null;

// export const tempUser = {
//   user: {
//     email: 'y@cyrencare.com',
//     displayName: 'Youngjae Ji',
//     username: 'youngjae',
//     id: '1',
//     profileUrl:
//       'https://s3.amazonaws.com/profile_photos/1203011298905970.1203011298934366.G7brVFh3dWH5s3Zx2F48_huge.jpeg',
//   },
//   jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.e',
// };

export const AuthState = atom<AuthStateType>({
  key: 'AuthState',
  // default: tempUser,
  default: null,
});
