import { InputHTMLAttributes, memo } from 'react';

import { Enum_Translation_Datatype } from '@cyren/common-lib';
import { useData } from '../../../report/use-data';
import { QuestionProps } from '../../pa-prop-types';
import { QuestionType } from '../../patient-types';
import QsCollapsibleGroup from './QsCollapsibleGroup';
import QsCollapsibleGroupItem from './QsCollapsibleGroupItem';

function QsCollapsible(
  props: InputHTMLAttributes<HTMLInputElement> &
    Omit<QuestionProps, 'question'> & {
      question: QuestionType;
    }
) {
  const { question } = props;

  const { answerKeys } = question;
  const [, { getTransByKey }] = useData();

  // console.log('selectedGroup', selectedGroup);
  // console.log('optionQuestion', optionQuestion);
  // console.log('optionAnswerKeys', optionAnswerKeys);

  return (
    <div className='t_QsCollapsible flex-1'>
      {answerKeys?.map((aKey) => {
        const trans = getTransByKey(aKey);

        if (trans?.dataType === Enum_Translation_Datatype.TreeSx) {
          return (
            <div key={aKey} className='border-b'>
              <QsCollapsibleGroupItem {...props} optionKey={aKey} />
            </div>
          );
        }

        return <QsCollapsibleGroup {...props} key={aKey} optionKey={aKey} />;
      })}
    </div>
  );
}

export default memo(QsCollapsible);
