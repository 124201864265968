import { SystemDate, SystemDateTimeSec, formatDate, parseDate } from '@cyren/common-lib';
import classNames from 'classnames';
import { differenceInDays, subDays, subHours, subMonths, subWeeks, subYears } from 'date-fns';
import { now, size } from 'lodash';
import { InputHTMLAttributes, memo, useEffect, useState } from 'react';
import { QuestionProps } from '../../pa-prop-types';

function parseDateAndTime(dateValue?: number | string | null | readonly string[]) {
  const parsedDateValue = dateValue?.toString().substring(0, 10);
  const initDateValue = size(parsedDateValue) === 10 ? parsedDateValue : '';
  const parsedTimeValue = dateValue?.toString().substring(11);
  const initTimeValue = size(parsedTimeValue) >= 5 ? parsedTimeValue?.substring(0, 5) : '';

  return [initDateValue, initTimeValue] as const;
}

function QsInputOnsetV2(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
    QuestionProps & {
      onChange: (value: string) => void;
    }
) {
  const {
    value,
    onChange,
    // className, error
  } = props;

  // const timeValue = value?.toString().substring(11);
  const [initDateValue, initTimeValue] = parseDateAndTime(value);

  const hasValue = !!value;

  const [dateValue, setDateValue] = useState(initDateValue);
  const [timeValue, setTimeValue] = useState(initTimeValue);

  const finalValue = dateValue ? `${dateValue} ${timeValue}` : '';

  useEffect(() => {
    if (finalValue) {
      onChange(finalValue);
    }
  }, [finalValue]);

  const daysDiff = differenceInDays(
    parseDate(new Date(), SystemDate),
    parseDate(dateValue, SystemDate)
  );

  const needTimeData = daysDiff < 2;

  return (
    <div className='t_QsInputOnsetV2 flex-col gap-3'>
      <div
        className={classNames(
          'flex gap-2 flex-wrap',
          'transition-all duration-150 ease-in-out overflow-hidden',
          hasValue ? 'max-h-0' : 'max-h-32'
        )}
      >
        <span
          className='badge cursor-pointer badge-lg badge-outline'
          onClick={() => {
            const v = subHours(now(), 3);
            const vv = formatDate(v, { formatStr: SystemDateTimeSec });

            const [dateStr, timeStr] = parseDateAndTime(vv);
            setDateValue(dateStr);
            setTimeValue(timeStr);
          }}
        >
          In hours
        </span>
        <span
          className='badge cursor-pointer badge-lg badge-outline'
          onClick={() => {
            const v = subDays(now(), 4);
            const vv = formatDate(v, { formatStr: SystemDateTimeSec });

            const [dateStr] = parseDateAndTime(vv);
            setDateValue(dateStr);
            setTimeValue('');
          }}
        >
          In days
        </span>
        <span
          className='badge cursor-pointer badge-lg badge-outline'
          onClick={() => {
            const v = subWeeks(now(), 3);
            const vv = formatDate(v, { formatStr: SystemDateTimeSec });

            const [dateStr] = parseDateAndTime(vv);
            setDateValue(dateStr);
            setTimeValue('');
          }}
        >
          In weeks
        </span>
        <span
          className='badge cursor-pointer badge-lg badge-outline'
          onClick={() => {
            const v = subMonths(now(), 4);
            const vv = formatDate(v, { formatStr: SystemDateTimeSec });

            const [dateStr] = parseDateAndTime(vv);
            setDateValue(dateStr);
            setTimeValue('');
          }}
        >
          In months
        </span>
        <span
          className='badge cursor-pointer badge-lg badge-outline'
          onClick={() => {
            const v = subYears(now(), 2);
            const vv = formatDate(v, { formatStr: SystemDateTimeSec });

            const [dateStr] = parseDateAndTime(vv);
            setDateValue(dateStr);
            setTimeValue('');
          }}
        >
          In years
        </span>
      </div>
      <div
        className={classNames(
          'flex-col gap-3',
          'transition-all duration-150 ease-in-out overflow-hidden',
          !hasValue ? 'max-h-0' : 'max-h-32'
        )}
      >
        <div>
          {hasValue && (
            <span className='opacity-80 text-sm'>Please finalize it by adjusting the time</span>
          )}
        </div>
        <div className='flex gap-3'>
          <div>
            <input
              type='date'
              className='input input-bordered'
              value={dateValue}
              onChange={(e) => {
                const nValue = e.target.value;
                setDateValue(nValue);
              }}
              max={new Date().toISOString().split('T')[0]}
            />
          </div>
          {needTimeData && (
            <div>
              <input
                type='time'
                className='input input-bordered'
                value={timeValue}
                onChange={(e) => {
                  const nValue = e.target.value;
                  setTimeValue(nValue);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(QsInputOnsetV2);
