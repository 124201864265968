import slugify from 'slugify';
import ctasDicts from '../../assets/files/ctas-dictionary.json';
import nonCtasTreeOptions from '../../assets/files/ctas-tree-non-ctas.json';
import ctasTreeOptions from '../../assets/files/ctas-tree-options.json';
const qsOptions = [...ctasTreeOptions, ...nonCtasTreeOptions, ...ctasDicts];
export function fetchQsPatientLocales() {
    const locList = fetchDictsLocales()
        .map((o) => {
        return {
            key: o.key,
            value: o.patient || o.common,
            locale: 'english',
        };
    })
        .filter((t) => !!t.key && !!t.value);
    // console.log('locList', locList);
    // console.log(
    //   'locList',
    //   locList.filter((t) => t.key === 'sx-urinary-retention')
    // );
    return locList;
}
export function fetchDictsLocales() {
    const locList = qsOptions.map((o) => {
        return {
            key: slugify(o['option-key'], { lower: true }),
            patient: o['patient-en'],
            doctor: o['doctor'],
            common: o['locale-en'],
            locale: 'english',
        };
    });
    return locList;
}
