import 'react-tiny-fab/dist/styles.css';
import './report-floating-actions.scss';
import { TPatientVisit } from '../../../graphql-enhanced';
import { size, startCase, union } from 'lodash';
import classNames from 'classnames';
import { Enum_Patientvisit_Status } from '@cyren/common-lib';
import { AiOutlineClockCircle } from 'react-icons/ai';

function StageProgressMeter(props: {
  visit: TPatientVisit;
}) {
  const { visit } = props;

  const surveyState = visit.surveyState?.surveyState;
  if (!surveyState) {
    return <></>;
  }

  const stages = union(surveyState.completedStages,
    surveyState.activeStage ? [surveyState.activeStage] : [],
    surveyState.unreachedStages);


  if (size(stages) === 0) {
    if (visit.status === Enum_Patientvisit_Status.Reporting) {
      // show the older clock icon if there are no stages in this survey
      return (
          <AiOutlineClockCircle size={20} title="Patient is writing the report"/>
        );
    }
    return <></>;
  }

  const stageLetters = stages.map(str => {
    // Remove the "stage-" prefix if it exists
    const newStr = str.startsWith("stage-") ? str.slice(6) : str;
    // Return the first character of the new string
    return newStr.charAt(0);
  });

  const completedStageCount = (surveyState.completedStages) ?
    surveyState.completedStages.length : 0;
  const activeStageCount = (surveyState.activeStage) ? 1 : 0;

  function getStageTitle(stageName: string, isCompleted: boolean, isActive: boolean) {
    let prefix = "";
    if (isCompleted) {
      prefix = "Completed Stage";
    }
    else if (isActive) {
      prefix = "Active Stage";
    }
    else {
      prefix = "Unreached Stage";
    }

    const displayStageName = startCase((stageName.startsWith("stage-") ? stageName.slice(6) : stageName).replace('-', ' '));
    return `${prefix}: ${displayStageName}`;
  }

  return (
    <div className="flex-row items-center">
      {stageLetters.map((stageLetter, idx) => {

        const isCompleted = idx < completedStageCount;
        const isActive = activeStageCount > 0 && idx === completedStageCount;
        const isUnreached = idx >= (completedStageCount + activeStageCount);
        const title = getStageTitle(stages[idx], isCompleted, isActive)
        return (
          <div key={idx} className="flex-row items-center">
            <div className={classNames(
              "rounded-full border w-4 h-4",
              "flex-center capitalize text-xs-extra",
              isCompleted && "bg-primary bg-opacity-20 border-secondary-content",
              isActive && "border-secondary-content",
              isUnreached && "border-neutral-content text-neutral-content",
            )}
              title={title} aria-label={title}>{stageLetter}</div>
            {(idx < (stageLetters.length - 1)) &&
              <div
                className="border-b border-b-2 border-b-gray w-3"
              />
            }
          </div>)
      })
      }
    </div>
  );
}

export default StageProgressMeter;
