import classNames from 'classnames';
import { InputHTMLAttributes, memo } from 'react';
import { QsOptions } from '../../patient/patient-types';
import { getRangeAdjusted } from './utils/question/range';

function QsInputRange(
  props: InputHTMLAttributes<HTMLInputElement> & {
    options?: QsOptions | null;
  }
) {
  const { options, value } = props;
  const { max: maxStr = '10', min: minStr = '0', step: stepStr = '1' } = options || {};

  const {
    min,
    max: maxNum,
    step,
    ticks,
  } = getRangeAdjusted({
    min: minStr,
    max: maxStr,
    step: stepStr,
  });

  return (
    <div>
      <input
        type='range'
        className={classNames(
          'range range-primary rounded-full',
          (value == null || value === '') && 'opacity-40'
        )}
        step={step}
        {...props}
        value={value || '0'}
        max={maxNum}
        min={minStr}
      />
      <div className='w-full flex justify-between text-xs'>
        {ticks.map((tick, idx) => {
          const tickValue = step * idx + min;
          return (
            <span key={idx} className='w-5 text-center'>
              {tickValue}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default memo(QsInputRange);
