import triageRows from '../../assets/files/triage.json';
import { notNull } from '../utils';
import { convertItemToQuestion, filterQuestions } from '../utils/csv';
const triage = filterQuestions(triageRows);
export function fetchTriageQsLocales() {
    return triage.map((item) => {
        return {
            locale: 'english',
            key: item['question-key'],
            value: item['locale-en'],
        };
    });
}
export function fetchTriageTree() {
    return {
        treeKey: 'triage',
        entryQuestionKeys: triage.map((item) => item['question-key']),
    };
}
export function fetchTriageQuestions() {
    return triage
        .filter((item) => item['tree-key'] === 'triage')
        .map((item) => {
        if (!item.id)
            return null;
        return convertItemToQuestion(item);
    })
        .filter(notNull);
}
