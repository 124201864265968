import {
  NoYearDateTimeFormat,
  formatDate
} from '@cyren/common-lib';
import { HTMLAttributes, memo, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { FaBell, FaBook, FaComments, FaEdit, FaEnvelope, FaEnvelopeOpen, FaShareSquare } from 'react-icons/fa';
import { IoMdCopy } from 'react-icons/io';
import { useCopyToClipboard } from 'usehooks-ts';
import { H5 } from '../../../common/typo/H';
import { useToastMsgs } from '../../hooks/use-toast-msgs';
import { AdhocQuestionType } from '../../../patient/patient-types';
import { size, trim } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PatientMessageType } from '../../../report/types';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { useElectron } from '../../hooks/use-electron';
import { useRecoilState } from 'recoil';
import { PaDataRepoState } from '../../../report/use-data';
import { getStringFromAdHocQuestions } from '../../../utils/models/template';

function ReportAdHocCard({
  adHocData,
  patientMessages,
  locale
}: HTMLAttributes<HTMLDivElement> & {
  adHocData: {
    adHocQuestions: AdhocQuestionType[] | null;
  } | null,
  patientMessages?: PatientMessageType[] | null;
  locale?: string | null;
}) {
  const [, copy] = useCopyToClipboard();
  const { showNothingToCopyMsg, showCopiedMsg } = useToastMsgs();

  const { isElectron } = useElectron();
  const [isSendingData, setIsSendingData] = useState(false);

  const [dataState] = useRecoilState(PaDataRepoState);
  const { userNoteTrans } = dataState;

  return (
    <Accordion defaultExpanded
      sx={{
        borderRadius: '1rem',
        margin: '0 0 0 0',
        '&.MuiAccordion-root:first-of-type':
        {
          borderRadius: '1rem'
        },
        '&.MuiAccordion-root.Mui-expanded':
        {
          margin: '0 0 0 0',
        },
        '&.MuiAccordion-root:last-of-type':
        {
          borderRadius: '1rem'
        },
        '&:before': { display: 'none' }  // to hide shadow top border element
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="additional-questions-content"
        id="additional-questions-header"
        sx={{
          padding: '0 0.5rem',
          margin: '0.25rem 0',
          minHeight: '0.25rem',
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px 0px',
            minHeight: '0.25rem',
          },
          '.MuiAccordionSummary-content': {
            margin: '0px 0px',
            minHeight: '0.25rem',
          },
          '&.Mui-expanded': {
            margin: '0.25rem 0rem 0rem 0rem',
            minHeight: '0.25rem',
          }
        }}
      >
        <div className='card-body p-4 md:p-1 gap-0 flex-row justify-between'>
          <div className='flex-center'>
            <H5>Patient Communications</H5>
          </div>
          <div className='flex-row'>
            <div className='flex flex-end relative'>
              {isElectron && (
                <span
                  className='p-2 cursor-pointer h-fit w-fit'
                  onClick={(event) => {
                    event.stopPropagation();

                    const outputStr = getStringFromAdHocQuestions(adHocData?.adHocQuestions || [], userNoteTrans, locale);
  
                    if (outputStr) {
                      setIsSendingData(true);
                      window.electron?.ipcRenderer?.invokeMessage('send', [`${outputStr}\n`]).finally(() => setIsSendingData(false));
                    }
                  }}
                >
                  {isSendingData ? <span className='loading loading-xs loading-spinner'></span> : <FaShareSquare size={17} />}
                </span>
              )}
              <span
                className='p-2 cursor-pointer h-fit w-fit'
                onClick={(event) => {
                  event.stopPropagation();

                  const outputStr = getStringFromAdHocQuestions(adHocData?.adHocQuestions || [], userNoteTrans, locale);
                  if (outputStr) {
                    copy(`${outputStr}\n`);
                    showCopiedMsg();
                  } else {
                    showNothingToCopyMsg();
                  }
                }}
              >
                <IoMdCopy size={17} />
              </span>
              <span className='p-2 cursor-pointer h-fit w-fit hidden'>
                <BiPencil size={17} />
              </span>
            </div>
          </div>
        </div>
      </AccordionSummary >
      <AccordionDetails
        id="additional-questions-content"
        sx={{
          margin: '0px 0px',
          padding: '0rem 0.5rem 0.25rem 0.5rem'
        }}
      >
        <div className='card-body p-4 md:p-1 gap-0'>
          <SimpleTreeView defaultExpandedItems={['custom-questions']}>
            {size(adHocData?.adHocQuestions) > 0 &&

              <TreeItem itemId="custom-questions" label={`Custom Questions`}>

                <div className="ml-5">
                  {
                    adHocData?.adHocQuestions?.map((item) => {

                      const noteTran = userNoteTrans?.find((tra) => tra.target === item.answer && tra.target_locale === locale);
                      const translatedAnswer = trim(noteTran?.output) || item.answer;

                      return (
                        <div key={item.uniqueId} className='py-1'>
                          <div className='underline'>{item.questionText}</div>
                          {item.answer ?
                            <>
                              <div className='pl-6 bold' >{translatedAnswer}</div>
                              {translatedAnswer && translatedAnswer !== item.answer &&
                                <div className="pl-6 text-xs opacity-75">[Auto-translated from <span className="italic">{item.answer}</span>]</div>
                              }
                            </>
                            :
                            <div className='pl-6 italic opacity-75 text-sm'>Pending patient answer</div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </TreeItem>
            }
            {size(patientMessages) > 0 &&
              <TreeItem itemId="communication-history-tree" label={`Communication History (${patientMessages?.length} messages)`}
                className="box-border p-6" sx={{ mt: 2 }}>
                <div className="w-full pl-4 box-border">
                  <table className="table table-compact w-full allowWrapping">
                    <thead>
                      <tr>
                        <th className="w-auto flex-center">Type</th>
                        <th className="w-full" style={{ whiteSpace: 'normal' }}>Content</th>
                        <th className="w-auto whitespace-nowrap">Date</th>
                        <th className="w-auto flex-center">Opened</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patientMessages?.map(aMessage => {
                        const createdAt = new Date(aMessage.createdAt);
                        const showOpenState = ['notification'].indexOf(aMessage.contentType) < 0;

                        return (
                          <tr key={aMessage.uniqueID} className="align-middle text-xs">
                            <td className="text-center align-middle">
                              <div className="flex justify-center">
                                {(aMessage.contentType === 'adhoc') &&
                                  <FaComments aria-label="Additional Question" title="Additional Question" size={15} />}
                                {(aMessage.contentType === 'addOnForm') &&
                                  <FaEdit aria-label="Add-On Form" title="Add-on Form" size={15} />}
                                {(aMessage.contentType === 'notification') &&
                                  <FaBell aria-label="Notification" title="Notification" size={15} />}
                                {(aMessage.contentType === 'resource') &&
                                  <FaBook aria-label="Resource" title="Resource" size={15} />}
                              </div>
                            </td>
                            <td className="align-middle">{aMessage.messageContent?.messageSummary}</td>
                            <td className="nowrap">{formatDate(createdAt, { formatStr: NoYearDateTimeFormat })}</td>
                            <td className="text-center align-middle">
                              <div className="flex justify-center">
                                {(showOpenState && aMessage.opened) &&
                                  <FaEnvelopeOpen aria-label="Opened" title="Opened" size={15} />}
                                {(showOpenState && !aMessage.opened) &&
                                  <FaEnvelope aria-label="Unopened" title="Unopened" size={15} />}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </TreeItem>
            }
          </SimpleTreeView>
        </div>
      </AccordionDetails>
    </Accordion >
  );
}

export default memo(ReportAdHocCard);
