import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export function H2({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) {
  return (
    <h3 className={classNames('text-3xl font-bold', className)} {...props}>
      {children}
    </h3>
  );
}

export function H3({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) {
  return (
    <h3 className={classNames('text-xl font-semibold', className)} {...props}>
      {children}
    </h3>
  );
}

export function H4({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) {
  return (
    <h4 className={classNames('text-lg font-semibold', className)} {...props}>
      {children}
    </h4>
  );
}

export function H5({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) {
  return (
    <h5 className={classNames('text-m font-semibold', className)} {...props}>
      {children}
    </h5>
  );
}

export function H6({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) {
  return (
    <h5 className={classNames('text-sm font-semibold', className)} {...props}>
      {children}
    </h5>
  );
}
