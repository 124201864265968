import {
  Enum_Question_Type,
} from '@cyren/common-lib';

export const questionTypeMap: Record<Enum_Question_Type, string> = {
  [Enum_Question_Type.BloodPressure]: 'Blood Pressure',
  [Enum_Question_Type.BodySelect]: 'Body Selection',
  [Enum_Question_Type.BodySelectBack]: 'Body Selection - Back',
  [Enum_Question_Type.BodySelectBelly]: 'Body Selection - Belly',
  [Enum_Question_Type.BodySelectChest]: 'Body Selection - Chest',
  [Enum_Question_Type.BodySelectDental]: 'Body Selection - Dental',
  [Enum_Question_Type.BodySelectFace]: 'Body Selection - Face',
  [Enum_Question_Type.BodySelectHead]: 'Body Selection - Head',
  [Enum_Question_Type.BodySelectJoints]: 'Body Selection - Joints',
  [Enum_Question_Type.BodySelectLimbs]: 'Body Selection - Limbs',
  [Enum_Question_Type.BodySelectMouth]: 'Body Selection - Mouth',
  [Enum_Question_Type.BodySelectSensory]: 'Body Selection - Sensory',
  [Enum_Question_Type.BodySelectSubpart]: 'Body Selection - Subpart',
  [Enum_Question_Type.BodySelectWhole]: 'Body Selection - Whole',
  [Enum_Question_Type.Collapsible]: 'Collapsible Section',
  [Enum_Question_Type.CollapsibleItem]: 'Collapsible Item',
  [Enum_Question_Type.Date]: 'Date',
  [Enum_Question_Type.DateDob]: 'Date of Birth',
  [Enum_Question_Type.Datetime]: 'Date & Time',
  [Enum_Question_Type.DatetimeRelativeOnset]: 'Relative Onset Date & Time',
  [Enum_Question_Type.DrawBody]: 'Draw on Body',
  [Enum_Question_Type.Frequency]: 'Frequency',
  [Enum_Question_Type.Image]: 'Image Upload',
  [Enum_Question_Type.Markdown]: 'Markdown Content',
  [Enum_Question_Type.Number]: 'Number Input',
  [Enum_Question_Type.NumberUnitSelect]: 'Number with Unit Selection',
  [Enum_Question_Type.Onset]: 'Onset',
  [Enum_Question_Type.OnsetV2]: 'Onset (V2)',
  [Enum_Question_Type.PhoneNumber]: 'Phone Number',
  [Enum_Question_Type.Range]: 'Range Selector',
  [Enum_Question_Type.Select]: 'Dropdown Select',
  [Enum_Question_Type.SelectAbdLocation]: 'Select Abdomen Location',
  [Enum_Question_Type.SelectCountries]: 'Select Multiple Countries',
  [Enum_Question_Type.SelectCountry]: 'Select Country',
  [Enum_Question_Type.SelectSheet]: 'Select from Sheet',
  [Enum_Question_Type.SelectV2]: 'Dropdown Select (V2)',
  [Enum_Question_Type.Signature]: 'Signature Field',
  [Enum_Question_Type.StageMilemarker]: 'Stage Mile Marker',
  [Enum_Question_Type.SxCollect]: 'Symptom Collection',
  [Enum_Question_Type.SxCollectAssociated]: 'Symptom Collection - Associated',
  [Enum_Question_Type.SxCollectIntelligent]: 'Symptom Collection - Intelligent',
  [Enum_Question_Type.SxSelect]: 'Symptom Selection',
  [Enum_Question_Type.Text]: 'Short Text Input',
  [Enum_Question_Type.Textarea]: 'Long Text Input',
  [Enum_Question_Type.Time]: 'Time Picker'
};
