import { isEmpty, isEqual, sortBy, toLower, trim } from 'lodash';
export function createArray(len) {
    return new Array(len).fill(null);
}
export function createIntArray(len) {
    return createArray(len).map((n, idx) => idx);
}
export function notNull(value) {
    return value != null;
}
export function notEmpty(value) {
    return !isEmpty(value);
}
export function notEmptyString(value) {
    return !isEmpty(trim(value || undefined));
}
export function includeI(arr, item) {
    if (!item || !arr) {
        return false;
    }
    return (arr || []).map(toLower).includes(toLower(item) || '');
}
export function equalI(item1, item2) {
    return toLower(item1) === toLower(item2);
}
export function areArraysEqual(arr1, arr2) {
    return isEqual(sortBy(arr1), sortBy(arr2));
}
