import { PatientVisitFragment, decrypt, encrypt } from '@cyren/common-lib';
import { keys } from 'lodash';
import { ClientModel, TPatientVisit } from '../../graphql-enhanced';

export const fieldsToEncrypt = ['lastName', 'sex', 'firstName', 'surveyState', 'adHocState'];

// NOTE: mutating
export function encryptVisit(visit: TPatientVisit | null) {
  keys(visit).forEach((key) => {
    // @ts-ignore
    if (visit[key] != null) {
      // @ts-ignore
      visit[key] = fieldsToEncrypt.includes(key) ? encrypt(visit[key]) : visit[key];
    }
  });

  return visit;
}

// NOTE: mutating
export function decryptVisit(visit: ClientModel<PatientVisitFragment>) {
  keys(visit).forEach((key) => {
    // @ts-ignore
    const value = visit[key] as string | null;
    const included = fieldsToEncrypt.includes(key);

    if (included) {
      try {
        // @ts-ignore
        visit[key] = JSON.parse(decrypt(value));
      } catch (error) {
        //
      }
    }
  });

  return visit;
}
