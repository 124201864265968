import { guessBrowserLocale, locales, rtlLocales } from '@cyren/common-lib';
import produce from 'immer';
import { first, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export function findLocaleByKey(key?: string | null) {
  if (!key) return undefined;

  return locales.find((l) => l.key === key);
}

type AppStateCookieT = {
  localeKey: string;
  userLocaleKey?: string | null;
  msAuth?: boolean;
};

export const AppStateCookie = atom<AppStateCookieT>({
  key: 'AppStateCookie',
  default: {
    localeKey: guessBrowserLocale(),
    userLocaleKey: null,
    msAuth: false,
  },
  effects_UNSTABLE: [persistAtom],
});

const AppState = atom<{ modals: string[]; openLocale: boolean }>({
  key: 'AppState',
  default: {
    modals: [],
    openLocale: false,
  },
});

export default function useAppState({ requireLocaleInit }: { requireLocaleInit?: boolean } = {}) {
  const [appState, setAppState] = useRecoilState(AppState);
  const [appStateCookie, setAppStateCookie] = useRecoilState(AppStateCookie);

  const { localeKey } = appStateCookie;
  const localeInfo = findLocaleByKey(localeKey);

  function setLocale(locale: string) {
    setAppStateCookie(
      produce(appStateCookie, (draft) => {
        draft.localeKey = locale;
        draft.userLocaleKey = locale;
      })
    );

    closeLocaleModal();
  }

  function unsetLocale() {
    setAppStateCookie(
      produce(appStateCookie, (draft) => {
        draft.localeKey = 'english';
        draft.userLocaleKey = null;
      })
    );
  }

  function closeModalWithKey(key: string) {
    setAppState(
      produce(appState, (draft) => {
        draft.modals = draft.modals.filter((k) => k !== key);
      })
    );
  }

  function openModalWithKey(key: string) {
    // check if modal is standalone

    // NOTE what is this for?
    const el = document.getElementById('cyren-admin');
    if (el) return;

    setAppState(
      produce(appState, (draft) => {
        draft.modals.push(key);
      })
    );
  }

  function closeLocaleModal() {
    closeModalWithKey('localeModal');
    setAppState((st) =>
      produce(st, (draft) => {
        draft.openLocale = false;
      })
    );
  }

  function openLocaleModal() {
    openModalWithKey('localeModal');

    setAppState((st) =>
      produce(st, (draft) => {
        draft.openLocale = true;
      })
    );
  }

  function switchScroll(on: boolean) {
    if (on) {
      document.body.classList.add('h-[2000rem]');
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('h-[2000rem]');
      document.body.classList.remove('overflow-hidden');
    }
  }

  // prevent scrolling when needed
  useEffect(() => {
    const hasOpenModal = !isEmpty(appState?.modals);

    switchScroll(hasOpenModal);
  }, [appState?.modals]);

  useEffect(() => {
    if (requireLocaleInit && appStateCookie.userLocaleKey == null) {
      openLocaleModal();
    }
  }, [appStateCookie.userLocaleKey]);

  const dir = rtlLocales.includes(localeKey || '') ? 'rtl' : 'ltr';
  const isRtl = dir === 'rtl';

  useEffect(() => {
    const el = first(document.getElementsByTagName('html'));
    el?.setAttribute('dir', dir);
  }, [dir]);
  return [
    {
      appState,
      appStateCookie,
      dir,
      isRtl,
      localeInfo,
      localeCode: localeInfo?.code,
      localeKey,
    },
    {
      openModalWithKey,
      closeModalWithKey,
      closeLocaleModal,
      openLocaleModal,
      switchScroll,
      setLocale,
      unsetLocale,
      setAppStateCookie,
    },
  ] as const;
}
