import { Configuration, LogLevel } from '@azure/msal-browser';
import envs from '../../common/envs';

export const msalConfig: Configuration = {
  auth: {
    clientId: envs.msSSO.clientId || '',
    authority: 'https://login.microsoftonline.com/organizations/v2.0',
    redirectUri: `${envs.clientUrl}/admin`,
    postLogoutRedirectUri: '/admin/auth/logout',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line
            console.debug(message);
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line
            console.warn(message);
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
