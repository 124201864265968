import { MsalProvider, useMsal } from '@azure/msal-react';
import { Platform, useConnectAuthMutation } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { useEffect } from 'react';
import { BsMicrosoft } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { createAADInstance } from '../admin/auth/aad/aad-client';
import { useElectron } from '../admin/hooks/use-electron';
import { AppStateCookie } from '../app/use-app-state';
import envs from '../common/envs';
import LoadingAnim from '../components/LoadingAnim';
import Modal from '../components/Modal';
import { getErrorMsg } from '../graphql/utils/error';
import { onError as onGqlError } from '../utils/apollo-utils';
import { loginRequest } from './aad/config';
import useAuth from './use-auth';

type Props = {
  onError?: (err?: string | null) => void;
  onSuccess?: () => void;
};

export function SignInModalAADContent({ onError, onSuccess }: Props) {
  const { isElectron } = useElectron();
  const { instance } = useMsal();
  const [, { handleLogin }] = useAuth();

  const setAppStateCookie = useSetRecoilState(AppStateCookie);

  const [connect, { loading: connectLoading, error }] = useConnectAuthMutation({
    onError: onGqlError,
    onCompleted: async (connectData) => {
      if (connectData?.connect.user) {
        await handleLogin(connectData?.connect);
        onSuccess?.();

        setAppStateCookie((st) =>
          produce(st, (dr) => {
            dr.msAuth = true;
          })
        );
      }
    },
  });

  const gqlError = getErrorMsg(error);

  useEffect(() => {
    onError?.(gqlError);
  }, [gqlError]);

  const msLogin = () => {
    if (isElectron) {
      instance
        // .acquireTokenRedirect(loginRequest)
        .acquireTokenRedirect({
          ...loginRequest,
          prompt: !envs.isProd ? 'select_account' : 'login',
          redirectUri: `${envs.clientUrl}/admin/auth/ms/callback`,
          // redirectUri: `${envs.backendBaseUrl}/api/auth/ms/callback`,
        });
    } else {
      instance
        // .acquireTokenRedirect(loginRequest)
        .acquireTokenPopup({
          ...loginRequest,
          prompt: !envs.isProd ? 'select_account' : 'login',
        })
        .then((re) => {
          const accToken = re.accessToken;
          connect({
            variables: {
              platform: Platform.Web,
              code: accToken,
              type: 'microsoft',
            },
          });
        })
        .catch((e) => {
          onError?.(`Login request failed. ${e.message}`);
          toast.error(`Login request failed. ${e.message}`);
        });
    }
  };

  return (
    <button
      className={classNames(
        'btn btn-lg gap-2 bg-white hover:bg-white border-0 text-black lg:bg-base-200 lg:hover:bg-base-200'
      )}
      onClick={async () => {
        msLogin();
      }}
    >
      {connectLoading ? <LoadingAnim width={20} /> : <BsMicrosoft size='1.4rem' />}
      Continue with Microsoft
    </button>
  );
}

const inst = createAADInstance();

export function SignInAAD(props: Props) {
  return (
    <MsalProvider instance={inst}>
      <SignInModalAADContent {...props} />
    </MsalProvider>
  );
}

// eslint-disable-next-line
function SignInModalAAD(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess?: () => void;
}) {
  const { open, setOpen } = props;

  if (!open) return null;

  return (
    <Modal
      open={open}
      className='lg:w-[400px]'
      onClickBackdrop={() => {
        setOpen(false);
      }}
    >
      <SignInAAD {...props} />
    </Modal>
  );
}

export default SignInModalAAD;
