
export const reportItemTypesMap: Record<string, string> = {
  "rep-positive-negative": "Positive / Negative",
  "rep-points": "Points",
  "rep-total": "Total",
  "rep-good-bad": "Good / Bad",
  "rep-as-is": "As-Is",
  "rep-options-patient-term": "Options (Patient Term)",
  "list-all-as-checkbox": "List All as Checkbox",
  "rep-formatted-single": "Formatted (Single)",
  "rep-formatted-mixed": "Formatted (Mixed)",
  "rep-label": "Label",
  "rep-tree-doctor-term": "Tree (Doctor Term)",
  "rep-map-options": "Map Options",
  "rep-date-to-age": "Date to Age",
  "rep-system-values": "System Values",
  "rep-formatted": "Formatted",
  "rep-whole-body": "Whole Body"
};
