import { ApolloProvider } from '@apollo/client';
import { RouterProvider } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { useClient } from '../graphql/client';
import { ThemeProvider } from '@mui/material/styles';
import { providerTheme } from './mui-theme';
import AppRoutes from './AppRoutes';

export default function App() {
  const client = useClient();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={providerTheme}>
        <RouterProvider router={AppRoutes} />
        <ToastContainer position='bottom-left' />
        <div id='modal-root'></div>
      </ThemeProvider>
    </ApolloProvider>
  );
}
