/**
 * This is very simple version of encryption. It's not providing server side level of security.
 * This is only to hide the data on browser debugger.
 * To be run on browser environments, it could not use fancy encryption algorithms or libraries.
 */
export function encrypt(text) {
    if (text == null)
        return text;
    let encryptedText = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        const encryptedCharCode = charCode + 14;
        encryptedText += String.fromCharCode(encryptedCharCode);
    }
    return encryptedText;
}
export function decrypt(encryptedText) {
    if (encryptedText == null)
        return encryptedText;
    let decryptedText = '';
    for (let i = 0; i < encryptedText.length; i++) {
        const charCode = encryptedText.charCodeAt(i);
        const decryptedCharCode = charCode - 14;
        decryptedText += String.fromCharCode(decryptedCharCode);
    }
    return decryptedText;
}
