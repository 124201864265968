import { ChangeEventHandler, memo } from 'react';

import { Enum_Translation_Locale } from '@cyren/common-lib';
import classNames from 'classnames';
import { isEmpty, trim } from 'lodash';
import { useTranslations } from '../hooks/use-translations';
import { Key, Keys } from '../patient-types';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';

/**
 * NOTE
 * Renders body part selector in whole body selector
 * Responsible to render the correct part on selected deeper selector option
 */

function SelectItem({
  answerKey,
  questionKey,
  selectedKeys,
  requireNoteInput,
  locale,
  note,
  error,
  onChange,
  onNoteChange,
  type,
}: {
  answerKey: Key;
  questionKey: Key;
  selectedKeys: Keys;
  requireNoteInput?: boolean;
  locale: Enum_Translation_Locale;
  note?: string;
  error?: string;
  onNoteChange?: (n?: string) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  type: 'radio' | 'checkbox';
}) {
  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();

  const isSelected = selectedKeys.includes(answerKey);
  const extNoteId = `${questionKey}-ext-${answerKey}-onsheet`;
  return (
    <div key={`in-sheet-${answerKey}`}>
      <label className='cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'>
        <div className='flex-1 w-full flex-row gap-4'>
          <input
            value={answerKey}
            name={questionKey}
            checked={isSelected}
            type={type}
            className={classNames('checkbox checkbox-lg checkbox-primary')}
            onChange={onChange}
          />
          <span className='pt-[0.2rem]'>{t(answerKey, {})}</span>
        </div>

        {requireNoteInput && (
          <label className='flex flex-col'>
            <input
              type='text'
              placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
              value={note}
              className={classNames(
                extNoteId,
                error && isEmpty(trim(note)) && 'border-error',
                'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
              )}
              onChange={(e) => {
                onNoteChange?.(e.target.value);
              }}
            />
          </label>
        )}
      </label>
    </div>
  );
}

export default memo(SelectItem);
