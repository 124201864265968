import { Enum_Translation_Locale } from '@cyren/common-lib';
import produce from 'immer';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const adminLocales = [
  {
    key: 'english',
    label: 'English',
  },
  {
    key: 'spanish',
    label: 'Spanish',
  },
  { key: 'korean', label: 'Korean' },
];

export function findLocaleByKey(key?: string | null) {
  if (!key) return undefined;

  return adminLocales.find((l) => l.key === key);
}

type AdminAppStateCookieT = {
  localeKey?: string | null;
  editingLang: Enum_Translation_Locale;
  openSide: boolean;
};

export const AdminAppStateCookie = atom<AdminAppStateCookieT>({
  key: 'AdminAppStateCookie',
  default: {
    openSide: true,
    editingLang: Enum_Translation_Locale.English,
  },
  effects_UNSTABLE: [persistAtom],
});

const AdminAppState = atom<{ modals: string[]; openLocale: boolean }>({
  key: 'AdminAppState',
  default: {
    modals: [],
    openLocale: false,
  },
});

export default function useAdminAppState() {
  const [adminAppState, setAdminAppState] = useRecoilState(AdminAppState);
  const [adminAppStateCookie, setAdminAppStateCookie] = useRecoilState(AdminAppStateCookie);

  const { localeKey } = adminAppStateCookie;
  const localeInfo = findLocaleByKey(localeKey);

  function setLocale(locale?: string | null) {
    setAdminAppStateCookie(
      produce(adminAppStateCookie, (draft) => {
        draft.localeKey = locale;
      })
    );

    closeLocaleModal();
  }

  function setEditingLocale(locale?: Enum_Translation_Locale | string) {
    setAdminAppStateCookie(
      produce(adminAppStateCookie, (draft) => {
        // NOTE: converting string to enum
        if (locale != null && typeof locale === 'string') {
          draft.editingLang = locale as Enum_Translation_Locale;
          // return;
        }

        // draft.editingLang = locale;
      })
    );

    closeLocaleModal();
  }

  function closeModalWithKey(key: string) {
    setAdminAppState(
      produce(adminAppState, (draft) => {
        draft.modals = draft.modals.filter((k) => k !== key);
      })
    );
  }

  function openModalWithKey(key: string) {
    setAdminAppState(
      produce(adminAppState, (draft) => {
        draft.modals.push(key);
      })
    );
  }

  function closeLocaleModal() {
    closeModalWithKey('localeModal');
  }

  function openLocaleModal() {
    openModalWithKey('localeModal');
  }

  function switchScroll(on: boolean) {
    if (on) {
      document.body.classList.add('h-full');
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('h-full');
      document.body.classList.remove('overflow-hidden');
    }
  }

  function setOpenSide(n: boolean) {
    setAdminAppStateCookie(
      produce(adminAppStateCookie, (draft) => {
        draft.openSide = n;
      })
    );
  }

  // prevent scrolling when needed
  useEffect(() => {
    switchScroll(!isEmpty(adminAppState?.modals));
  }, [adminAppState]);

  return [
    {
      adminAppState,
      adminAppStateCookie,
      localeInfo,
    },
    {
      setOpenSide,
      setEditingLocale,
      openModalWithKey,
      closeModalWithKey,
      closeLocaleModal,
      openLocaleModal,
      switchScroll,
      setLocale,
    },
  ] as const;
}
