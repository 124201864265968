import { TTranslation } from '../graphql-enhanced';

// const fields = ['key', 'locale', 'dataType', 'type'];

export function isEqualTrans({ trans1, trans2 }: { trans1: TTranslation; trans2: TTranslation }) {
  return trans1.id === trans2.id;

  /* const obj1 = pick(trans1, fields); */
  /* const obj2 = pick(trans2, fields); */

  /* //   if (isEqual(obj1, obj2)) console.log('isEqual(obj1, obj2)', isEqual(obj1, obj2), obj1, obj2); */

  /* const e = fields.some((field) => { */
  /*   // @ts-ignore */
  /*   return obj1?.[field] !== obj2?.[field]; */
  /* }); */

  /* return !e; */
}
