import produce from 'immer';
import { isEmpty, truncate, uniq, upperCase } from 'lodash';
import { memo, useState } from 'react';
import { BsFillPlusCircleFill, BsPlusCircle } from 'react-icons/bs';

import { isNotEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import {
  getMainOptionAnswerKeys,
  getMutualExclusiveAnswerKeys,
  getRequireNoteAnswerKeys,
} from '../../../../utils/models/question';
import { useTranslations } from '../../../hooks/use-translations';
import { BodySelectorProps } from '../../../pa-prop-types';
import { Keys } from '../../../patient-types';
import SensoryMaleButtons from './buttons/SensoryMaleButtons';
import SensoryFemaleButtons from './buttons/SensoryFemaleButtons';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useStaticLocales } from '../../../../admin/hooks/locales/use-static-locale';

function BodyPartSelectorSensory(props: BodySelectorProps) {
  const { standalone, question, onValueChange, answerValue: answerValueInit, locale, isMale } = props;

  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();

  const [answerValue, setAnswerValue] = useState(answerValueInit || {});
  const [requireNoteKey, setRequireNoteKey] = useState<string | null>(null);

  const mutExcAnswerKeys = getMutualExclusiveAnswerKeys({ question });
  const requireNoteKeys = getRequireNoteAnswerKeys({ question });
  const mainOptionKeys = getMainOptionAnswerKeys({ question });
  // const omitInSearchKeys = getOmitInSearchAnswerKeys({ question });

  const selectedKeys = answerValue.answerKeys || [];
  const selectedButtonKeys = [...selectedKeys];

  // There was a logic change to use face-lt/face-rt instead of head-lt/head/rt.  Check
  // what is actually in the question and guide the body part selector accordingly
  const alternateAnswerKeys = new Map<string, string>();
  if (question?.answerKeys?.find(key => key === "o-face-lt")) {
    alternateAnswerKeys.set("o-head-lt", "o-face-lt");
  }
  if (question?.answerKeys?.find(key => key === "o-face-rt")) {
    alternateAnswerKeys.set("o-head-rt", "o-face-rt");
  }

  function handleClick({ id }: { id: string }) {
    // eslint-disable-next-line
    console.log('id', id);

    const selected = selectedKeys.includes(id);
    const isMutExcl = mutExcAnswerKeys.includes(id);

    setRequireNoteKey(null);

    let nKeys: Keys = [...selectedKeys];

    if (isMutExcl) {
      // ONLY INCLUDE MUT KEY
      if (selected) {
        nKeys = [];
      } else {
        nKeys = [id];
      }
    } else {
      // EXCLUDE ALL MUT KEYS
      nKeys = nKeys.filter((k) => !mutExcAnswerKeys.includes(k));
      // eslint-disable-next-line no-lonely-if
      if (selected) {
        nKeys = nKeys.filter((i) => i !== id);
      } else {
        nKeys = uniq([...nKeys, id]);
      }
    }

    const nValue = produce(answerValue, (dr) => {
      dr.answerKeys = nKeys;
    });

    setAnswerValue(nValue);
    onValueChange(nValue);
  }

  return (
    <div className='t_BodyPartSelectorSensory flex-col overflow-hidden'>
      <div className={classNames('flex-col overflow-auto', !standalone && 'p-4')}>
        {isNotEmpty(mainOptionKeys) && (
          <div className='flex gap-2 flex-wrap'>
            {mainOptionKeys?.map((mutKey) => {
              const isRequireNote = requireNoteKeys.includes(mutKey);
              const selected = selectedKeys.includes(mutKey);

              return (
                <button
                  key={mutKey}
                  className='flex-1 btn-base'
                  onClick={() => {
                    if (!selected) {
                      setRequireNoteKey(null);

                      if (isRequireNote) {
                        setRequireNoteKey(mutKey);
                        return;
                      }
                    }

                    handleClick({ id: mutKey });
                  }}
                >
                  {selected ? (
                    <BsPlusCircle size={22} className='text-primary rotate-45' />
                  ) : (
                    <BsFillPlusCircleFill size={22} className='text-primary' />
                  )}
                  <span className='text-base'>{upperCase(t(mutKey))}</span>
                </button>
              );
            })}
          </div>
        )}

        {requireNoteKey && (
          <div className='flex-none flex pt-3 gap-2'>
            <TextField fullWidth
              label={sltStr({ key: 'label-area-description', upper: true })}
              value={answerValue?.notesForKeys?.[requireNoteKey] || ''}
              onChange={(e) => {
                const nValue = produce(answerValue || {}, (dr) => {
                  if (!dr.notesForKeys) dr.notesForKeys = {};
                  dr.notesForKeys[requireNoteKey] = e.target.value;
                });

                setAnswerValue(nValue);
              }}
              autoComplete='off'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      disabled={(!isNotEmpty(answerValue?.notesForKeys?.[requireNoteKey]))}
                      onClick={() => {
                        handleClick({ id: requireNoteKey });
                      }}
                      color="primary"
                    >
                      {sltStr({ key: 'btn-add' })}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}

        <div className='relative max-w-full pt-2'>
          <div className='pt-14 pb-1'>
            <div className='absolute flex-center top-0 w-full flex justify-between opacity-60'>
              <span className='p-5 text-sm'>{sltStr({ key: 'label-right', upper: true })}</span>
              <span className='p-5 text-sm'>{sltStr({ key: 'label-left', upper: true })}</span>
            </div>
            {/* BODY IMAGE */}
            <div className='flex-center relative -top-6'>
              {isMale ? (
                <SensoryMaleButtons
                  selectedKeys={selectedButtonKeys}
                  alternateAnswerKeys={alternateAnswerKeys}
                  className='relative'
                  onClick={(e) => {
                    const el = e.target as HTMLElement;
                    const id = el.getAttribute('id');
                    if (id) {
                      handleClick({ id });
                    }
                  }}
                />
              ) : (
                <SensoryFemaleButtons
                  selectedKeys={selectedButtonKeys}
                  alternateAnswerKeys={alternateAnswerKeys}
                  className='relative'
                  onClick={(e) => {
                    const el = e.target as HTMLElement;
                    const id = el.getAttribute('id');
                    if (id) {
                      handleClick({ id });
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {!isEmpty(selectedKeys) && (
        <div
          className={classNames('flex-none flex-col pt-4 gap-3 border-t', !standalone && 'px-4')}
        >
          <div className='flex gap-3 flex-wrap'>
            {selectedKeys.map((answerKey) => {
              const note = answerValue?.notesForKeys?.[answerKey];
              return (
                <button
                  key={answerKey}
                  className='btn btn-outline btn-primary border-primary p-2 min-h-9 max-w-full flex gap-2'
                  onClick={() => {
                    handleClick({ id: answerKey });
                  }}
                >
                  <span className='uppercase'>{t(answerKey)}</span>
                  {note ? ` (${truncate(note, {length: 75})})` : null}
                  <MdClose size={16} />
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(BodyPartSelectorSensory);
