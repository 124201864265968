/* eslint-disable jsx-a11y/no-autofocus */
import { isNotEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { endsWith, first } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { Outlet, useLocation } from 'react-router';
import { AutoLogout } from '../auth/AutoLogout';
import { MsAuthRefresher } from '../auth/MsAuthRefresher';
import SignInSection from '../auth/SignInSection';
import useAuth from '../auth/use-auth';
import useScrollToTop from '../common/hooks/use-scroll-to-top';
import LoadingAnim from '../components/LoadingAnim';
import Logo from '../components/Logo';
import { useDebInputState } from '../hooks/use-deb-input-state';
import { useAdminParams } from '../survey/use-admin-params';
import bgImg from './assets/Group 10189.svg';
import ProjectSelector from './components/ProjectSelector';
import StaffHelmet from './staff/StaffHelmet';
import useStaffState from './staff/use-staff-state';
import Version from './Version';

function AuthLayoutStaff(props: {
  navToAuthed?: string;
  navToUnauthed?: string;
  children?: ReactNode;
}) {
  const { navToAuthed, navToUnauthed, children } = props;
  const { orgIdParam } = useAdminParams();
  const loc = useLocation();

  const [{ authInit, authLoading, selectedOrg, isLoggedIn }, { nav }] = useAuth({
    navigateToAuthed: navToAuthed,
    navigateToUnAuthed: navToUnauthed,
  });

  const [showProjectSelector, setShowProjectSelector] = useState(false);

  useEffect(() => {
    if (selectedOrg && selectedOrg.id) {
      nav(`/admin/staff/${selectedOrg.id}/reports`);
    }
  }, [selectedOrg]);

  /* useEffect(() => { */
  /*   const n = toNumber(orgIdParam); */
  /*   if (orgIdParam && isNaN(n)) { */
  /*     nav('/admin/staff/'); */
  /*   } */
  /* }, [orgIdParam]); */

  useScrollToTop();

  useEffect(() => {
    const el = first(window.document.getElementsByTagName('html'));
    el?.setAttribute('data-theme', 'light');
  }, []);

  const [{ staffState }, { setStaffState }] = useStaffState();
  const { input, ref, setInput, debInput } = useDebInputState({
    initialValue: staffState.searchKey,
  });

  useEffect(() => {
    setStaffState((st) =>
      produce(st, (dr) => {
        dr.searchKey = debInput;
      })
    );

    if (orgIdParam && !endsWith(loc.pathname, '/reports')) {
      nav(`/admin/staff/${orgIdParam}/reports`);
    }
  }, [debInput]);

  useEffect(() => {
    if (authInit) {
      if (!orgIdParam) {
        const firstOrgId = selectedOrg?.id;
        if (firstOrgId) {
          // console.log('firstOrgId', firstOrgId);
          // nav(`/admin/staff/${firstOrgId}`);
        }
      }
    }
  }, [authInit, orgIdParam]);

  if (!authInit || authLoading) {
    // eslint-disable-next-line
    return (
      <div className='max-h-screen h-screen w-full'>
        <StaffHelmet />
        <LoadingAnim className='h-screen' />
      </div>
    );
  }

  if (!isLoggedIn) {
    return <SignInSection />;
  }

  if (authInit && !selectedOrg) {
    return (
      <div className='p-3 flex-col gap-4'>
        <StaffHelmet />
        <div className='w-12'>
          <Logo />
        </div>
        <div>
          <div>Please finish your onboarding process by following instructions in the email.</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <StaffHelmet />
      <div
        id='cyren-admin'
        className={classNames(' admin-v2 flex flex-1 flex-col md:overflow-hidden h-screen')}
      >
        {/* SEARCH BOX */}
        <div
          className='flex-1 flex fixed top-0 w-full p-3 gap-3 z-30'
          style={{
            backgroundImage: `url("${bgImg}")`,
          }}
        >
          <div
            className='flex-center'
            onClick={() => {
              setShowProjectSelector(!showProjectSelector);
            }}
          >
            <Logo className='w-10' />
          </div>

          {showProjectSelector && <ProjectSelector basePath='/admin/staff' />}

          <div className='flex-1 flex'>
            <input
              ref={ref}
              value={input}
              type='text'
              className='input w-full selection:text-white selection:bg-black text-xl tracking-wider font-extrabold'
              autoFocus
              onChange={(e) => {
                setInput(e.target.value);
              }}
              onFocus={() => {
                ref.current?.select();
              }}
              placeholder='Search patient ...'
            />
          </div>
          {isNotEmpty(input) && (
            <div>
              <button
                className='btn btn-ghost text-primary px-1'
                onClick={() => {
                  setInput('');
                  ref.current?.focus();
                }}
              >
                <IoMdCloseCircle size={33} />
              </button>
            </div>
          )}
        </div>
        {/* CONTENTS */}
        <div className='flex-col flex-1 bg-base-300 overflow-auto'>
          <div className={classNames('flex flex-1 w-full pt-20')}>
            <div className={classNames('flex-col p-0 md:pt-8 md:px-10 flex-1')}>
              {children || <Outlet />}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0"><Version/></div>
      </div>

      <MsAuthRefresher />
      <AutoLogout />
    </>
  );
}

export default AuthLayoutStaff;
