import {
  ApolloClient,
  ApolloLink,
  from,
  // createHttpLink,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { RefreshTokenDocument } from '@cyren/common-lib';
import { uniqBy } from 'lodash';
import envs from '../common/envs';

const httpLink = createUploadLink({
  uri: envs.BackendUrl,
  fetchOptions: {
    credentials: 'include',
  },
});

const logoutPath = '/admin/auth/logout';
const errorLinkRefreshToken = (redirectUrl?: string) =>
  onError(() => {
    // eslint-disable-next-line
    console.log('error handle for refresh token');

    // refresh token error
    // LOGOUT process
    window.location.href = redirectUrl || logoutPath;
  });

export function createClient(
  { redirectUrl }: { redirectUrl?: string } = { redirectUrl: logoutPath }
) {
  const client = new ApolloClient<NormalizedCacheObject>({
    // @ts-ignore
    link: from([errorLinkRefreshToken(redirectUrl), httpLink]),
    cache: new InMemoryCache(),
  });

  return client;
}

let accessToken: undefined | string | null = null;

export function setAccessToken(value: typeof accessToken) {
  // console.log('value', value);

  accessToken = value;
}

export function getAccessToken() {
  return accessToken;
}

const errorLink = ({ redirectUrl }: { redirectUrl?: string } = { redirectUrl: logoutPath }) =>
  onError(
    // eslint-disable-next-line
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          // eslint-disable-next-line
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      }

      if (networkError) {
        // @ts-ignore
        const { statusCode } = networkError;

        if (statusCode === 401) {
          const client = createClient({ redirectUrl });

          return fromPromise(
            client
              .mutate({
                mutation: RefreshTokenDocument,
              })
              .then((r) => {
                setAccessToken(r.data.refreshToken?.jwt);
                return forward(operation);
              })
          ).flatMap(() => {
            return forward(operation);
          });
        }

        // eslint-disable-next-line
        console.log(`[Network error]: ${networkError}`);
        // this triggers infinite errors
        // toast.error('Network error. Please check your internet connection.');
      }

      return undefined;
    }
  );

const createAuthMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const at = getAccessToken();
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...(at
            ? {
                authorization: `Bearer ${at}`,
              }
            : null),
        },
      };
    });

    return forward(operation);
  });
};

export function useClient({ redirectUrl }: { redirectUrl?: string } = { redirectUrl: logoutPath }) {
  const client = new ApolloClient<NormalizedCacheObject>({
    // @ts-ignore
    link: from([errorLink({ redirectUrl }), createAuthMiddleware(), httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            patientVisits: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                // load more and appending to the cached list
                const data = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref
                );

                return {
                  ...existing,
                  data,
                };
              },
            },
            usersPermissionsUsers: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                // load more and appending to the cached list
                return {
                  ...existing,
                  data: [...(existing?.data || []), ...(incoming?.data || [])],
                };
              },
            },
            comments: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                // load more and appending to the cached list
                const comments = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref
                );

                return {
                  ...existing,
                  data: comments,
                };
              },
            },
          },
        },
      },
    }),
  });

  return client;
}
