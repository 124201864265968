import { isNotEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { isEmpty } from 'lodash';
import { InputHTMLAttributes, memo, useCallback, useState } from 'react';
import { useStaticLocales } from '../../../../admin/hooks/locales/use-static-locale';
import { useOnNavChange } from '../../../../hooks/use-on-nav-change';
import { useErrorState } from '../../../hooks/use-error-state';
import { useGlobalPaState } from '../../../hooks/use-global-pa-state';
import { QuestionProps } from '../../../pa-prop-types';
import { AnswerValueT, Keys, QuestionType } from '../../../patient-types';
import { useQuestion } from '../../../tree/use-question';
import BodyPartSelectorBack from './BodyPartSelectorBack';
import BodyPartSelectorBelly from './BodyPartSelectorBelly';
import BodyPartSelectorChest from './BodyPartSelectorChest';
import BodyPartSelectorDental from './BodyPartSelectorDental';
import BodyPartSelectorFace from './BodyPartSelectorFace';
import BodyPartSelectorHead from './BodyPartSelectorHead';
import BodyPartSelectorLimbs from './BodyPartSelectorLimbs';
import BodyPartSelectorMouth from './BodyPartSelectorMouth';
import BodyPartSelectorSensory from './BodyPartSelectorSensory';
import BodyPartSelectorWholeBody from './BodyPartSelectorWholeBody';

function BodyPartSelector(
  props: QuestionProps &
    InputHTMLAttributes<HTMLInputElement> & {
      onSave?: (p: AnswerValueT) => void;
      onClose?: () => void;
      question: QuestionType;
      standalone?: boolean;
      onInterimChange?: (p: AnswerValueT) => void;
    }
) {
  const [{ isMale }] = useGlobalPaState();
  const { slt } = useStaticLocales();

  const { standalone, onSave, onClose, onInterimChange, answerValue: answerMapInit, question, locale } = props;
  const [answerValue, setAnswerValue] = useState<AnswerValueT>(answerMapInit || {});
  const bodyPartSelected = isNotEmpty(answerValue.answerKeys);
  const {
    isBodySelectBack,
    isBodySelectSensory,
    isBodySelectDental,
    isBodySelectMouth,
    isBodySelectLimbs,
    isBodySelectFace,
    isBodySelectBelly,
    isBodySelectHead,
    isBodySelectChest,
    isBodySelectWhole,
  } = useQuestion(question);

  const [selectedStack, setSelectedStack] = useState<Keys>([]);

  const handleNavChange = useCallback(() => {
    if (!isEmpty(selectedStack)) {
      setSelectedStack((st) =>
        produce(st, (dr) => {
          dr.pop();
        })
      );
      return;
    }
    onClose?.();
  }, [onClose, selectedStack, setSelectedStack]);

  useOnNavChange({
    block: !standalone,
    onChange: handleNavChange,
  });

  const nProps = {
    ...props,
    isMale,
    locale,
    answerValue,
    onValueChange: (nAV: AnswerValueT) => {
      setAnswerValue(nAV);

      if (standalone) {
        onSave?.(nAV);
      }
      else {
        onInterimChange?.(nAV);
      }
    },
  };

  const { errorState } = useErrorState({
    containerKey: 'whole-body',
    resetOnMount: true,
  });

  const invalidInput = isNotEmpty(errorState.errorKeys);

  return (
    <>
      {isBodySelectSensory && <BodyPartSelectorSensory {...nProps} />}
      {isBodySelectDental && <BodyPartSelectorDental {...nProps} />}
      {isBodySelectMouth && <BodyPartSelectorMouth {...nProps} />}
      {isBodySelectLimbs && <BodyPartSelectorLimbs {...nProps} />}
      {isBodySelectBack && <BodyPartSelectorBack {...nProps} />}
      {isBodySelectChest && <BodyPartSelectorChest {...nProps} />}
      {isBodySelectFace && <BodyPartSelectorFace {...nProps} />}
      {isBodySelectHead && <BodyPartSelectorHead {...nProps} />}
      {isBodySelectBelly && <BodyPartSelectorBelly {...nProps} />}
      {isBodySelectWhole && <BodyPartSelectorWholeBody {...nProps} />}

      {standalone !== true && (
        <div className='flex-none flex-col px-4 py-4 gap-3'>
          <button
            className={classNames(
              'btn btn-primary flex-1',
              (invalidInput || !bodyPartSelected) && 'btn-disabled'
            )}
            onClick={async () => {
              if (invalidInput) return;

              onSave?.(answerValue);
            }}
          >
            {slt({ key: 'btn-next' })}
          </button>
        </div>
      )}
    </>
  );
}

export default memo(BodyPartSelector);
