import { Enum_Questiontree_Treetype } from '@cyren/common-lib';
import classNames from 'classnames';
import { size } from 'lodash';
import { memo } from 'react';
import { ImArrowLeft2 } from 'react-icons/im';
import { useNavigate } from 'react-router';
import useInAdminDashboard from '../../admin/hooks/use-in-admin-dashboard';
import useAppState from '../../app/use-app-state';
import Logo from '../../components/Logo';
import { TSurvey } from '../../graphql-enhanced';
import { QuestionTreeType } from '../patient-types';
import ProgressBar from './ProgressBar';

function PaTreeHeader({
  survey,
  tree,
  showFullBar,
  noBack,
}: {
  survey?: TSurvey | null | undefined;
  tree?: QuestionTreeType | null;
  showFullBar?: boolean;
  noBack?: boolean;
}) {
  const { inAdminPanel } = useInAdminDashboard();
  const nav = useNavigate();
  const [{ isRtl }] = useAppState();

  const isStepTree = tree?.treeType === Enum_Questiontree_Treetype.Step;
  const treeKey = tree?.treeKey || '';
  const treeIdx = survey?.questionTreeKeys.findIndex((k) => k === treeKey);

  return (
    <div className='px-0 flex justify-between min-h-[2.8rem]'>
      {!noBack && !inAdminPanel && (
        <div className='relative'>
          <button
            className={classNames(
              'btn btn-ghost absolute overflow-visible',
              isRtl ? 'right-0 rotate-180' : 'left-0',
            )}
            onClick={() => {
              nav(-1);
            }}
          >
            <ImArrowLeft2 size={20} />
          </button>
        </div>
      )}
      <div className='flex-col w-full h-full py-4 px-8'>
        {showFullBar ? (
          <ProgressBar current={4} stepCount={4} />
        ) : (
          <>
            {isStepTree && treeIdx != null ? (
              <ProgressBar current={treeIdx} stepCount={size(survey?.questionTreeKeys)} />
            ) : (
              <Logo className='w-9' forPatient />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default memo(PaTreeHeader);
