import classNames from 'classnames';
import { isEmpty, omit, trim } from 'lodash';
import { InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ModeT, QuestionProps } from '../../pa-prop-types';
import { QsOptions } from '../../patient-types';
import { propsToIgnore } from './qs-const';
import SignaturePad from '../../../common/signature-pad/SignaturePad';

function QsInputSignature(
  props: InputHTMLAttributes<HTMLInputElement> &
    QuestionProps & {
      isDisplayMode?: boolean;
      options?: QsOptions | null;
      onUpdate: (value?: string | null) => void;
      focusOnLoad?: boolean;
      mode?: ModeT;
    }
) {
  const { focusOnLoad, isDisplayMode, onUpdate, className, value, error, mode } = props;


  // RE INIT VALUE WHEN NEW VALUE PROVIDED
  const [initialValue, setInitialValue] = useState(value);
  useEffect(() => {
    if (initialValue !== value) {
      setInitialValue(value);
      setValueTemp(value != null ? `${value}` : '');
    }
  }, [value]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (focusOnLoad && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [focusOnLoad]);

  const [valueTemp, setValueTemp] = useState(`${value}` || '');
  // const [valueDebbed] = useDebounce(valueTemp, 300);
  const [valueDebbed] = useDebounce(valueTemp, 150);

  const hasValue = !isEmpty(trim(valueDebbed));

  const valid = true;
  const errorMessage = null;

  useEffect(() => {
    if (value !== valueDebbed) {
      onUpdate(valueDebbed);
    }
  }, [valueDebbed]);

  return (
    <div className="QsInputSignature" ref={inputRef}>
      {errorMessage ? <div className='text-error opacity-70 p-1'>{errorMessage}</div> : ''}
      <SignaturePad
        className={classNames(
          'QsInputSignature',
          'border input-bordered p-2 w-full rounded-xl',
          className,
          hasValue && !isDisplayMode && !valid && 'input-error',
          error && 'input-error'
        )}
        {...omit(props, propsToIgnore)}
        onUpdate={(data) => { if (onUpdate) onUpdate(data); }}
        initialValue={value as string}
        printMode={mode === "pdf"}
      />
    </div>
  );
}

export default memo(QsInputSignature);
