import { memo, useEffect, useMemo, useState } from 'react';
import Picker from 'react-mobile-picker';

import { createIntArray, isNotEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import { first, isNaN, toNumber } from 'lodash';
import { useTranslations } from '../../hooks/use-translations';
import { QuestionProps } from '../../pa-prop-types';

function QsInputNumberUnit(props: QuestionProps) {
  const { question, updateAnswer, error, locale, treeState } = props;

  const [, { t }] = useTranslations({ locale });
  const { answerKeys, questionKey, typeOptions } = question || {};

  const { min, max } = typeOptions || {};

  const valueList = useMemo(() => {
    const minNum = toNumber(min);
    const maxNum = toNumber(max);

    let list: number[] = [];

    if (!isNaN(maxNum)) {
      list = createIntArray(maxNum).map((i) => i + 1);
    }

    if (!isNaN(minNum) && minNum > 0) {
      list = list.filter((i) => i >= minNum);
    }

    return list;
  }, [min, max]);

  const initialUnit = first(answerKeys);
  const initialValue = '';

  const answerValue = treeState?.answerMap[questionKey || ''] || {};
  const [formData, setFormData] = useState<{ value: string; unit: string }>({
    value: first(answerValue.answerValues) || initialValue,
    unit: first(answerValue.answerKeys) || initialUnit || '',
  });

  // to skip first initial load
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }

    if (!questionKey) return;

    if (isNotEmpty(formData.value) && isNotEmpty(formData.unit)) {
      updateAnswer?.({
        questionKey,
        answerKeys: [formData.unit],
        answerValues: [formData.value],
      });
    } else {
      updateAnswer?.({
        questionKey,
        answerKeys: [],
        answerValues: [],
      });
    }
  }, [formData]);

  if (!initialUnit) {
    return (
      <div>
        <span className='text-error'>Missing an answer</span>
      </div>
    );
  }

  return (
    <div className={classNames('t_QsInputNumberUnit', 'select-none flex-1', error ? 'border-error' : 'border')}>
      <Picker
        className='px-2'
        value={formData}
        onChange={(p) => {
          setFormData({ ...formData, ...p });
        }}
        wheelMode='natural'
        height={110}
      >
        <Picker.Column name='value'>
          <Picker.Item value={''}>
            {({ selected }) => (
              <div className={selected ? 'font-semibold' : 'opacity-70'}>{'-'}</div>
            )}
          </Picker.Item>

          {valueList.map((numValue) => {
            return (
              <Picker.Item key={numValue} value={`${numValue}`}>
                {({ selected }) => (
                  <div className={selected ? 'font-semibold' : 'opacity-70'}>{numValue}</div>
                )}
              </Picker.Item>
            );
          })}
        </Picker.Column>
        <Picker.Column name='unit'>
          {answerKeys?.map((answerKey) => {
            return (
              <Picker.Item key={answerKey} value={answerKey}>
                {({ selected }) => (
                  <div className={selected ? 'font-semibold' : 'opacity-70'}>{t(answerKey)}</div>
                )}
              </Picker.Item>
            );
          })}
        </Picker.Column>
      </Picker>
    </div>
  );
}

export default memo(QsInputNumberUnit);
