import { DateProviderFormat, SystemDate, formatDate, parseDate } from '@cyren/common-lib';
import classNames from 'classnames';
import DatePickerMobile from '../../../common/date-picker/DatePickerMobile';

import { subYears } from 'date-fns';
import { InputHTMLAttributes, memo, useState } from 'react';
import useAppState from '../../../app/use-app-state';
import { dateConfigPreset } from '../../../common/date-picker/date-picker-config';
import { ModeT, QuestionProps } from '../../pa-prop-types';

function QsInputDate(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
    QuestionProps & {
      onChange: (value: string) => void;
      mode?: ModeT;
    }
) {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [, { closeModalWithKey, openModalWithKey }] = useAppState();

  const { value, onChange, className, error, mode } = props;

  const firstValue = value;

  const startDate = firstValue ? parseDate(firstValue, SystemDate) || null : null;

  return (
    <div className="t_QsInputDate">
      <button
        className={classNames(
          'btn btn-primary btn-outline w-full rounded-xl border-1_5 border-black border-opacity-20',
          (mode === "pdf") && "pb-4",  // fix for PDF printing not handling flex centering properly
          className,
          error && 'border-error'
        )}
        onClick={() => {
          setOpenDatePicker(!openDatePicker);
          openModalWithKey('qs-input-date');
        }}
      >
        {startDate ? formatDate(startDate, { formatStr: DateProviderFormat }) : '----/--/--'}
      </button>
      {openDatePicker && (
        <DatePickerMobile
          dateConfig={dateConfigPreset}
          isOpen={openDatePicker}
          value={startDate || new Date()}
          max={new Date()}
          min={subYears(new Date(), 120)}
          onCancel={() => {
            setOpenDatePicker(false);
            closeModalWithKey('qs-input-date');
          }}
          onSelect={(d) => {
            setOpenDatePicker(false);
            onChange(formatDate(d, { formatStr: SystemDate }));
            closeModalWithKey('qs-input-date');
          }}
        />
      )}
    </div>
  );
}

export default memo(QsInputDate);
