import { createArray } from '@cyren/common-lib';
import { floor, size, toNumber } from 'lodash';

export function getRangeAdjusted({
  min: minStr,
  max: maxStr,
  step: stepStr,
}: {
  min: string;
  max: string;
  step: string;
}) {
  const min = toNumber(minStr);
  const maxNum = toNumber(maxStr);
  const step = toNumber(stepStr);
  const length = floor((maxNum - min) / step);
  const actualMax = length * step + min;

  const adjustedLen = length + 1;

  if (adjustedLen < 0) {
    const ticks = [null];
    return {
      min,
      max: min,
      step: 1,
      ticks,
      tickLen: size(ticks),
    };
  }

  const ticks = createArray(adjustedLen);

  return {
    min,
    max: actualMax,
    step,
    ticks,
    tickLen: size(ticks),
  };
}
