import produce from 'immer';
import { useRecoilValue } from 'recoil';
import { PaReportState } from '../patient/hooks/use-report';
import { conditionHelpers } from './helper-functions';
import { Keys, QuestionType } from './types';
import { PaDataRepoState } from './use-data';
import { difference, isEmpty, union } from 'lodash';
import { Enum_Survey_Access_Restriction } from '@cyren/common-lib';

export function useQuestionHelpers() {
  const reportState = useRecoilValue(PaReportState);
  const { survey } = useRecoilValue(PaDataRepoState);

  function refineQuestion({
    question: questionRaw,
    isDisplayMode,
  }: {
    question?: QuestionType;
    isDisplayMode?: boolean;
  }) {

    let question = questionRaw;
    const { optionConditions } = questionRaw || {};

    if (survey?.access_restriction && survey?.access_restriction === Enum_Survey_Access_Restriction.ConferenceDemo) {
      // in conference demo mode, only required system key questions are actually required
      if (question && question.required && isEmpty(question.systemKey)) {
        question = {
          ...question,
          required: false
        };
      }
    }

    if (isDisplayMode || !question || !questionRaw || !reportState) {
      return [{ question }] as const;
    }

    let exclusionMatches = [] as Keys;
    let inclusionMatches = [] as Keys;
    let inclusionFailures = [] as Keys;
    optionConditions?.forEach((condition) => {
      let matched = false;
      if (condition?.func != null) {
        const func = conditionHelpers?.[condition?.func];

        matched = func({ question: questionRaw, reportState, survey });
      }

      if (matched === true && condition.type === 'exclude') {
        exclusionMatches = [...exclusionMatches, ...(condition.keys) || []];
      }

      if (condition.type === 'include') {
        if (matched === true) {
          inclusionMatches = [...inclusionMatches, ...(condition.keys) || []];
        }
        else {
          inclusionFailures = [...inclusionFailures, ...(condition.keys) || []];
        }
      }
    });

    // remove any keys that were either a match for an exclusion rule, or were never allowed by
    // an inclusion rule
    const keysToRemove = union(exclusionMatches, difference(inclusionFailures, inclusionMatches));
    if (!isEmpty(keysToRemove)) {

      question =
        produce(question, (draft) => {
          if (!draft) return question;

          // remove exclude keys
          draft.answerKeys = draft.answerKeys?.filter((answerKey) => {
            return !keysToRemove.includes(answerKey);
          });

          return draft;
        }) || question;
    }

    return [{ question }] as const;
  }


  return [
    refineQuestion
  ]
}
