import { hasOnlyValidEnglishNameCharacters } from '@cyren/common-lib';
import classNames from 'classnames';
import { isEmpty, omit, toNumber, trim } from 'lodash';
import { InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { QuestionProps } from '../../pa-prop-types';
import { QsOptions } from '../../patient-types';
import { propsToIgnore } from './qs-const';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';

function QsTextInput(
  props: InputHTMLAttributes<HTMLInputElement> &
    QuestionProps & {
      isDisplayMode?: boolean;
      options?: QsOptions | null;
      onUpdate: (value?: string) => void;
      focusOnLoad?: boolean;
      restrictInput?: 'valid name' | null;
    }
) {
  const { focusOnLoad, isDisplayMode, onUpdate, className, value, options, error, restrictInput } = props;
  const { max, min } = options || {};
  const filteredProps = omit(props, [...propsToIgnore, 'restrictInput']);

  // RE INIT VALUE WHEN NEW VALUE PROVIDED
  const [initialValue, setInitialValue] = useState(value);
  useEffect(() => {
    if (initialValue !== value) {
      setInitialValue(value);
      setValueTemp(value != null ? `${value}` : '');
    }
  }, [value]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (focusOnLoad && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [focusOnLoad]);

  const { sltStr } = useStaticLocales();

  const [valueTemp, setValueTemp] = useState(`${value}` || '');
  // const [valueDebbed] = useDebounce(valueTemp, 300);
  const [valueDebbed] = useDebounce(valueTemp, 150);

  const hasValue = !isEmpty(trim(valueDebbed));

  let valid = true;
  let errorMessage = null;

  if (valid && min != null) {
    if (toNumber(value) < toNumber(min)) {
      valid = false;
    }
  }
  if (valid && max != null) {
    if (toNumber(value) > toNumber(max)) {
      valid = false;
    }
  }

  if (valid && !isEmpty(value) && restrictInput === 'valid name' && !hasOnlyValidEnglishNameCharacters(value)) {
    errorMessage = sltStr({ key: 'valid name' });
    valid = false;
  }

  useEffect(() => {
    if (value !== valueDebbed) {
      onUpdate(valueDebbed);
    }
  }, [valueDebbed]);

  return (
    <div className="QsTextInput">
      {errorMessage ? <div className='text-error opacity-70 p-1'>{errorMessage}</div> : ''}
      <input
        ref={inputRef}
        className={classNames(
          'input input-bordered w-full rounded-xl',
          className,
          hasValue && !isDisplayMode && !valid && 'input-error',
          error && 'input-error'
        )}
        {...filteredProps}
        value={valueTemp}
        onChange={(e) => {
          setValueTemp(e.target.value);
        }}
        min={min}
        max={max}
      />
      {(min != null || max != null) && (
        <div className='font-bold text-sm py-1'>
          * {min != null ? `${min} ` : ''}~{max != null ? ` ${max}` : ''}
        </div>
      )}
    </div>
  );
}

export default memo(QsTextInput);
