import { DateTimeFormat, SystemDateTimeSec, formatDate, parseDate } from '@cyren/common-lib';
import classNames from 'classnames';
import DatePickerMobile from '../../../common/date-picker/DatePickerMobile';

import { subYears } from 'date-fns';
import { InputHTMLAttributes, memo, useState } from 'react';
import useAppState from '../../../app/use-app-state';
import { datetimeConfigPreset } from '../../../common/date-picker/date-picker-config';
import { QuestionProps } from '../../pa-prop-types';

function QsInputDatetime(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
    QuestionProps & {
      onChange: (value: string) => void;
    }
) {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [, { closeModalWithKey, openModalWithKey }] = useAppState();

  const { value, onChange, className, error } = props;

  const firstValue = value;

  const startDate = firstValue ? parseDate(firstValue, SystemDateTimeSec) || null : null;

  return (
    <div className="t_QsInputDatetime">
      <button
        className={classNames(
          'btn btn-primary btn-outline w-full rounded-xl',
          className,
          error && 'border-error'
        )}
        onClick={() => {
          setOpenDatePicker(!openDatePicker);
          openModalWithKey('qs-input-datetime');
        }}
      >
        {startDate ? formatDate(startDate, { formatStr: DateTimeFormat }) : '----/--/-- --:--'}
      </button>
      {openDatePicker && (
        <DatePickerMobile
          dateConfig={datetimeConfigPreset}
          isOpen={openDatePicker}
          value={startDate || new Date()}
          max={new Date()}
          min={subYears(new Date(), 120)}
          onCancel={() => {
            setOpenDatePicker(false);
            closeModalWithKey('qs-input-datetime');
          }}
          onSelect={(d) => {
            setOpenDatePicker(false);
            onChange(
              formatDate(d, {
                formatStr: SystemDateTimeSec,
              })
            );
            closeModalWithKey('qs-input-datetime');
          }}
        />
      )}
    </div>
  );
}

export default memo(QsInputDatetime);
