import { QuestionProps, UpdateAnswerHandler } from '../../pa-prop-types';

import { InputHTMLAttributes, memo, useEffect, useState } from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { TbCirclePlus } from 'react-icons/tb';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import { useTranslations } from '../../hooks/use-translations';
import { AnswerValue, QuestionType } from '../../patient-types';
import { useQuestion } from '../../tree/use-question';
import BodyPartSelector from './body-select/BodyPartSelector';
import ModalBodySelect from './body-select/ModalBodySelect';
import './body-select/body-select.scss';

function QsInputBodySelectQs(
  props: QuestionProps &
    InputHTMLAttributes<HTMLInputElement> & {
      question: QuestionType;
      answerValue?: AnswerValue;
      updateAnswer?: UpdateAnswerHandler;
    }
) {
  const { onCancel, startOpen, updateAnswer, answerValue, locale, question } = props;
  const { sltStr } = useStaticLocales();

  const { questionKey } = question;
  const [, { t }] = useTranslations({ locale });

  const [openModal, setOpenModal] = useState(false);
  const { isBodyWholeSubpartSelectTypes } = useQuestion(question);

  useEffect(() => {
    if (!openModal && startOpen) {
      setOpenModal(true);
    }
  }, [startOpen]);

  if (isBodyWholeSubpartSelectTypes) {
    return (
      <div className='qs-input-body-select-qs'>
        <BodyPartSelector
          {...props}
          standalone
          onSave={(nMap) => {
            updateAnswer?.({
              questionKey,
              ...nMap,
            });
          }}
        />
      </div>
    );
  }

  return (
    <>
      {openModal && (
        <ModalBodySelect
          {...props}
          onClose={() => {
            setOpenModal(false);
            onCancel?.();
          }}
          onSave={(nMap) => {
            setOpenModal(false);
            updateAnswer?.({
              questionKey,
              ...nMap,
            });
          }}
        />
      )}

      <div className='t_QsInputBodySelectQs flex-col gap-3'>
        {/* IN TREE, BODY SELECT QUESTION */}
        {/* SHOW SELECTED ANSWERS */}
        <div className='flex-col gap-3'>
          {answerValue?.answerKeys?.map((answerKey) => {
            const note = answerValue?.notesForKeys?.[answerKey];
            return (
              <div
                key={answerKey}
                className='p-1 border rounded-xl bg-primary bg-opacity-10 flex gap-2'
              >
                <span className='flex-1 p-3'>
                  {t(answerKey)} {note ? ` (${note})` : null}
                </span>

                <span className='flex-none flex gap-0 px-2'>
                  <button
                    className='btn btn-ghost px-2'
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <BiPencil size={20} />
                  </button>
                  <button
                    className='btn btn-ghost px-2'
                    onClick={() => {
                      updateAnswer?.({
                        questionKey,
                        answerKeys: answerValue?.answerKeys?.filter((i) => i !== answerKey),
                      });
                    }}
                  >
                    <BiTrash size={20} />
                  </button>
                </span>
              </div>
            );
          })}
        </div>

        <div className='flex-col'>
          <button
            className='btn btn-outline btn-ghost btn-lg'
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <TbCirclePlus size={23} /> {sltStr({ key: 'btn-bodypart', upper: true })}
          </button>
        </div>
      </div>
    </>
  );
}

export default memo(QsInputBodySelectQs);
