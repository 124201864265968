import { getCountriesOptions, getLabelForCountryKey } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { omit, uniq } from 'lodash';
import { InputHTMLAttributes, memo } from 'react';
import { BiMinusCircle } from 'react-icons/bi';

import { Keys } from '../../../report/types';

const countries = getCountriesOptions();

function QsInputSelectCountry(
  props: InputHTMLAttributes<HTMLSelectElement> & {
    multi?: boolean;
    onChangeValues?: (values: Keys) => void;
    values?: Keys;
  }
) {
  const { onChangeValues, values, multi, className } = props;

  if (multi) {
    return (
      <div className='t_QsInputSelectCountry'>
        {values && (
          <ul className='pb-2'>
            {values?.map((value) => {
              return (
                <li key={value} className='flex-center-y justify-between py-2'>
                  <div>{getLabelForCountryKey(value)}</div>
                  <div
                    className='cursor-pointer'
                    onClick={() => {
                      if (!onChangeValues) return;

                      const nValues = produce(values || [], (draft) => {
                        return draft.filter((v) => v !== value);
                      });

                      onChangeValues(uniq(nValues));
                    }}
                  >
                    <BiMinusCircle size={23} className='text-red-500 cursor-pointer' />
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        <select
          className={classNames('select select-bordered w-full', className)}
          {...omit(props, ['multi'])}
          onChange={(e) => {
            if (!onChangeValues) return;
            const nValue = e.target.value;
            const selected = values?.includes(nValue);

            const nValues = produce(values || [], (draft) => {
              if (selected) {
                draft = draft.filter((v) => v !== nValue);
              } else {
                draft.push(nValue);
              }

              return draft;
            });

            onChangeValues(uniq(nValues));
          }}
        >
          <option value={''}>--</option>
          {countries.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  return (
    <div className=''>
      <select
        className={classNames('select select-bordered w-full', className)}
        {...omit(props, ['multi'])}
      >
        <option value={''}>--</option>
        {countries.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default memo(QsInputSelectCountry);
