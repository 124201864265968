import { DateTimeSecFormat, PatientDobFormat, dateStrToAge, dateStrToAgeDays, dateStrToAgeMonths, parseDate, parseDuration } from '@cyren/common-lib';
import { differenceInHours } from 'date-fns';
import { first, get, isArray, isEmpty, keys, size, uniq, values } from 'lodash';
import { TSurvey } from '../graphql-enhanced';
import { getTopLevelQsState } from '../patient/hooks/use-report';
import { QuestionType, ReportStateType } from '../patient/patient-types';
import { femaleKey, maleKey, onsetQuestionKey } from './report-config';
import { ConditionHelper, Keys, TreeState } from './types';

const conditionHelpersPure = {
  isFemale: ({
    survey,
    reportState,
  }: {
    survey?: TSurvey | null;
    question?: QuestionType;
    reportState?: ReportStateType;
  }) => {
    const topLevelQsState = getTopLevelQsState({
      reportState,
      survey,
    });

    const sex = get(topLevelQsState, ['q-patient-sex', 'answerKeys', 0]);

    return sex === femaleKey;
  },
  isMale: ({
    survey,
    reportState,
  }: {
    survey?: TSurvey | null;
    question?: QuestionType;
    reportState?: ReportStateType;
  }) => {
    const topLevelQsState = getTopLevelQsState({
      reportState,
      survey,
    });

    const sex = get(topLevelQsState, ['q-patient-sex', 'answerKeys', 0]);
    return sex === maleKey;
  },
  moreThan2PIs: ({ treeState }: { treeState?: TreeState }) => {
    const consolidatedAnswerKeys = uniq(
      values(treeState?.answerMap).reduce((p, i) => {
        if (i?.answerKeys && !isEmpty(i?.answerKeys) && isArray(i?.answerKeys)) {
          return [...p, ...i.answerKeys];
        }

        return p;
      }, [] as Keys)
    );

    return size(consolidatedAnswerKeys) > 1;
  },
  isOnsetLess24h: ({ treeState }: { treeState?: TreeState }) => {
    const { answerValues } = treeState?.answerMap[onsetQuestionKey] || {};

    const dateStr = first(answerValues);

    if (dateStr) {
      const date = parseDate(dateStr, DateTimeSecFormat);
      const hourDiff = differenceInHours(new Date(), date);
      const isLess24h = hourDiff <= 24;

      return isLess24h;
    }

    return false;
  },
  possiblePregnancy: ({ treeState }: { treeState?: TreeState }) => {
    const { answerKeys, answerValues } = treeState?.answerMap?.['q-patient-sex'] || {};

    let isFemale = false;
    let isPossibleAge = false;

    const bday = treeState?.answerMap['q-patient-birthday']?.answerValues?.[0];

    if (bday) {
      const age = dateStrToAge(bday, {
        inputFormat: PatientDobFormat,
      });
      isPossibleAge = age >= 15 && age <= 50;
    } else {
      return false;
    }

    if (!isPossibleAge) return false;

    if (isArray(answerKeys)) {
      isFemale = answerKeys.includes('o-female');
    } else {
      isFemale = `${answerValues}`.includes('o-female');
    }

    return isFemale;
  },
  isNeonate: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    if (bday) {
      const ageDays = dateStrToAgeDays(bday as string, {
        inputFormat: PatientDobFormat,
      });

      if (ageDays <= 28) {
        return true;
      }
    }
    return false;
  },
  isYoungInfant: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    if (bday) {
      const ageDays = dateStrToAgeDays(bday as string, {
        inputFormat: PatientDobFormat,
      });
      const ageMonths = dateStrToAgeMonths(bday as string, {
        inputFormat: PatientDobFormat,
      });

      if (ageDays >= 29 && ageMonths < 3) {
        return true;
      }
    }
    return false;
  },
  isOldInfant: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    if (bday) {
      const ageMonths = dateStrToAgeMonths(bday as string, {
        inputFormat: PatientDobFormat,
      });

      if (ageMonths >= 3 && ageMonths < 12) {
        return true;
      }
    }
    return false;
  },
  isToddler: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    let age: number | null = null;
    if (bday) {
      age = dateStrToAge(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }
    else if (reportState?.systemValueMap?.age) {
      const duration = parseDuration(reportState?.systemValueMap?.age);
      if (duration.unit === "yr") {
        age = duration.amount;
      }
    }

    if (age) {
      if (age >= 1 && age < 3) {
        return true;
      }
    }

    return false;
  },
  isPreschoolAge: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    let age: number | null = null;
    if (bday) {
      age = dateStrToAge(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }
    else if (reportState?.systemValueMap?.age) {
      const duration = parseDuration(reportState?.systemValueMap?.age);
      if (duration.unit === "yr") {
        age = duration.amount;
      }
    }

    if (age) {
      if (age >= 3 && age < 5) {
        return true;
      }
    }

    return false;
  },
  isSchoolAge: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    let age: number | null = null;
    if (bday) {
      age = dateStrToAge(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }
    else if (reportState?.systemValueMap?.age) {
      const duration = parseDuration(reportState?.systemValueMap?.age);
      if (duration.unit === "yr") {
        age = duration.amount;
      }
    }

    if (age) {
      if (age >= 5 && age < 12) {
        return true;
      }
    }
    return false;
  },
  isAdolescent: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    let age: number | null = null;
    if (bday) {
      age = dateStrToAge(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }
    else if (reportState?.systemValueMap?.age) {
      const duration = parseDuration(reportState?.systemValueMap?.age);
      if (duration.unit === "yr") {
        age = duration.amount;
      }
    }

    if (age) {
      if (age >= 12 && age < 18) {
        return true;
      }
    }
    return false;
  },
  isUnderAge2: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    let age: number | null = null;
    if (bday) {
      age = dateStrToAge(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }
    else if (reportState?.systemValueMap?.age) {
      const duration = parseDuration(reportState?.systemValueMap?.age);
      if (duration.unit === "yr") {
        age = duration.amount;
      }
    }

    if (age) {
      if (age < 2) {
        return true;
      }
    }
    return false;
  },
  isOverAge2: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    const bday = reportState?.systemValueMap?.dob;
    let age: number | null = null;
    if (bday) {
      age = dateStrToAge(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }
    else if (reportState?.systemValueMap?.age) {
      const duration = parseDuration(reportState?.systemValueMap?.age);
      if (duration.unit === "yr") {
        age = duration.amount;
      }
    }

    if (age) {
      if (age >= 2) {
        return true;
      }
    }
    return false;
  },
  isReproductiveAgeFemale: ({
    reportState,
  }: {
    reportState?: ReportStateType;
  }) => {
    if (reportState?.systemValueMap?.sex === "o-female") {
      const bday = reportState?.systemValueMap?.dob;
      let age: number | null = null;
      if (bday) {
        age = dateStrToAge(bday as string, {
          inputFormat: PatientDobFormat,
        });
      }
      else if (reportState?.systemValueMap?.age) {
        const duration = parseDuration(reportState?.systemValueMap?.age);
        if (duration.unit === "yr") {
          age = duration.amount;
        }
      }
      if (age) {

        if (age >= 15 && age < 50) {
          return true;
        }
      }
    }
    return false;
  },
};


export function matchAgeRange({ reportState, ageMax, ageMin, ageUnit }:
  {
    reportState?: ReportStateType,
    ageMax?: number | null,
    ageMin?: number | null,
    ageUnit?: 'months' | 'years' | null
  }) {

  const bday = reportState?.systemValueMap?.dob;
  if (bday) {
    let age = 0;
    if (ageUnit === 'months') {
      age = dateStrToAgeMonths(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }
    else {
      age = dateStrToAge(bday as string, {
        inputFormat: PatientDobFormat,
      });
    }

    if (ageMax && age > ageMax) {
      return false;
    }
    if (ageMin && age < ageMin) {
      return false;
    }
    return true;
  }

  return false;
}

export const helperFuncKeys = keys(conditionHelpersPure);

export type HelperFuncKeyT = keyof typeof conditionHelpersPure;

export const conditionHelpers: Record<
  HelperFuncKeyT,
  ConditionHelper<boolean>
> = conditionHelpersPure;
