import sdohQuestions from '../../assets/files/sdoh-questions.json';
import { notNull } from '../utils';
import { convertItemToQuestion, filterQuestions, mapItemToDescLocale, mapItemToLocale, } from '../utils/csv';
export function fetchSdohQsLocales() {
    const list = sdohQuestions.map(mapItemToLocale);
    const descList = sdohQuestions.map(mapItemToDescLocale);
    return [...list, ...descList];
}
export function fetchSdohQsTreeByKey(key, options) {
    const trQs = fetchSdohQuestionsByTreeKey(key);
    return {
        ...options,
        treeKey: key,
        entryQuestionKeys: trQs.map((q) => q.questionKey),
    };
}
export function fetchSdohQuestions() {
    return sdohQuestions.map(convertItemToQuestion).filter(notNull);
}
export function fetchSdohQuestionsByTreeKey(treeKey) {
    return filterQuestions(sdohQuestions
        .filter((item) => item['tree-key'] === treeKey)
        .map(convertItemToQuestion)
        .filter(notNull));
}
