import { getBrowserLocales, locales } from '@cyren/common-lib';
import classNames from 'classnames';
import { capitalize, isEmpty, lowerCase, trim } from 'lodash';
import { MdClose } from 'react-icons/md';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { H3 } from '../common/typo/H';
import Modal from '../components/Modal';
import { useDebInputState } from '../hooks/use-deb-input-state';
import useAppState from './use-app-state';

function ModalLocale() {
  const [
    {
      appState: { openLocale },
      appStateCookie: { userLocaleKey },
    },
    { setLocale },
  ] = useAppState();

  const browserLangKeys = getBrowserLocales();

  const fetching = false;

  const { input, ref, setInput, debInput } = useDebInputState({ noInitFocus: true });
  const hasUserInput = !!userLocaleKey;

  const reorderedList = locales.sort((a, b) => {
    // @ts-ignore
    const found1 = browserLangKeys.includes(a.key);
    // @ts-ignore
    const found2 = browserLangKeys.includes(b.key);
    if (found1 && found2) {
      return 0;
    }
    if (found1 && !found2) {
      return -1;
    }

    return 1;
  });

  const localesFiltered = !isEmpty(trim(debInput))
    ? reorderedList?.filter((l) => {
        return (
          lowerCase(l.label).includes(lowerCase(debInput)) ||
          lowerCase(l.key).includes(lowerCase(debInput))
        );
      })
    : reorderedList;

  return (
    <Modal
      open={openLocale}
      className='ModalSxCollector h-full rounded-t-xl mt-6 px-0 overflow-hidden py-0 pb-4'
    >
      <div className='flex-1 flex-col gap-1 overflow-hidden'>
        <div className='flex-col flex-none gap-2 overflow-hidden pb-4'>
          <div className='flex-none flex-center'>
            {/* top modal handle */}
            <div className='bg-black bg-opacity-10 rounded-full w-10 h-2 mt-3'></div>
          </div>
          <div className='flex-none flex-center-y gap-2 px-5'>
            <H3 className='flex-1 text-center'>Choose a language</H3>
            <div className='w-0 overflow-visible'>
              <button
                className={classNames('p-2 inline', !hasUserInput && 'hidden')}
                onClick={async () => {
                  if (userLocaleKey) setLocale(userLocaleKey);
                }}
              >
                <MdClose size={20} className='opacity-60 -top-4 relative right-7' />
              </button>
            </div>
          </div>
        </div>

        <div className='flex-col flex-1 overflow-hidden'>
          <div className='form-control border-y flex-none'>
            <div className='flex'>
              <span className={classNames('flex-none flex-center p-2 px-3 bg-transparent')}>
                <div
                  className={classNames(
                    'block relative w-0 overflow-visible h-0',
                    !fetching && 'opacity-0'
                  )}
                >
                  <div className='absolute -top-2'>
                    <span className='loading loading-spinner loading-xs opacity-50'></span>
                  </div>
                </div>
                <PiMagnifyingGlass
                  size={18}
                  className={classNames('opacity-60', fetching && 'opacity-0')}
                />
              </span>
              <input
                type='text'
                ref={ref}
                value={input}
                placeholder='SEARCH'
                className='flex-1 border-none py-3 pr-2 outline-none text-sm'
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
            </div>
          </div>

          <div className='flex-col flex-1 overflow-auto max-h-[40em]'>
            {localesFiltered.map((locale) => {
              // @ts-ignore
              const preferred = browserLangKeys.includes(locale.key);

              return (
                <div
                  className={classNames(
                    'px-10 py-3 border-b cursor-pointer',
                    'hover:bg-gray-200', 
                    userLocaleKey === locale.key ? 'bg-primary bg-opacity-20' : 'bg-base-100',
                    preferred && 'font-bold'
                  )}
                  key={locale.key}
                  onClick={async () => {
                    setLocale(locale.key);
                  }}
                >
                  {capitalize(locale.label)}{' '}
                  {locale.label !== locale.key && (
                    <span className='opacity-50 text-xs'>({capitalize(locale.key)})</span>
                  )}
                </div>
              );
            })}
          </div>

        </div>
      </div>
    </Modal>
  );
}

export default ModalLocale;
