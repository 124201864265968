
export const helperFuncMap: Record<string, string> = {
    isAdolescent: 'Is Adolescent',
    isFemale: 'Is Female',
    isMale: 'Is Male',
    isNeonate: 'Is Neonate',
    isOldInfant: 'Is Old Infant',
    isOnsetLess24h: 'Is Onset Less Than 24 Hours',
    isOverAge2: 'Is Over Age 2',
    isPreschoolAge: 'Is Preschool Age',
    isReproductiveAgeFemale: 'Is Reproductive Age Female',
    isSchoolAge: 'Is School Age',
    isToddler: 'Is Toddler',
    isUnderAge2: 'Is Under Age 2',
    isYoungInfant: 'Is Young Infant',
    moreThan2PIs: 'More Than 2 Primary Issues',
    possiblePregnancy: 'Possible Pregnancy'
  };
  