import { first, replace, startsWith, upperCase } from 'lodash';

export function parseSex({ sexStr }: { sexStr?: string | null }) {
  if (!sexStr) return '-';

  let str = sexStr;
  if (startsWith(str, 'o-')) {
    str = replace(str, 'o-', '');
  }

  return upperCase(first(str));
}
