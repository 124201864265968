import { useForm } from 'react-hook-form';
import { useCopyToClipboard } from 'usehooks-ts';

function PaBasicInfo() {
  const { register, getValues } = useForm<{
    firstName: string;
    lastName: string;
    sex: string;
    age: string;
    visited: boolean;
  }>({
    defaultValues: {
      firstName: 'John',
      lastName: 'Doe',
      age: '32',
      sex: 'Male',
      visited: true,
    },
  });

  const [, copy] = useCopyToClipboard();

  return (
    <div className=''>
      <div className='p-8'>
        <div className='flex-1 flex-col gap-4 overflow-auto'>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>First name</span>
              </label>
              <input type='text' className='input input-bordered' {...register('firstName')} />
            </div>

            <button
              onClick={() => {
                const v = getValues('firstName');
                copy(getValues('firstName'));
                // copy('');
                window.electron?.ipcRenderer?.sendMessage('send-request', v);
              }}
            >
              Move
            </button>
          </div>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Last name</span>
              </label>
              <input type='text' className='input input-bordered' {...register('lastName')} />
            </div>
          </div>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Age</span>
              </label>
              <input type='text' className='input input-bordered' {...register('age')} />
            </div>
          </div>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Sex</span>
              </label>
              <input type='text' className='input input-bordered' {...register('sex')} />
            </div>
          </div>
          <div>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Visited before</span>
              </label>
              <input type='checkbox' className='checkbox' {...register('visited')} />
            </div>
          </div>
          <div>
            <button
              className='btn btn-primary'
              onClick={() => {
                const values = getValues();

                const arg = [
                  values.firstName,
                  '',
                  values.lastName,
                  values.sex,
                  values.visited,
                  '',
                  values.age,
                ];

                window.electron?.ipcRenderer?.sendMessage('send-fields', arg);
              }}
            >
              Copy over each fields
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaBasicInfo;
