import { memo } from 'react';
import { toast } from 'react-toastify';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import Footer from '../../app/Footer';
import thumbsUp from '../../assets/ill/Thumbs_up.svg';
import LoadingAnim from '../../components/LoadingAnim';
import Logo from '../../components/Logo';
import { useOnNavChange } from '../../hooks/use-on-nav-change';
import { usePatientTransData } from '../../report/use-patient-trans-data';
import { useTranslations } from '../hooks/use-translations';
import PaTreeHeader from '../tree/PaTreeHeader';

function PaAdhocEndPage() {

  const { sltStr } = useStaticLocales();

  const [, { t }] = useTranslations();

  useOnNavChange({
    block: true,
    onChange: () => {
      toast.info(sltStr({ key: 'msg-cannot-go-back' }), {
        toastId: 'msg-cannot-go-back',
      });
    },
  });

  const titleLabel = t('finish-page-title', {}, { fallbackValue: null });

  const [{ loadingTrans }] = usePatientTransData({
    // keys: [...pageKeys, ...transKeys],
    keys: []
  });

  if (loadingTrans) {
    return (
      <div className='flex flex-1 w-full'>
        <LoadingAnim className='h-full p-10 w-full' />
      </div>
    );
  }

  return (
    <div className='flex-1 flex-col gap-5 justify-between'>
      <div className='flex-1 flex-col gap-5'>
        <PaTreeHeader showFullBar noBack />

        <div className='px-5'>
          <div className='flex-center'>
            <Logo className='w-9' forPatient />
          </div>

          <div className='flex-center flex-col gap-4'>
            <div className='pt-12 pb-6 w-1/3'>
              <img src={thumbsUp} />
            </div>

            <div className='flex-col gap-2 flex-center-y'>
              <h4 className='font-bold sm'>{titleLabel || sltStr({ key: 'finished-title' })}</h4>
            </div>
          </div>
        </div>
      </div>

      <div className='hidden flex-none'>
        <Footer />
      </div>
    </div>
  );
}

export default memo(PaAdhocEndPage);
