import { InputHTMLAttributes, memo } from 'react';

import classNames from 'classnames';
import { QuestionProps } from '../../pa-prop-types';
import { useData } from '../../../report/use-data';
import { H2, H4 } from '../../../common/typo/H';
import { FaCheck } from 'react-icons/fa';
import { genStepDescKey, genStepNameKey } from '../../../utils/models/trees';
import { useTranslations } from '../../hooks/use-translations';
import heroImage from './assets/mile-marker-hero-computer.svg';


function QsStageMileMarker(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
    QuestionProps
) {
  const {
    treeKey,
    locale,
    question,
    onNext
  } = props;

  const [, { t }] = useTranslations({ locale });

  const [, { getSurvey, getTreeByKey }] = useData();

  const stages = getSurvey()?.questionTreeKeys?.
    map(aTreeKey => getTreeByKey(aTreeKey)).
    filter(tree => tree?.stageBoundary);
  let foundCurrentStage = false;
  const questionTitle = t(question?.questionKey);

  return (
    <div className='t_QsStageMileMarker flex-col gap-6 items-center'>
      <img src={heroImage} className='w-[60%] pt-12 pb-12' />
      <H2 className="self-start pb-1">{questionTitle !== question?.questionKey && questionTitle}</H2>
      <div className='flex-col p-4'>
        {
          stages?.map((tree, idx) => {

            const currentStage = (tree!.treeKey === treeKey);
            if (currentStage) {
              foundCurrentStage = true;
            }
            const completedStage = !foundCurrentStage && !currentStage;
            const unreachedStage = foundCurrentStage && !currentStage;

            const nameKey = genStepNameKey(tree);
            const descKey = genStepDescKey(tree);
            const treeLabel = t(nameKey || '', {}, { fallbackValue: '' });
            const desc = t(descKey || '', {}, { fallbackValue: '' });

            return (
              <div key={tree!.stepNameKey}>
                <div className={classNames("flex-row w-full items-center",
                  currentStage && "bg-white border-primary cursor-pointer")}
                  onClick={() => {
                    if (currentStage && onNext) {
                      onNext();
                    }
                  }}
                  role={currentStage ? "button" : "heading"}
                  tabIndex={currentStage ? 0 : -1}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      if (currentStage && onNext) {
                        onNext();
                      }
                      event.preventDefault(); // Prevent default scrolling behavior when space is pressed
                    }
                  }}
                >
                  <div className={
                    classNames("w-8 h-8 min-w-8 rounded-full ",
                      "border-2 z-10",
                      "flex-center",
                      completedStage && "bg-primary border-primary",
                      unreachedStage && "bg-white border-gray",
                      currentStage && "bg-white border-primary")}
                  >
                    {completedStage && <FaCheck aria-label="Completed" className="text-white" />}
                  </div>
                  <H4 className={classNames("pl-4", !currentStage && "opacity-50")}>
                    {treeLabel}
                  </H4>
                </div>
                <div className="flex-row w-full min-h-6 items-stretch">
                  <div className="w-8 min-w-8" style={{ position: 'relative' }}>
                    {(idx < (stages.length - 1)) &&
                      <div
                        className="border-l border-l-2 border-l-gray"
                        style={{ position: 'absolute', top: '-20px', bottom: '-20px', left: '50%' }}
                      />
                    }
                  </div>
                  <div className="pl-4 pb-6 text-sm">
                    {currentStage && desc}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default memo(QsStageMileMarker);
