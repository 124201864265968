import { first } from 'lodash';
export function focusWithId(el, id) {
    const extNoteEl = first(el.getElementsByClassName(id));
    // @ts-ignore
    extNoteEl?.focus();
}
export function focusWithIdTimeOut(el, id, time) {
    const trashCanEl = first(el.getElementsByClassName('qs-item-trashcan'));
    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.style.display = 'inline-block';
    fakeInput.style.opacity = '0';
    fakeInput.style.height = '0';
    fakeInput.style.width = '0';
    fakeInput.style.fontSize = '16px'; // disable auto zoom
    // you may need to append to another element depending on the browser's auto
    // zoom/scroll behavior
    trashCanEl?.appendChild(fakeInput);
    // focus so that subsequent async focus will work
    fakeInput.focus();
    setTimeout(() => {
        focusWithId(el, id);
        // cleanup
        // fakeInput.remove();
    }, time);
}
