import { Route, Routes } from 'react-router-dom';

import PaAdhocEndPage from '../patient/adhoc/PaAdhocEndPage';
import PaAdhocHomePage from '../patient/adhoc/PaAdhocHomePage';
import PaSurveyEndPage from '../patient/survey/PaSurveyEndPage';
import PaSurveyHomePage from '../patient/survey/PaSurveyHomePage';
import PaTreePage from '../patient/tree/PaTreePage';
import { ThemeProvider } from '@emotion/react';
import { patientTheme } from './mui-theme';

export default function AppRoutesPatient() {
  // NOTE update use-pa-survey-params when route changes
  return (
    <ThemeProvider theme={patientTheme}>
      <Routes>
        <Route path=':orgId/:shortId/adhoc/:adhocId/finished' element={<PaAdhocEndPage />} />
        <Route path=':orgId/:shortId/adhoc/:adhocId' element={<PaAdhocHomePage />} />
        <Route path=':orgId/:shortId/finished' element={<PaSurveyEndPage />} />
        <Route path=':orgId/:shortId/addon/:referralMessageId' element={<PaSurveyHomePage />} />
        <Route path=':orgId/:shortId/:treeKey' element={<PaTreePage />} />
        <Route path=':orgId/:shortId' element={<PaSurveyHomePage />} />
      </Routes>
    </ThemeProvider>
  );
}
