import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import LoadingAnim from '../../components/LoadingAnim';

export default function PageLoading({ className }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'flex-center flex-1 overflow-hidden',
        // { 'bg-base-100': !hasBgClass },
        className
      )}
    >
      <LoadingAnim />
    </div>
  );
}
