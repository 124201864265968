/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseDateV2 } from '@cyren/common-lib';
import { InputMask } from '@react-input/mask';
import { InputHTMLAttributes, memo, useEffect, useState } from 'react';

import classNames from 'classnames';
import { differenceInYears } from 'date-fns';
import { startsWith } from 'lodash';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import Modal from '../../../components/Modal';
import { QuestionProps } from '../../pa-prop-types';

// Define the types for the HTMLInputElement which can be either an input or textarea, and the positions as numbers.
function setSelectionRange(
  input: HTMLInputElement | HTMLTextAreaElement,
  selectionStart: number,
  selectionEnd: number,
): void {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
  } else if ((input as any).createTextRange) {
    // Handling this as a special case since createTextRange does not exist on the standard types
    const range = (input as any).createTextRange();
    range.collapse(true);
    range.moveEnd('character', selectionEnd);
    range.moveStart('character', selectionStart);
    range.select();
  }
}

function setCaretToPos(input: HTMLInputElement | HTMLTextAreaElement, pos: number): void {
  setSelectionRange(input, pos, pos);
}

const format = 'MM/DD/YYYY';

function QsInputDateDob(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
    QuestionProps & {
      onChange: (value: string) => void;
    },
) {
  const {
    value,
    onChange,
    error,
    question,
    // className, error
  } = props;

  const { slt } = useStaticLocales();
  const [dateInput, setDateInput] = useState(`${value || format}`);
  const [age, setAge] = useState<number | null>(null);
  const ageRestriction = question?.typeOptions?.ageRestriction;
  const [blockByAge, setBlockByAge] = useState(false);

  useEffect(() => {
    const validDate = parseDateV2({
      dateStr: dateInput,
      formatStr: 'MM/dd/yyyy',
    });

    if (validDate) {
      onChange(dateInput);

      if (ageRestriction) {
        const nAge = differenceInYears(new Date(), validDate);
        setAge(nAge);
      }
    } else {
      onChange('');
    }
  }, [dateInput, ageRestriction]);

  return (
    <div className='t_QsInputDateDob flex-col gap-3'>
      <div className='flex-col gap-1'>
        <InputMask
          separate
          className={classNames(
            'input tracking-widest w-[10rem] max-w-auto',
            error && 'input-error',
          )}
          value={dateInput}
          onChange={(e) => {
            setDateInput(e.target.value);
          }}
          pattern='[0-9]*'
          onFocus={(e) => {
            if (startsWith(e.target.value, 'M')) {
              setTimeout(() => {
                setCaretToPos(e.target, 0);
              }, 50);
            }
          }}
          mask='MM/DD/YYYY'
          onBlur={() => {
            if (ageRestriction && age != null && (age === 0 || age < ageRestriction)) {
              setBlockByAge(true);
            }
          }}
          replacement={{ M: /\d/, Y: /\d/, D: /\d/ }}
          showMask
        />
        <span className='text-xs opacity-70 px-4'>{slt({ key: 'mon-day-year' })} </span>
      </div>
      {blockByAge && (
        <Modal open>
          <div
            className='flex flex-col justify-center items-center gap-7'
            onClick={() => setBlockByAge(false)}
          >
            <div className='text-2xl font-bold text-center'>Age restriction applied</div>
            <div className='text-center'>
              You MUST not use this app, if you are under {ageRestriction} years old.
              <br />
              Please close the app immediately.
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default memo(QsInputDateDob);
