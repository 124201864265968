import produce from 'immer';
import { uniq } from 'lodash';
import { memo } from 'react';
import { getMutualExclusiveAnswerKeys } from '../../../utils/models/question';
import { useReportState } from '../../hooks/use-report';
import { QuestionProps } from '../../pa-prop-types';
import QsSxCollector from './QsSxCollector';

// NOTE this question type is for sx_select
// SEARCH TAG sx_select, isSxSelectType
function QsInputSxSelector(props: QuestionProps) {
  const { locale, mode, question, treeKey } = props;

  // eslint-disable-next-line
  // console.log('treeState', treeState);
  // console.log('props', props);
  // console.log('treeKey', treeKey);

  const [
    {
      treeState,
      // reportState,
      //
    },
    { updateAnswer, setReportState },
  ] = useReportState({ treeKey });

  if (!question) {
    return null;
  }

  const { questionKey } = question;
  const { answerMap } = treeState || {};

  const mutExcKeys = getMutualExclusiveAnswerKeys({ question });

  const qAnswerMap = answerMap?.[questionKey];
  const selectedKeys = qAnswerMap?.answerKeys || [];

  return (
    <QsSxCollector
      locale={locale}
      treeKey={treeKey}
      question={question}
      mode={mode}
      onChange={(e) => {
        if (!updateAnswer) return;

        const { value, checked } = e.target;
        const isMutExclOption = mutExcKeys.includes(value);

        if (treeKey && isMutExclOption && checked) {
          setReportState((st) => {
            return produce(st, (draft) => {
              // 1. remove all other answers
              const treeIndex = draft.treeStates.findIndex((ts) => ts.treeKey === treeKey);

              if (!draft.treeStates[treeIndex]?.answerMap[questionKey]) {
                draft.treeStates[treeIndex].answerMap[questionKey] = {
                  answerKeys: [],
                  notesForKeys: {},
                };
              }

              // 1.1 select exclusive option
              if (draft.treeStates?.[treeIndex].answerMap?.[question.questionKey]) {
                // @ts-ignore
                draft.treeStates[treeIndex].answerMap[question.questionKey].answerKeys = [value];
              }

              // 2. delete selected symptom data
              draft.treeStates = draft.treeStates.filter((ts) => {
                return !selectedKeys.includes(ts.treeKey);
              });
            });
          });

          return;
        }

        let nKeys = checked
          ? uniq([...selectedKeys, value])
          : selectedKeys.filter((nv) => nv !== value);

        if (treeKey && !isMutExclOption && checked) {
          // 1. unset all exclusive options
          nKeys = nKeys.filter((k) => !mutExcKeys.includes(k));
        }

        updateAnswer({
          questionKey,
          answerKeys: nKeys,
        });
      }}
    />
  );
}

export default memo(QsInputSxSelector);
