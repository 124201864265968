import { pick, split, trim, uniq } from 'lodash';
import ctas from '../../assets/files/ctas.json';
import { notNull } from '../utils';
export function getSymptom(key) {
    if (key == null)
        return key;
    const sx = ctas.find((c) => c['ctas-sx-key'] === key);
    return sx;
}
export function fetchSymptoms({ location } = {}) {
    // console.log('ctas', ctas);
    const sxs = ctas.filter((c) => trim(c.include) === '1');
    const childSxs = sxs
        .filter((c) => !trim(c.ctas_parent))
        .map((c) => pick(c, ['ctas-sx-key', 'location', 'include']));
    const nonLocSxs = location ? childSxs.filter((c) => trim(c.location) === location) : childSxs;
    return nonLocSxs;
}
export function fetchSxLocales() {
    return ctas.map((ct) => {
        return {
            locale: 'english',
            key: ct['ctas-sx-key'],
            value: ct['ctas_sx_concept'],
        };
    });
}
export function fetchLocations() {
    const sxs = ctas.filter((c) => trim(c.include) === '1');
    const locList = sxs
        .map((ct) => {
        return ct['location'];
    })
        .filter((c) => notNull(c) && trim(c));
    const locations = locList.reduce((p, i) => {
        const l = split(i, ',')
            .map((i) => trim(i))
            .filter(notNull);
        return uniq([...p, ...l]);
    }, []);
    return locations;
}
