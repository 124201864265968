import {
  containLowerCase,
  fetchDictsLocales,
  fetchPmhxQsLocales,
  fetchQsPatientLocales,
  fetchRosQsLocales,
  fetchSdohQsLocales,
  fetchSxLocales,
  fetchSxQsLocales,
  fetchTriageQsLocales,
} from '@cyren/common-lib';
import { capitalize, keys, lowerFirst } from 'lodash';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { AppStateCookie } from '../app/use-app-state';
import { translations } from './mock-data-trans';
import { TOptions } from './prop-types';
import { tFunc } from './types';

export function useLocalization() {
  // eslint-disable-next-line
  const [appStateCookie, setAppStateCookie] = useRecoilState(AppStateCookie);

  const [locales] = useState([
    ...fetchQsPatientLocales(),
    ...translations,
    ...fetchSxLocales(),
    ...fetchTriageQsLocales(),
    ...fetchSxQsLocales(),
    ...fetchRosQsLocales(),
    ...fetchPmhxQsLocales(),
    ...fetchSdohQsLocales(),
  ]);

  const [sxDict] = useState([...fetchDictsLocales()]);

  // eslint-disable-next-line
  // console.log('locales', locales);
  // eslint-disable-next-line
  // console.log('sxDict', sxDict);

  function t(key?: string, tOptions?: TOptions, convOptions?: { cap?: boolean }) {
    const { cap } = convOptions || {};

    const label = locales.find((tra) => tra.locale === 'english' && tra.key === key);
    let output = label?.value || key;

    keys(tOptions).forEach((oKey) => {
      const oVal = tOptions?.[oKey];
      if (oVal) {
        output = output?.replaceAll(`{{${oKey}}}`, lowerFirst(oVal));
      }
    });

    if (cap && !containLowerCase(output)) {
      return capitalize(output);
    }

    return capitalize(output);
  }

  function trans({ key, type }: { key: string; type: 'patient' | 'doctor' | 'common' }) {
    const label = sxDict.find(
      (tra) => tra.locale === 'english' && tra.key === key && type === 'patient'
    );

    if (type === 'patient') {
      return label?.patient || label?.common;
    }

    if (type === 'doctor') {
      return label?.doctor || label?.common;
    }
    // TODO error handle
    return '';
  }

  const td: tFunc = (key?: string) => {
    const label = sxDict.find((tra) => tra.locale === 'english' && tra.key === key);

    const provLang = label?.doctor || label?.common;

    if (provLang) {
      return provLang;
    }

    return key;
  };

  const tp: tFunc = (key?: string) => {
    const label = sxDict.find(
      (tra) => tra.locale === (appStateCookie.localeKey || 'english') && tra.key === key
    );

    const provLang = label?.patient || label?.common;

    if (provLang) {
      return provLang;
    }

    return key;
  };

  // console.log('locales', locales);
  // console.log('sxDict', sxDict);

  return [{ appStateCookie }, { t, td, tp, trans }] as const;
}
