import { DateTimeSecFormat, formatDate, parseDate, TimeFormat } from '@cyren/common-lib';
import classNames from 'classnames';
import DatePickerMobile from '../../../common/date-picker/DatePickerMobile';

import { InputHTMLAttributes, memo, useState } from 'react';
import useAppState from '../../../app/use-app-state';
import { timeConfigPreset } from '../../../common/date-picker/date-picker-config';

function QsInputTime(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (value: string) => void;
  }
) {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [, { closeModalWithKey, openModalWithKey }] = useAppState();

  const { value, onChange, className } = props;

  const firstValue = value;

  const startDate = firstValue ? parseDate(firstValue, DateTimeSecFormat) || null : null;

  return (
    <div className="t_QsInputTime">
      <button
        className={classNames('btn btn-primary btn-outline w-full', className)}
        onClick={() => {
          setOpenDatePicker(!openDatePicker);
          openModalWithKey('qs-input-time');
        }}
      >
        {startDate ? formatDate(startDate, { formatStr: TimeFormat }) : '-- : --'}
      </button>
      <DatePickerMobile
        dateConfig={timeConfigPreset}
        isOpen={openDatePicker}
        value={startDate || new Date()}
        showHeader={false}
        showCaption
        onCancel={() => {
          setOpenDatePicker(false);
          closeModalWithKey('qs-input-time');
        }}
        onSelect={(d) => {
          setOpenDatePicker(false);
          onChange(formatDate(d));
          closeModalWithKey('qs-input-time');
        }}
      />
    </div>
  );
}

export default memo(QsInputTime);
