import { useEffect } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

export default function useScrollToTop({
  behavior,
}: { behavior?: ScrollBehavior | undefined } = {}) {
  const location = useLocation();

  const navType: NavigationType = useNavigationType();

  useEffect(() => {
    // skip scroll change
    if (location.pathname.match(/\/deep-pages\/\w\/\w/)) {
      return;
    }

    if (navType === 'PUSH') {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior,
      });
    }
  }, [location.pathname, navType]);

  return null;
}
