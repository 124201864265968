import classNames from 'classnames';
import produce from 'immer';
import { first, isEmpty } from 'lodash';
import { InputHTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { atomFamily } from 'recoil';
import { useStaticLocales } from '../../../../admin/hooks/locales/use-static-locale';
import { useToastMsgs } from '../../../../admin/hooks/use-toast-msgs';
import Modal from '../../../../components/Modal';
import { useOnNavChange } from '../../../../hooks/use-on-nav-change';
import { useData } from '../../../../report/use-data';
import { useQsVisibilitySync } from '../../../hooks/use-report';
import { useTranslations } from '../../../hooks/use-translations';
import { useTreeHelpers } from '../../../hooks/use-tree-helpers';
import { QuestionProps } from '../../../pa-prop-types';
import { Keys, QuestionAnswerMap, QuestionType, TreeState } from '../../../patient-types';
import Question from '../../../tree/Question';
import { useSxModalState } from '../../use-sx-modal-answers';

export const PaAnswerValueState = atomFamily<QuestionAnswerMap, string | null | undefined>({
  key: 'PaAnswerValueState',
  default: {},
});

/**
 * Whole body selector []
 *    Body select Q (q-where-hurt) >
 *       Body select tree (st-whole-body) >
 *          [whole body selector Q] (q-select-body-whole)
 *          [follow up Qs] (i.e. q-nrs)
 */
function ModalBodySelectTree(
  props: QuestionProps &
    InputHTMLAttributes<HTMLInputElement> & {
      onSave: (p: TreeState) => void;
      onClose: () => void;
      question: QuestionType;
    }
) {
  const { tOptions, treeState, onSave, onClose, question, locale } = props;
  const { sltStr } = useStaticLocales();

  const [, { t }] = useTranslations({ locale });
  const { showAnswerAll } = useToastMsgs();
  const [dirty, setDirty] = useState(false);


  const { answerKeys, questionKey } = question;
  const treeKey = first(answerKeys);
  const sxKey = treeKey;

  // eslint-disable-next-line
  // const [answerValue, setAnswerValue] = useRecoilState(PaAnswerValueState(bodyQsKey));

  const [
    { modalSxState },
    { updateAnswer, setModalSxState, updateAnswerValue, onUiMetadataChange },
  ] = useSxModalState({
    sxKey,
    initState: treeState,
  });

  // eslint-disable-next-line
  // console.log('modalSxState', modalSxState);

  const [, { isQuestionVisible }] = useTreeHelpers({ treeState: modalSxState });
  const [errorQsKeys, setErrorQsKeys] = useState<Keys>([]);
  const [{ isValid, qsKeysNotAnswered, qsErrorMap }] = useQsVisibilitySync({
    treeState: modalSxState,
    forSxModal: true,
  });

  const [, { getTreeByKey, getQuestionByKey }] = useData();
  const tree = treeKey ? getTreeByKey(treeKey) : null;
  const [selectedStack, setSelectedStack] = useState<Keys>([]);

  const bodySelQsKey = first(tree?.entryQuestionKeys);

  function handleClose() {
    onClose();
    setModalSxState(null);
  }

  const handleNavChange = useCallback(() => {
    if (!isEmpty(selectedStack)) {
      setSelectedStack((st) =>
        produce(st, (dr) => {
          dr.pop();
        })
      );
      return;
    }

    handleClose();
  }, [onClose, selectedStack, setSelectedStack]);

  useOnNavChange({
    block: true,
    onChange: handleNavChange,
  });

  const [startOpenBodySelKey, setStartOpenBodySelKey] = useState('');

  // OPEN FIRST BODY SELECT QUESTION WHEN NO PARTS SELECTED (INITIAL OPEN)
  useEffect(() => {
    // console.log('>>>> bodySelQsKey', bodySelQsKey);
    // console.log('startOpenBodySelKey', startOpenBodySelKey);

    if (startOpenBodySelKey) return;
    if (!bodySelQsKey) return;

    const bodyPartKeys = modalSxState?.answerMap[bodySelQsKey]?.answerKeys || [];
    // console.log('bodyPartKeys', bodyPartKeys);

    if (isEmpty(bodyPartKeys)) {
      // open
      setStartOpenBodySelKey(bodySelQsKey);
    }
  }, []);

  return (
    <>
      <Modal open className={classNames('p-0 overflow-hidden')} containerCls=''>
        <div className='t_ModalBodySelectTree  flex-1 flex-col overflow-hidden svg-path-disable'>
          <div className='flex-col flex-none overflow-hidden w-full'>
            <div className='flex-center-y gap-2 px-5 border-b flex-1 py-2'>
              <div className='flex-col flex-1'>
                <div className='text-base flex-center'>
                  {t(questionKey, tOptions, { fallbackValue: '' })}
                </div>
                <div className='text-sm flex-center opacity-60'>{sltStr({ key: 'label-best-guess' })}</div>
              </div>
              <div className='w-0 overflow-visible'>
                <button
                  className={classNames('p-2 inline')}
                  onClick={async () => {
                    if (modalSxState && !isEmpty(Object.keys(modalSxState))) {
                      // if there is some key data, save it instead of just closing
                      onSave(modalSxState);
                      setModalSxState(null);
                    }
                    else {
                      handleClose();
                    }
                  }}
                >
                  <MdClose size={20} className='opacity-60 -top-0 relative right-7' />
                </button>
              </div>
            </div>
          </div>

          <div className='t_ModalBodySelectTree flex-col overflow-hidden py-5'>
            <div className='flex-1 flex-col gap-8 overflow-auto px-5'>
              <div className='flex-1 flex-col gap-6'>
                {/* tree not found */}
                {!tree && 'Data not found'}

                {/* render body select tree */}
                {tree?.entryQuestionKeys.map((qKey) => {
                  const qs = getQuestionByKey(qKey);
                  if (!qs) return null;

                  const visible = isQuestionVisible({ question: qs, treeKey: tree.treeKey });
                  if (!visible) return null;

                  return (
                    <Question
                      locale={locale}
                      dirty={dirty}
                      level={1}
                      key={qKey}
                      error={errorQsKeys.includes(qKey)}
                      errorMsgs={qsErrorMap?.[question?.questionKey || '']}
                      treeKey={sxKey}
                      question={qs}
                      treeState={modalSxState}
                      updateAnswer={updateAnswer}
                      tOptions={{ symptom: t(sxKey) }}
                      onUiMetadataChange={onUiMetadataChange}
                      updateValue={updateAnswerValue}
                      startOpen={startOpenBodySelKey === qKey}
                      onCancel={() => {
                        handleClose();
                      }}
                    />
                  );
                })}
              </div>
              <div className='flex pb-16'>
                <button
                  className={classNames('btn btn-primary flex-1 btn-lg')}
                  onClick={() => {
                    setDirty(true);
                    if (!isValid) {
                      const firstKeyNotAnswered = first(qsKeysNotAnswered);
                      setErrorQsKeys(qsKeysNotAnswered);

                      if (firstKeyNotAnswered) {
                        const selectKey = `#${sxKey} #${firstKeyNotAnswered}`;
                        const el = first(document.querySelectorAll(selectKey));

                        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });

                        showAnswerAll();
                      }
                      return;
                    }

                    if (modalSxState == null) return;

                    onSave(modalSxState);
                    setModalSxState(null);
                  }}
                >
                  {sltStr({ key: 'btn-save', capit: true })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default memo(ModalBodySelectTree);
