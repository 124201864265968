import React, { useEffect } from 'react';

interface GlobalErrorHandlerProps {
  // eslint-disable-next-line
  logError: (error: any) => void;
}

const GlobalErrorHandler: React.FC<GlobalErrorHandlerProps> = ({ logError }) => {

  useEffect(() => {
    // eslint-disable-next-line
    const handleWindowError = (message: any, source?: string, lineno?: number, colno?: number, error?: Error) => {

      // NOTE: react throws these exceptions twice, which folks talk about online but I can't find a great way to work around
      // ignoring the problem since this is purely a debugging resource
      logError({ message, source, lineno, colno, error });
      return true;
    };

    const handleRejection = (event: PromiseRejectionEvent) => {
      logError({ message: event.reason.message, source: event.reason.stack });
    };

    window.onerror = handleWindowError;
    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      window.onerror = null;
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, [logError]);

  return null;
};

export default GlobalErrorHandler;
