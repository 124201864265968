import classNames from 'classnames';
import { first, upperCase } from 'lodash';
import { useEffect } from 'react';
import { BiGlobe } from 'react-icons/bi';
import useScrollToTop from '../common/hooks/use-scroll-to-top';
import { useData } from '../report/use-data';
import useAppState from './use-app-state';

import LogoPatient from '../components/LogoPatient';

function PaSurveyHeader() {
  useEffect(() => {
    const el = first(window.document.getElementsByTagName('html'));
    el?.setAttribute('data-theme', 'patientLight');
  }, []);

  const [
    {
      dataState: { survey },
    },
  ] = useData({ initData: true });

  useScrollToTop();

  const [{ localeInfo }, { openLocaleModal }] = useAppState();

  return (
    <>
      <div className='navbar z-20 gap-2 p-0'>
        <div className='flex-1 font-bold text-2xl'>
          <div className='h-[2rem]'>
            <LogoPatient className='w-auto' />
          </div>
        </div>
        <div className='flex'>
          <div
            className={classNames(
              'btn bg-transparent border-black border-1_5 border-opacity-10 p-2 min-h-0 h-auto',
              survey?.id == null && 'hidden'
            )}
            onClick={() => {
              openLocaleModal();
            }}
          >
            <BiGlobe size={'1.2rem'} />
            <span className='text-xs'>{upperCase(localeInfo?.label)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaSurveyHeader;
