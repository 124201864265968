import classNames from 'classnames';
import { isEmpty } from 'lodash';
import useAuth from '../../auth/use-auth';
import { useAdminParams } from '../../survey/use-admin-params';
import useAdminAppState from '../use-admin-app-state';

function ProjectSelector({
  mobile,
  onChange,
  basePath,
}: {
  basePath?: string;
  onChange?: () => void;
  mobile?: boolean;
}) {
  const [{ profiles }, { setSelectedProfile }] = useAuth();
  const { orgIdParam } = useAdminParams();

  const profileSelected = profiles?.find((prof) => prof.organization?.id === orgIdParam);

  const [
    {
      adminAppStateCookie: { openSide: openSideDesktop },
    },
  ] = useAdminAppState();

  // always set open for mobile view
  const openSide = mobile === true || openSideDesktop;

  return (
    <div className={classNames('flex gap-2', !openSide && 'hidden')}>
      {!isEmpty(profiles) && (
        <div>
          <select
            className='select select-sm font-normal hover:font-bold border-none pl-0'
            onChange={(e) => {
              if (onChange) onChange();

              setSelectedProfile(e.target.value, { basePath });
            }}
            value={profileSelected?.id || ''}
          >
            {profiles?.map((profile) => {
              return (
                <option className='opacity-60' key={profile.id} value={profile?.id || ''}>
                  {profile.organization?.name}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </div>
  );
}

export default ProjectSelector;
