import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const StaffState = atom<{ searchKey: string | undefined }>({
  key: 'StaffState',
  default: { searchKey: '' },
  effects_UNSTABLE: [persistAtom],
});

export default function useStaffState() {
  const [staffState, setStaffState] = useRecoilState(StaffState);

  return [
    {
      staffState,
    },
    {
      setStaffState,
    },
  ] as const;
}
