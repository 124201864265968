import { isEmpty, trim } from 'lodash';
import { notNull } from './array';
export function filterQuestions(questions) {
    return questions.filter((q) => {
        return !!q['question-key'] && !q['exclude'];
    });
}
export function convertColumnValueToKeys(valueStr = '') {
    return valueStr
        .split(',')
        .map(trim)
        .filter((v) => notNull(v) && !isEmpty(v));
}
export function parseJsStrToArr(jsStr) {
    if (isEmpty(jsStr) || jsStr == null) {
        return [];
    }
    try {
        return eval(jsStr) || [];
    }
    catch (error) {
        // ignore for now
        // console.log('error', error);
        return [];
    }
}
export function parseShowCondition(item) {
    return parseJsStrToArr(item['show-conditions']);
}
export function parseOptionVizConditions(item) {
    return parseJsStrToArr(item['optionVizConditions']);
}
export function mapItemToLocale(item) {
    return {
        locale: 'english',
        key: item['question-key'],
        value: item['locale-en'],
    };
}
export function mapItemToDescLocale(item) {
    return {
        locale: 'english',
        key: item['question-key'] + '-desc',
        value: item['desc'],
    };
}
export function convertItemToQuestion(item) {
    // if (!item.id) return null;
    let answerKeys;
    if (!item['option-keys'] && item['options-desc']) {
        answerKeys = convertStringToArray(item['options-desc']);
    }
    else {
        answerKeys = convertColumnValueToKeys(item['option-keys']);
    }
    let extNoteRequiredKeys;
    if (item['ext-note-required-keys']) {
        extNoteRequiredKeys = convertStringToArray(item['ext-note-required-keys']);
    }
    return {
        ...item,
        questionKey: item['question-key'] || '',
        // NOTE better not change things form dash for (sx-select)
        // type: replace(`${item.type}`, '-', '_'),
        desc: item['desc'] ? item['question-key'] + '-desc' : undefined,
        ...(item.multiple
            ? {}
            : {
                minSelectedOptions: 1,
                maxSelectedOptions: 1,
            }),
        showConditions: parseShowCondition(item),
        optionConditions: parseOptionVizConditions(item),
        answerKeys,
        required: !!item.required,
        exampleKey: item['example-key'],
        extNoteRequiredKeys,
        reportLabel: item['report-label'],
        reportLabelType: item['report-label-type'],
        type: item['type']?.replaceAll('-', '_'),
    };
}
export function convertStringToArray(optionDesc) {
    if (!optionDesc)
        return [];
    const options = optionDesc
        .split(',')
        .map(trim)
        .filter((v) => notNull(v) && !isEmpty(v));
    return options;
}
