import { Enum_Translation_Locale, QuestionTree, QuestionTreeFragment } from '@cyren/common-lib';
import {
  TOrg,
  TPatientVisit,
  TQuestion,
  TSurvey,
  TTransApi,
  TTranslation,
} from '../graphql-enhanced';
import { HelperFuncKeyT } from '../report/helper-functions';
import { ReportConfigStateT } from '../survey/template/template-types';

export type NavParamPaSurvey = {
  shortId?: string;
  orgId?: string;
  treeKey?: string;
  adhocId?: string;
  referralMessageId?: string;
};
export type Map = Record<string, string | number | null | undefined | Keys>;

export type UIMetadataMap = Record<string, string>;
export type UIMetadataT = UIMetadataMap | BodySelectMap;

export type AnswerValueT = {
  answerKeys?: Keys;
  answerValues?: Values;
  notesForKeys?: Record<Key, string | undefined | null>;
  uiMetadata?: UIMetadataT;
  answerMaps?: QuestionAnswerMap[];
  questionKey?: Key;
};

export type AnswerValue = AnswerValueT | null | undefined;
export type ValueMap = Record<string, AnswerValue>;
export type SystemValueMap = Record<Key, string | number | undefined | null>;
export type Key = string;
export type FlowKey = Key;
export type TreeKey = Key;
export type Keys = Key[];
export type Value = string;
export type Values = Value[];
export type EntryQuestionKeys = Keys;
export type QuestionKey = Key;
export type Option = { key: string; label: string };
export type Options = Option[];

export type QuestionAnswerMap = Record<QuestionKey, AnswerValue>;
export type TreeState = {
  treeKey: Key;
  answerMap: QuestionAnswerMap;
  tree: QuestionTreeType | null;
};

export type ModalSxStateType = TreeState;
export type PopupSxStateType = {
  sxKey?: Key;
  answerMap: QuestionAnswerMap;
};

export type TranslationType = {
  id?: Key | null;
  key: Key;
  value?: Value | null;
  locale?: Enum_Translation_Locale | null | `${Enum_Translation_Locale}`;
};

export type UnhandledErrorType = {
  createdOn: string;
  message: string;
  source?: string
  lineInfo?: string;
};

export type SurveyType = TSurvey;
export type AnswerPath = Key;

export type ReportErrorStateType = {
  maxErrorsReached: boolean;
  unhandledErrors: UnhandledErrorType[];
}

export type IntelligentSymptomResultsType = {
  source: "medlm" | "default";
  cc: string;
  symptomKeys: string[];
}

export type ReportStateType = {
  locale?: string;
  acceptedTerms?: boolean;
  isDemo?: boolean;
  globalAnswerMap: QuestionAnswerMap;
  systemValueMap?: SystemValueMap;
  treeStates: TreeState[];
  nextTreeKeys: Keys;
  presentIllness: Keys;
  ccKey?: Key;
  errorState?: ReportErrorStateType;
  intelligentSymptoms?: IntelligentSymptomResultsType;
};

export type AdhocQuestionType = {
  type: string;
  questionText: string;
  translatedQuestionText?: string;
  answer?: string;
  uniqueId: string;
}

export type AdhocCommentType = {
  visitId: string;
  template: string;
  userId: string;
  userName: string;
  commentText: string;
  uniqueId: string;
  timestamp: string;
  editedTimestamp: string;
  deleted: boolean;
}

export type PaSurveyStateType = {
  survey?: SurveyType;
  adaptedTreeKeys: SurveyType['questionTreeKeys'];
  curTreeKey?: string | null;
  completedStages?: string[];
  activeStage?: string | null;
  unreachedStages?: string[];
};

// enhanced GQL types
export type QuestionTreeType = Omit<QuestionTree, 'entryQuestionKeys'> & {
  id?: QuestionTreeFragment['id'];
  step?: number;
  entryQuestionKeys: EntryQuestionKeys;
};

export type QuestionTreeTemplateType = {
  id?: QuestionTreeFragment['id'];
  treeKey: Key;
  template: ReportConfigStateT;
};

export const conditionCategories = ['helper-function', 'value-matching', 'age-range'] as const;
export type ConditionCategory = typeof conditionCategories[number];

export function consoliCondition(condition: ConditionBase) {
  const { category: oCat, type: oType, func } = condition;
  const type = oType || 'include';
  let category: ConditionCategory = oCat || 'value-matching';

  if (func) {
    category = 'helper-function';
  }

  const condToRender = {
    ...condition,
    category,
    type,
  };

  return condToRender as ConditionBase;
}

export interface ConditionBase {
  category?: ConditionCategory;
  type?: 'exclude' | 'include';
  keys?: Keys;
  operator?: 'ne' | 'eq' | 'gt' | 'gte' | 'lt' | 'lte';
  value?: string | null;
  targetTreeKey?: Key;
  targetTreeKeys?: Key[];
  qsKey?: Key;
  func?: HelperFuncKeyT;
  ageUnit?: 'years' | 'months';
  ageMin?: number | null;
  ageMax?: number | null;
  uiMetadata?: UIMetadataMap;
  logicType?: 'AND' | 'OR';
}

export interface OptionConditionByFunc extends ConditionBase {
  func: HelperFuncKeyT;
}

export interface ConditionByFunc extends ConditionBase {
  func: HelperFuncKeyT;
}

export interface ConditionByInclude extends ConditionBase {
  qsKey: Key;
}

export function isIncludeCondition(condition: ConditionBase): condition is ConditionByInclude {
  return condition.type === 'include';
}

export type ConditionType =
  | ConditionBase
  | ConditionByFunc
  | ConditionByInclude
  | OptionConditionByFunc;

export type QuestionType = TQuestion;
export type QsErrorType = 'required' | 'age restriction' | 'inline error' | 'invalid phone' | 'unverified phone';
export type QsErrorMap = Record<Key, QsErrorType[] | null>;

/**
 * utility function for figuring out follow trees
 */
export type ConditionFunc<T> = (p: { question: QuestionType; treeState: TreeState }) => T;
export type KeyHelper = ConditionFunc<Keys>;
export type ConditionHelper<T> = ConditionFunc<T>;
export type OptionConditionHelper<T> = OptionConditionFunc<T>;
export type OptionConditionFunc<T> = (p: {
  question: QuestionType;
  reportState?: ReportStateType;
}) => T;

export type tFunc = (key?: string) => string | undefined | null;

/**
 * question type options
 */
export type QsOptions = {
  min?: string;
  max?: string;
  step?: string;
  multi?: boolean;
  ageRestriction?: number | null;
  intelligentOnly?: boolean | null;
  suppressSxCollectors?: boolean | null;
  verifyPhoneNumber?: 'required' | 'optional' | 'disabled';
};

export type QsAnswerOptionsMap = Record<
  Key,
  {
    mutuallyExclusive?: boolean;
    requireNote?: boolean;
    mainOption?: boolean;
    omitInSearch?: boolean;
  }
>;

export type PaDataRepoStateT = {
  visit?: TPatientVisit | null;
  trees: QuestionTreeType[];
  questions: QuestionType[];
  translations: TTranslation[];
  doctorsTerms?: TTranslation[];
  userNoteTrans: TTransApi[];
  survey?: SurveyType | null;
  org?: TOrg | null;
  init?: boolean;
  stepsLoaded?: boolean;
  errors?: string[];
};

export type BodySelectMap = {
  [k: string]: string[];
};
