import { usePublicRedirectQuery } from '@cyren/common-lib';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router';
import envs from '../common/envs';
import LoadingAnim from '../components/LoadingAnim';

function QrRedirect() {
  const { uniqId } = useParams<{ uniqId?: string }>();

  // TODO create designated api to get qr url for uniq code
  const { data, loading } = usePublicRedirectQuery({
    skip: !uniqId,
    variables: {
      uniqId: uniqId || '',
    },
  });

  useEffect(() => {
    if (data?.publicRedirect) {
      const link = `${envs.surveyBase}/s${data?.publicRedirect}`;

      window.location.href = link;
    }
  }, [data?.publicRedirect]);

  if (loading) {
    // if (!loading) {
    return (
      <div className='h-full flex-1 flex-center overflow-hidden'>
        <LoadingAnim />
      </div>
    );
  }

  return <div className='flex-col gap-4 w-full h-screen flex-center'>Redirecting ...</div>;
}

export default memo(QrRedirect);
