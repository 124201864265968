import { size } from 'lodash';
import { memo } from 'react';
import { QuestionProps } from '../../pa-prop-types';
import { useQuestion } from '../../tree/use-question';
import QsInputSelectSheet from './QsInputSelectSheet';
import QsInputSelector from './QsInputSelector';
import QsInputSelectorV2 from './QsInputSelectorV2';

function QsInputSelect(props: QuestionProps) {
  const { question } = props;
  const { answerKeys } = question || {};
  const lengthyKeys = size(answerKeys) > 4;

  const { isSelectTypeV2, isSelectSheet } = useQuestion(question);

  if (isSelectTypeV2) {
    return <QsInputSelector {...props} />;
  }

  if (isSelectSheet && lengthyKeys) {
    return <QsInputSelectSheet {...props} />;
  }

  return <QsInputSelectorV2 {...props} />;
}

export default memo(QsInputSelect);
