import { memo, useState, SyntheticEvent } from 'react';

import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Box,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';
import { AdhocCommentType } from '../../../patient/patient-types';




function ModalAddComment(props: {
  onClose: () => void;
  onSave: (comment: string) => void;
  onEdit: (updatedComment: AdhocCommentType) => void;
  existingComment: AdhocCommentType | null;
}) {
  const { onClose, onSave, onEdit, existingComment } = props;

  const handleClose = (event: SyntheticEvent, reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const [comment, setComment] = useState<string>(existingComment?.commentText || "");

  return (
    <Dialog open
      onClose={handleClose}
      onClick={(event: React.MouseEvent) => { event.stopPropagation() }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {existingComment ? "Update Comment" : "Add a Comment"}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <Box sx={{ px: 1, py: 2 }}>
            <TextField
              label="Your Comment"
              variant="outlined"
              multiline
              minRows={1}
              maxRows={4}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              fullWidth
            />
          </Box>
        </div>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="primary"
            onClick={() => {
              if (existingComment) {
                onEdit({
                  ...existingComment,
                  commentText: comment
                });
              }
              else {
                onSave(comment);
              }
            }}
            disabled={isEmpty(comment) || comment === existingComment?.commentText}
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog >
  );
}

export default memo(ModalAddComment);
