import { notNull } from '@cyren/common-lib';
import { isEmpty, trim, values } from 'lodash';
import { TQuestion } from '../../graphql-enhanced';
import { AnswerValue, QuestionAnswerMap, TreeState } from '../../patient/patient-types';

export function getTextAnswer(answer: AnswerValue) {
  const { answerValues, answerKeys } = answer || {};
  return (
    values(answerValues).find((v) => !isEmpty(trim(v))) ||
    answerKeys?.find((v) => !isEmpty(trim(v)))
  );
}

export function getAnswerMapHasAnswer(answerMap: QuestionAnswerMap): boolean {
  const answerMapsHasAnswer =
    !!values(answerMap)?.find((mapValue) => {
      return getHasAnswer(mapValue);
    }) || false;

  return !!answerMapsHasAnswer;
}

export function getHasAnswer(answer: AnswerValue): boolean {
  const { answerKeys, answerValues, answerMaps } = answer || {};
  const mapHasAnswer = !!answerMaps?.find(getAnswerMapHasAnswer) as boolean;

  const hasAnswer =
    !isEmpty(answerKeys) || !isEmpty(answerValues?.map(trim).filter(Boolean)) || mapHasAnswer;

  return hasAnswer;
}

export function isTreeStateAnswered(treeState?: TreeState): boolean {
  const answeredQuestion = treeState?.tree?.entryQuestionKeys.some((qsKey) => {
    const answer = treeState.answerMap[qsKey];
    return getHasAnswer(answer);
  });
  
  return !!answeredQuestion;
}

export function isQsAnswered({
  question,
  treeAsMap,
}: {
  question?: TQuestion;
  treeAsMap: QuestionAnswerMap;
}) {
  const { answerOptionsMap, questionKey } = question || {};

  if (!question || !questionKey) return true;

  const qsAsMap = treeAsMap[questionKey];

  const answered = getHasAnswer(qsAsMap);
  // check if optionKey is requiring a text input

  const answerKeys = qsAsMap?.answerKeys || [];
  const selectedExtNoteRequireAnswerKeys = answerKeys
    .map((ak) => {
      return answerOptionsMap?.[ak]?.requireNote ? ak : null;
    })
    .filter(notNull);

  const answerKeyMissingNote = selectedExtNoteRequireAnswerKeys?.find((optionKey) => {
    const optionNote = qsAsMap?.notesForKeys?.[optionKey];
    return optionNote == null || isEmpty(trim(optionNote));
  });

  if (!isEmpty(answerKeyMissingNote)) {
    return false;
  }

  return answered;
}
