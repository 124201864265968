import { memo } from 'react';
import { useRecoilState } from 'recoil';
import { PaDataRepoState } from '../../report/use-data';
import { usePersistSurveyState } from '../../survey/template/use-pers-survey-state';

function PaLocalStorageReset() {
  const [{ survey }] = useRecoilState(PaDataRepoState);

  const [, { resetPersState }] = usePersistSurveyState({
    surveyId: survey?.id,
  });

  return (
    <div className='flex-col p-5'>
      <div>Oops!</div>
      <div>
        <button
          className='btn btn-primary'
          onClick={() => {
            resetPersState();

            window.location.reload();
          }}
        >
          Click here to fix it
        </button>
      </div>
    </div>
  );
}

export default memo(PaLocalStorageReset);
