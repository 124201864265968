import classNames from 'classnames';
import { InputHTMLAttributes, memo } from 'react';

function QsTextInput(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
    onChange: (value: [string, string]) => void;
    value: [string | undefined, string | undefined];
  }
) {
  const { value, onChange, className } = props;

  const [v1, v2] = value;

  // useEffect(() => {
  //   onChange([v1 || '1', v2 || 'day']);
  // }, []);

  return (
    <div className='t_QsFrequencyInput flex gap-3 flex-center-y'>
      <div className='flex-1'>
        <select
          className={classNames('select select-bordered w-full max-w-xs', className)}
          value={value[0]}
          onChange={(e) => {
            onChange([e.target.value, v2 || '']);
          }}
        >
          <option value={''}>--</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </select>
      </div>
      <div>per</div>
      <div className='flex-1'>
        <select
          className={classNames('select select-bordered w-full max-w-xs', className)}
          value={value[1]}
          onChange={(e) => {
            onChange([v1 || '', e.target.value]);
          }}
        >
          <option value={''}>--</option>
          <option value={'day'}>day</option>
          <option value={'week'}>week</option>
          <option value={'month'}>month</option>
        </select>
      </div>
    </div>
  );
}

export default memo(QsTextInput);
