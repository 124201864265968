import { TranslationType } from './types';

export const translations: TranslationType[] = [
  {
    key: 'yes',
    value: 'Yes',
    locale: 'english',
  },
  {
    key: 'no',
    value: 'No',
    locale: 'english',
  },
  {
    key: 'not-sure',
    value: 'Not sure',
    locale: 'english',
  },
  {
    key: 'used-to',
    value: 'Used to',
    locale: 'english',
  },
  {
    key: 'do-not-know',
    value: 'I do not know',
    locale: 'english',
  },
  {
    key: 'q-patient-name',
    value: 'What is your name?',
    locale: 'english',
  },
  {
    key: 'q-patient-name',
    value: '¿Cómo te llamas?',
    locale: 'spanish',
  },
  {
    key: 'q-patient-name',
    value: '당신의 이름은 무엇입니까? (영문 입력)',
    locale: 'korean',
  },
  {
    key: 'q-select-pain-part',
    value: 'Where does it bother you the most?',
    locale: 'english',
  },
  {
    key: 'q-cc-and-pis',
    value: 'Where does it bother you the most?',
    locale: 'english',
  },
  {
    key: 'q-have-more-sx',
    value: 'Do you have more symptoms?',
    locale: 'english',
  },
  {
    key: 'male',
    value: 'Male',
    locale: 'english',
  },
  {
    key: 'female',
    value: 'Female',
    locale: 'english',
  },
  {
    key: 'other',
    value: 'Other',
    locale: 'english',
  },
  {
    key: 'etc',
    value: 'Etc',
    locale: 'english',
  },
  {
    key: 'loc-neck',
    value: 'Neck',
    locale: 'english',
  },
  {
    key: 'loc-head',
    value: 'Head',
    locale: 'english',
  },
  {
    key: 'loc-abdo',
    value: 'Abdominal',
    locale: 'english',
  },
];
