import { uniq } from 'lodash';
import { cyrenLocales, cyrenRtlLocales } from './consts/langs-cyren';
export function guessBrowserLocale() {
    const code = navigator.language.substring(0, 2);
    return convCodeToLocale(code) || 'english';
}
export function getBrowserLocales() {
    const code = uniq(navigator.languages.map((i) => i.substring(0, 2))) || [];
    return code.map(convCodeToLocale);
}
export function convCodeToLocale(loc) {
    switch (loc) {
        case 'es':
        case 'sp':
            return 'spanish';
        case 'ko':
        case 'kor':
            return 'korean';
        case 'en':
            return 'english';
        default:
            return 'english';
    }
}
export function convLocaleToCode(loc) {
    switch (loc) {
        case 'korean':
            return 'ko';
        case 'english':
            return 'en';
        case 'spanish':
            return 'es';
    }
    return '';
}
export const locales = cyrenLocales;
export const rtlLocales = cyrenRtlLocales;
