import { createTheme } from '@mui/material/styles';


const themeCommon = {
  typography: {
    fontFamily: [
      "Noto Sans",
      "sans-serif"
    ].join(','),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.75rem',
        },
      },
    },
  },
  shape: {
    borderRadius: 10, // Change this value to make the buttons more rounded
  },
};



export const providerTheme = createTheme({
  ...themeCommon,
  palette: {
    primary: {
      main: '#8255FF',
    },
    secondary: {
      main: '#F9C610'
    },
    error: {
      main: '#FF5E71',
      contrastText: '#FFFFFF'
    }

  },
});

export const patientTheme = createTheme({
  ...themeCommon,
  palette: {
    primary: {
      main: '#3455FC',
    },
    secondary: {
      main: '#F9C610'
    },
    error: {
      main: '#FF5E71',
      contrastText: '#FFFFFF'
    }

  },
});
