import { QuestionProps, UpdateAnswerHandler } from '../../pa-prop-types';

import { isNotEmpty } from '@cyren/common-lib';
import produce from 'immer';
import { first, isEmpty, values } from 'lodash';
import { InputHTMLAttributes, memo, useMemo, useState } from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { TbCirclePlus } from 'react-icons/tb';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import LoadingAnim from '../../../components/LoadingAnim';
import { useData } from '../../../report/use-data';
import { usePatientTransData } from '../../../report/use-patient-trans-data';
import { usePatientTreeData } from '../../../report/use-patient-tree-data';
import { useTranslations } from '../../hooks/use-translations';
import { Keys, QuestionType } from '../../patient-types';
import { useQuestion } from '../../tree/use-question';
import ModalBodySelectTree from './body-select/ModalBodySelectTree';
import './body-select/body-select.scss';

/**
 * enum.BodySelect isBodySelect, body_select enum type
 */
function QsInputBodySelectTree(
  props: QuestionProps &
    InputHTMLAttributes<HTMLInputElement> & {
      question: QuestionType;
      updateAnswer?: UpdateAnswerHandler;
    }
) {
  const { updateAnswer, answerValue, locale, question } = props;
  const { questionKey, answerKeys } = question;
  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();

  // eslint-disable-next-line
  // console.log('answerValue', answerValue);

  const answerMaps = answerValue?.answerMaps || [];

  const bodyPartKeysInitiallySelected = answerMaps.reduce((p, answerMap) => {
    const qKeys = values(answerMap).reduce((p2, c2) => {
      if (c2?.answerKeys && isNotEmpty(c2?.answerKeys)) {
        return [...p2, ...c2.answerKeys];
      }
      return p2;
    }, [] as Keys);

    if (isNotEmpty(qKeys)) {
      return [...p, ...qKeys] as Keys;
    }

    return p;
  }, [] as Keys);

  const [{ loadingTrans: loadingInitialAnswers }] = usePatientTransData({
    keys: bodyPartKeysInitiallySelected,
  });

  // -1 = IDX: NEW STATE
  // -1 < IDX: EXISTING STATE
  const [modalTreeStateIdx, setModalTreeStateIdx] = useState<number | null>(null);
  const { isBodySelect } = useQuestion(question);

  const treeKey = first(answerKeys);

  const [
    {
      // eslint-disable-next-line
      survey,
      loadingTrans,
      loadingQss,
      // dataState: { surveyReady },
    },
  ] = usePatientTreeData({
    treeKey,
    autoRefetch: true,
  });

  const isTreeView = isBodySelect;
  // const isQsSelectView = !isBodySelect;

  // tree specific
  const [, { getTreeByKey }] = useData();
  const tree = treeKey ? getTreeByKey(treeKey) : null;
  const firstBodySelectQsKey = first(tree?.entryQuestionKeys);

  const loadingData =
    isTreeView && (loadingInitialAnswers || loadingTrans || loadingQss || !tree?.treeKey);

  const modalTreeState = useMemo(() => {
    if (modalTreeStateIdx == null) return null;

    const modalAnswerMap = answerMaps[modalTreeStateIdx];

    return {
      tree,
      treeKey: tree?.treeKey || '',
      answerMap: modalAnswerMap || {},
    };
  }, [answerMaps, modalTreeStateIdx]);

  return (
    <>
      {modalTreeStateIdx != null && (
        <ModalBodySelectTree
          {...props}
          onClose={() => {
            setModalTreeStateIdx(null);
          }}
          treeState={modalTreeState}
          onSave={(nTreeState) => {
            // eslint-disable-next-line
            // console.log('treeState', nTreeState);

            if (!nTreeState.answerMap) return;

            if (modalTreeStateIdx > -1) {
              updateAnswer?.({
                questionKey,
                answerMaps: produce(answerMaps, (dr) => {
                  if (dr?.[modalTreeStateIdx]) {
                    dr[modalTreeStateIdx] = nTreeState.answerMap;
                  }
                }),
              });
            } else {
              updateAnswer?.({
                questionKey,
                answerMaps: [...answerMaps, nTreeState.answerMap],
              });
            }

            setModalTreeStateIdx(null);
          }}
        />
      )}

      {loadingData ? (
        <div className='flex-1 flex-center py-12'>
          <LoadingAnim className='h-auto' />
        </div>
      ) : (
        <div className='t_QsInputBodySelectTree flex-col gap-3'>
          {/* MAIN BODY SELECT QUESTION */}
          <div className='flex-col gap-3'>
            {answerMaps?.map((anMap, idx) => {
              const bodySelMap = firstBodySelectQsKey ? anMap[firstBodySelectQsKey] : {};

              const selectedKeys = bodySelMap?.answerKeys || [];
              if (isEmpty(selectedKeys)) return null;

              const partsStr = selectedKeys.map((k) => t(k)).join(', ');

              return (
                <div
                  key={idx}
                  className='p-1 border rounded-xl bg-primary bg-opacity-10 flex gap-2'
                >
                  <span className='flex-1 p-3'> {partsStr}</span>
                  <span className='flex-none flex gap-0 px-2'>
                    <button
                      className='btn btn-ghost px-2'
                      onClick={() => {
                        if (tree?.treeKey == null) return;

                        setModalTreeStateIdx(idx);
                      }}
                    >
                      <BiPencil size={20} />
                    </button>
                    <button
                      className='btn btn-ghost px-2'
                      onClick={() => {
                        updateAnswer?.({
                          questionKey,
                          answerMaps: answerMaps.filter((i, idx2) => idx !== idx2),
                        });
                      }}
                    >
                      <BiTrash size={20} />
                    </button>
                  </span>
                </div>
              );
            })}
          </div>

          <div className='flex-col'>
            <button
              className='btn btn-outline btn-ghost btn-lg'
              onClick={() => {
                if (tree?.treeKey == null) return;

                setModalTreeStateIdx(-1);
              }}
            >
              <TbCirclePlus size={23} /> {sltStr({ key: 'btn-bodypart', upper: true })}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(QsInputBodySelectTree);
