import { RefObject, useEffect } from 'react';

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (ref: RefObject<HTMLTextAreaElement> | null, value: string) => {
  function rerender() {
    const el = ref?.current;
    if (el) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      el.style.height = '0px';
      // eslint-disable-next-line
      const scrollHeight = el.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a el will product an incorrect value.
      // eslint-disable-next-line
      const adjustment = 3;
      const finalH = scrollHeight + adjustment;
      el.style.height = `${finalH}px`;
    }
  }

  useEffect(() => {
    rerender();
  }, [ref, value]);

  return { rerender };
};

export default useAutosizeTextArea;
