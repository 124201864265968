import { notEmpty, useSurveyMetasQuery } from '@cyren/common-lib';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { SurveyMetaQna } from '../graphql-enhanced';
import { Keys } from '../report/types';

export function useSurveyMeta({
  metaKey,
  surveyId,
}: {
  metaKey: string;
  surveyId?: string | null;
}) {
  const { data, loading } = useSurveyMetasQuery({
    skip: !surveyId,
    variables: {
      filters: {
        survey: {
          id: {
            eq: surveyId,
          },
        },
        metaKey: {
          eq: metaKey,
        },
      },
    },
  });

  // INIT FORM DATA
  const meta = data?.surveyMetas?.data?.[0];

  const metaItems: SurveyMetaQna['items'] = meta?.attributes?.metadata?.items || [];

  const transKeys = useMemo(() => {
    const tKeys = metaItems.reduce((p, item) => {
      return [...p, item.qnaQuestionKey, item.qnaAnswerKey];
    }, [] as Keys);

    return uniq(tKeys.filter(notEmpty));
  }, [metaItems]);

  return { meta, data, loading, transKeys, metaItems } as const;
}
