import { memo, useEffect, useState } from "react";
import { useReportState } from '../../hooks/use-report';
import { QuestionProps } from '../../pa-prop-types';
import { useTranslations } from '../../hooks/use-translations';
import QsSelectedSymptom from "./components/QsSelectedSymptom";
import QsCollapsibleGroupItem from "./QsCollapsibleGroupItem";
import { difference, includes, isEmpty, size, union } from "lodash";
import LoadingAnim from "../../../components/LoadingAnim";
import { usePatientTreeData } from "../../../report/use-patient-tree-data";
import produce from "immer";
import classNames from "classnames";
import { noneAboveKey } from "../../../report/report-config";
import { useTreeHelpers } from "../../hooks/use-tree-helpers";
import { useStaticLocales } from "../../../admin/hooks/locales/use-static-locale";
import { useData } from "../../../report/use-data";
import { Enum_Question_Type, useSuggestSymptomsMutation } from "@cyren/common-lib";
import { useQuestionHelpers } from "../../../report/use-question-helpers";
import { onError } from '../../../utils/apollo-utils';
import { IntelligentSymptomResultsType } from "../../patient-types";
import { usePatientTransData } from "../../../report/use-patient-trans-data";


function QsInputSxIntelligentCollector(props: QuestionProps) {

  const { locale, question, treeKey, onUiMetadataChange, mode } = props;
  const { sltStr } = useStaticLocales();
  const [refineQuestion] = useQuestionHelpers();

  const [{ dataState }, { getQuestionByKey }] = useData();

  const [{ loadingTrans }, { fetchTrans }] = usePatientTransData({
    keys: []
  });

  const [suggestSymptoms, { loading: loadingSuggestions }] = useSuggestSymptomsMutation({
    onError,
  });

  const [{ treeState, selectedSxTrees, reportState }, { setReportState, updateAnswer }] =
    useReportState({
      treeKey,
    });

  const uiMetadata = treeState?.answerMap[question?.questionKey || ""]?.uiMetadata;

  const [, { getAssociatedSymptomKeys }] = useTreeHelpers({});

  const [, { t }] = useTranslations({ locale });

  const [
    {
      loadingTrees,
    },
    {
      fetchQss,
      fetchTrees
    }
  ] = usePatientTreeData();

  const suppressSxCollectors = !!question?.typeOptions?.suppressSxCollectors;

  const [loadingIntelligentSymptoms, setLoadingIntelligentSymptoms] = useState(false);


  async function recordIntelligentSymptoms(intelligentSymptoms: IntelligentSymptomResultsType) {
    setReportState((st) => {
      return produce(st, (draft) => {
        draft.intelligentSymptoms = intelligentSymptoms;
      })
    });

    await fetchTrans({ asNeededKeys: intelligentSymptoms.symptomKeys });
    setLoadingIntelligentSymptoms(false);
    fetchTrees({ treeKeyList: intelligentSymptoms.symptomKeys });
  }


  function generateDefaultIntelligentSymptoms() {
    // go with the default set of intelligent symptoms
    const defaultIntelligentSymptomKeys = question?.answerKeys?.filter(qsKey => {
      if (qsKey === noneAboveKey) {
        return false;
      }
      if (getQuestionByKey(qsKey)?.type === Enum_Question_Type.SxCollect) {
        return false;
      }
      if (qsKey.startsWith("q-")) {
        // purely for display purposes, hide anything that doesn't look like a symptom
        return false;
      }
      return true;
    }) || [];

    const intelligentSymptoms: IntelligentSymptomResultsType = {
      source: "default",
      cc: (reportState.systemValueMap?.cc || "") as string,
      symptomKeys: defaultIntelligentSymptomKeys
    }

    return intelligentSymptoms;
  }

  useEffect(() => {

    const loadDefaultDisplaySymptoms = async () => {
      await fetchQss({ qsKeys: question?.answerKeys || [] });
      recordIntelligentSymptoms(generateDefaultIntelligentSymptoms());
    }

    const loadPotentialSymptoms = async () => {
      // look through the answers and find the list of symptoms
      const answerQuestions = await fetchQss({ qsKeys: question?.answerKeys || [] });

      // find the SxCollect question from the answers, and then refine it to remove any answer options that don't apply to this user
      const [{ question: symptomListQs }] = refineQuestion({ question: answerQuestions?.find(qs => qs.type === Enum_Question_Type.SxCollect) });

      if (symptomListQs) {
        // okay we found the list of symptoms we are going to use.  
        // the main symptom list will be its own list of questions... fetch them all
        const symptomQuestions = await fetchQss({ qsKeys: symptomListQs.answerKeys || [] });

        // we could operate directly on the symptom questions, but to make debugging easier, process
        // them in order of the original answer list order
        const symptomSets = symptomListQs.answerKeys?.reduce((prev, qsKey) => {
          const matchingQuestion = symptomQuestions.find(qs => qs.questionKey === qsKey);

          if (matchingQuestion?.type === Enum_Question_Type.SxSelect) {
            return {
              ...prev,
              branchSymptoms: [...prev.branchSymptoms, qsKey]
            }
          }

          return {
            ...prev,
            leafSymptoms: [...prev.leafSymptoms, qsKey]
          }

        }, { leafSymptoms: [], branchSymptoms: [] } as { leafSymptoms: string[], branchSymptoms: string[] });

        // fetch all the branching question trees
        const branchAnswerQuestions = await fetchQss({ qsKeys: symptomSets?.branchSymptoms });

        const refinedBranchAnswerQuestions = branchAnswerQuestions.map(qs => {
          const [{ question: refinedQs }] = refineQuestion({ question: qs });
          return refinedQs;
        }).filter(i => i !== undefined);

        const newLeafSymptoms = symptomSets?.branchSymptoms.reduce((prev, qsKey) => {
          const matchingQuestion = refinedBranchAnswerQuestions.find(refQs => refQs?.questionKey === qsKey);
          return [...prev, ...(matchingQuestion?.answerKeys || [])];
        }, [] as string[]);

        const leafSymptoms = union(newLeafSymptoms, symptomSets?.leafSymptoms);

        const intelligentResult = await suggestSymptoms({
          variables: {
            uuid: dataState?.visit?.uuid || "",
            possibleSymptoms: leafSymptoms
          }
        });

        const intelligentSymptomResults = intelligentResult?.data?.suggestSymptoms?.symptomKeys;

        if (!isEmpty(intelligentSymptomResults)) {
          const intelligentSymptoms = {
            source: intelligentResult?.data?.suggestSymptoms?.source || "medlm",
            cc: (reportState.systemValueMap?.cc || "") as string,
            symptomKeys: intelligentSymptomResults as string[]
          }
          recordIntelligentSymptoms(intelligentSymptoms);
        }
        else {
          recordIntelligentSymptoms(generateDefaultIntelligentSymptoms());
        }
      }
    }
    if (mode === "display" || isEmpty(dataState.visit?.uuid)) {
      if (!reportState.intelligentSymptoms || reportState.intelligentSymptoms.cc !== reportState.systemValueMap?.cc) {
        // in display mode (i.e. survey preview) don't try to invoke intelligent symptom generation and just show the defaults
        loadDefaultDisplaySymptoms();
      }
      else {
        // in display mode, make sure we have the necessary translations we will need
        fetchTrans({ asNeededKeys: reportState.intelligentSymptoms.symptomKeys });
      }
    }
    else if (!reportState.intelligentSymptoms || reportState.intelligentSymptoms.cc !== reportState.systemValueMap?.cc) {
      // this is a "live" form, so invoke the intelligent symptom display logic if we haven't provided it yet
      setLoadingIntelligentSymptoms(true);
      loadPotentialSymptoms();
    }
    else {
      // make sure that we have all the translations we need - if the page has been reloaded or the language changed
      // we might be missing them
      const untranslatedKeys = reportState.intelligentSymptoms.symptomKeys.filter((key => key === t(key)));
      if (untranslatedKeys) {
        fetchTrans({ asNeededKeys: untranslatedKeys });
      }
    }

    return () => { }
  }, []); // Empty dependency array ensures it runs only once on mount

  // only display at the top selected symptoms that were part of the intelligent recommendation list
  const selectedIntelligentSymptoms = selectedSxTrees.filter(sx => reportState?.intelligentSymptoms?.symptomKeys.find(key => key === sx.treeKey));
  const disableNoMatchOption = size(selectedIntelligentSymptoms) > 0;

  useEffect(() => {
    if (!question || !onUiMetadataChange) {
      return;
    }

    const newAssociatedSymptomKeys = getAssociatedSymptomKeys("includeIntelligent")
    const currentlyHasAssociatedSymptoms = uiMetadata?.hasAssociatedSymptoms === 'true';
    const currentlyHasSelection = uiMetadata?.hasSelectedSymptoms === 'true';

    if ((isEmpty(newAssociatedSymptomKeys) === currentlyHasAssociatedSymptoms) ||
      (isEmpty(selectedIntelligentSymptoms) === currentlyHasSelection)) {
      // console.log("updating uimetadata", isEmpty(newAssociatedSymptomKeys), currentlyHasAssociatedSymptoms);
      // console.log("new value", isEmpty(newAssociatedSymptomKeys) ? 'false' : 'true');
      onUiMetadataChange({
        uiMetadata: {
          ...(uiMetadata || {}),
          hasAssociatedSymptoms: isEmpty(newAssociatedSymptomKeys) ? 'false' : 'true',
          hasSelectedSymptoms: isEmpty(selectedIntelligentSymptoms) ? 'false' : 'true'
        },
        questionKey: question.questionKey
      });
    }
  }, [selectedSxTrees]);

  if (!question) {
    return <></>;
  }

  if (loadingIntelligentSymptoms || loadingTrees || loadingTrans || loadingSuggestions) {
    return (
      <div className='flex-1 flex-center'>
        <div className="flex-col gap-2">
          <LoadingAnim />
          {sltStr({ key: 'label-analyzing', capit: true })}
        </div>
      </div>
    );
  }

  const noMatchOption = includes(question?.answerKeys, noneAboveKey) ? noneAboveKey : null;
  const nonAboveSelected = includes(treeState?.answerMap[question.questionKey]?.answerKeys, noneAboveKey);



  return (
    <div className='t_QsInputSxIntelligentCollector flex-col gap-8'>
      <div className='flex-col gap-3'>
        {selectedIntelligentSymptoms?.map((sxTree) => {
          return <QsSelectedSymptom key={sxTree.treeKey} sxTree={sxTree} questionProps={{ ...props, suppressSxCollectors }} ></QsSelectedSymptom>
        })}
        {reportState?.intelligentSymptoms?.symptomKeys?.map((sxKey) => {
          // it is important to keep the QsCollapsibleGroupItem in the DOM, otherwise it will vanish while it is in
          // the process of being edited, causing the dialog box to vanish as the user attempts to edit it
          const selectedSymptom = selectedIntelligentSymptoms.find(sx => sx.treeKey === sxKey);
          return <div key={sxKey} className={classNames("border rounded-lg", selectedSymptom && "hidden")}>
            <QsCollapsibleGroupItem
              {...props}
              optionKey={sxKey}
              treeKey={treeKey}
              question={question!}
              suppressSxCollectors={suppressSxCollectors}
            />
          </div>
        })}
        {noMatchOption &&
          <div
            className='border rounded-lg form-control cursor-pointer'
          >
            <label
              className={classNames(
                'label gap-2 px-4 py-3 cursor-pointer',
                nonAboveSelected && ' bg-primary bg-opacity-5 rounded-lg',
                disableNoMatchOption && 'opacity-50'
              )}
            >
              <div className="flex-row gap-3 items-center">
                <input
                  type='checkbox'
                  checked={nonAboveSelected}
                  disabled={disableNoMatchOption}
                  className={classNames(
                    'checkbox checkbox-lg rounded',
                    nonAboveSelected ? 'bg-black bg-opacity-10' : 'checkbox-primary',
                  )}
                  onChange={(e) => {
                    e.stopPropagation();

                    const currentAnswers = treeState?.answerMap[question.questionKey]?.answerKeys || [];
                    if (e.target.checked) {
                      updateAnswer({
                        questionKey: question.questionKey,
                        answerKeys: union(currentAnswers, [noMatchOption]),
                      });
                    }
                    else {
                      updateAnswer({
                        questionKey: question.questionKey,
                        answerKeys: difference(currentAnswers, [noMatchOption]),
                      });
                    }
                  }}
                />
                <span className='label-text text-base font-medium'>{t(noMatchOption)}</span>
              </div>
            </label>
          </div>
        }
      </div>
    </div>

  );
}

export default memo(QsInputSxIntelligentCollector);