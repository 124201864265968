import { memo, useState, useEffect, useRef } from 'react';

import {
  FormControl,
  TextField,
  Box,
  Typography,
  Autocomplete,
  Link,
  Button
} from '@mui/material';
import { useGetPatientResourceDetailsQuery, useGetPatientResourceOptionsQuery, useSendPatientNotificationMutation } from '@cyren/common-lib';
import { size } from 'lodash';
import { TPatientVisit } from '../../../graphql-enhanced';
import { onError } from '../../../utils/apollo-utils';
import { toast } from 'react-toastify';
import { genMessageLongId } from '../../nav-utils';
import { SubmitButtonState } from './ModalSendCommunication';
import useAuth from '../../../auth/use-auth';
import LoadingAnim from '../../../components/LoadingAnim';
import ReactMarkdown from 'react-markdown';




function PanelSendPatientResource(props: {
  onClose: () => void;
  visit: TPatientVisit;
  setSubmitButtonState: ((newState: SubmitButtonState) => void)
}) {
  const [{ selectedOrg }] = useAuth();
  const orgId = selectedOrg?.id;
  const { onClose, visit, setSubmitButtonState } = props;

  const updateSubmitButtonState = () => {
    setSubmitButtonState(
      {
        loading: sending,
        enabled: size(selectedResource?.value) > 0,
        message: "Send",
        callback: handleSave
      });
  }

  useEffect(() => {
    updateSubmitButtonState();
  }, []);

  const [sendPatientNotification, { loading: sending }] = useSendPatientNotificationMutation({
    onError,
  });

  const { data: patientResources, loading: loadingNotificationOptions } = useGetPatientResourceOptionsQuery({
    onError,
    fetchPolicy: 'network-only',
    skip: !orgId,
    variables: {
      orgId: orgId!,
    },
  });

  type PatientResource = {
    value: string,
    label: string,
  }

  const resourceOptions: PatientResource[] = patientResources?.getPatientResourceOptions?.map(i => {
    const extraText = size(i?.note) ? ` [${i?.note}]` : "";
    return {
      value: i?.key || "",
      label: `${i?.messageText}${extraText}`
    }
  }) || [];


  const handleSend = (messageKey: string, messageText: string) => {
    sendPatientNotification({
      variables: {
        id: visit?.id as string,
        data: {
          messageContent: JSON.stringify({
            messageKey,
            messageSummary: messageText,
          }),
          recipientAddress: visit.surveyState.reportState?.systemValueMap?.phone as string,
          uniqueID: genMessageLongId(),
        }
      },
    }).then(() => {
      toast.success('Sent');
      onClose();
    });

  }

  const [selectedResource, setSelectedResource] = useState<PatientResource | null>(null);
  const handleSelectNotification = (event: React.SyntheticEvent<Element, Event>, value: PatientResource | null) => {
    // Handle the change event here
    setSelectedResource(value);
    setIsExpanded(false);
  };

  useEffect(() => {
    updateSubmitButtonState();
  }, [selectedResource]);


  const handleSave = () => {

    if (!selectedResource) {
      return;
    }
    handleSend(selectedResource.value, selectedResource.label);
  }

  const { data: resourceDetails, loading: loadingResourceDetails } = useGetPatientResourceDetailsQuery({
    onError,
    fetchPolicy: 'network-only',
    skip: !orgId || size(selectedResource?.value) === 0,
    variables: {
      orgId: orgId!,
      key: selectedResource?.value || "",
      locale: "en"
    },
  });


  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        setIsOverflowing(contentRef.current.scrollHeight > contentRef.current.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [resourceDetails?.getPatientResourceDetails?.resourceText]);

  return (
    <Box>
      {loadingNotificationOptions ? (
        <div className='flex-1 flex-center'>
          <LoadingAnim />
        </div>
      ) : (
        <>

          <Typography>Select a resource link you would like to send to the patient.</Typography>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <Autocomplete
              id="form-select-notification"
              options={resourceOptions}
              value={selectedResource} onChange={handleSelectNotification}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              renderInput={(params) => <TextField {...params} label="Resource" />}
            />
          </FormControl>
          {loadingResourceDetails ? (
            <div className='flex-1 flex-center'>
              <LoadingAnim />
            </div>
          ) : (
            <>
              {selectedResource &&
                <Box className="pt-2 pb-2">
                  <Typography variant="subtitle1" fontWeight={600} className="opacity-80">Resource:</Typography>
                  <Typography variant="body2">
                    {resourceDetails?.getPatientResourceDetails?.resourceUrl &&
                      <div className="px-5">
                        <Box>
                          <Typography variant="caption" className="opacity-80">If the patient completed the intake in another language, the SMS message text will be automatically translated, but the linked resource will remain in its original language.</Typography>
                        </Box>

                        <Link href={resourceDetails?.getPatientResourceDetails?.resourceUrl} target="_blank" rel="noopener noreferrer">
                          {resourceDetails?.getPatientResourceDetails?.resourceUrl}
                        </Link>
                      </div>
                    }
                    {resourceDetails?.getPatientResourceDetails?.resourceText &&
                      <>
                        <div className="relative px-5">
                          {!isExpanded && <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white"></div>}
                          <div ref={contentRef}
                            className='prose'
                            style={{
                              maxHeight: isExpanded ? 'none' : '5rem',
                              overflow: 'hidden',
                              transition: 'max-height 0.3s ease',
                            }}>
                            <ReactMarkdown>{resourceDetails?.getPatientResourceDetails?.resourceText}</ReactMarkdown>
                          </div>
                        </div>
                        {isOverflowing && (
                          <Button variant="text" onClick={toggleExpand} style={{ marginTop: '0.5rem' }}>
                            {isExpanded ? 'Less' : 'More'}
                          </Button>
                        )}
                      </>
                    }
                  </Typography>
                </Box>
              }
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default memo(PanelSendPatientResource);
