import { Enum_Questiontree_Treetype, notNull } from '@cyren/common-lib';
import produce from 'immer';
import { isEmpty, isEqual, pick, trim } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { RecoilState, atom, atomFamily, useRecoilState, useSetRecoilState } from 'recoil';
import useAdminAppState from '../../app/use-admin-app-state';
import { TSurvey } from '../../graphql-enhanced';
import { QuestionAnswerMap } from '../../report/types';
import { useData } from '../../report/use-data';
import { usePatientTransData } from '../../report/use-patient-trans-data';
import { getTextAnswer } from '../../utils/models/question-answer';
import { UpdateAnswerHandler, UpdateAnswerValueHandler } from '../pa-prop-types';
import { Key, Keys, ReportErrorStateType, ReportStateType, TreeState, UIMetadataT, ValueMap } from '../patient-types';
import { ModalSxState } from '../question/use-sx-modal-answers';
import { PaSurveyState, usePaSurveyParams } from './use-survey-state';
import { useTreeHelpers } from './use-tree-helpers';

export const defaultReportState: ReportStateType = {
  globalAnswerMap: {},
  treeStates: [] as TreeState[],
  nextTreeKeys: [],
  presentIllness: [],
  ccKey: undefined,
};

export const PaReportState = atom<ReportStateType>({
  key: 'PaReportState',
  default: defaultReportState,
});


export const defaultErrorState: ReportErrorStateType = {
  maxErrorsReached: false,
  unhandledErrors: []
};

export const PaErrorState = atom<ReportErrorStateType>({
  key: 'PaErrorState',
  default: defaultErrorState,
});


export function getTopLevelQsState({
  reportState,
  survey,
}: {
  survey?: TSurvey | null;
  reportState?: ReportStateType | null;
}) {
  const nonSxTreeQsAnswerMap = reportState?.treeStates?.reduce((prev, item) => {
    // check if it is top level tree state
    if (survey?.questionTreeKeys.includes(item?.treeKey)) {
      return {
        ...prev,
        ...item?.answerMap,
      };
    }
    return prev;
  }, {} as QuestionAnswerMap);

  const topLevelQsMap = {
    ...nonSxTreeQsAnswerMap,
    // global question answers
    ...reportState?.globalAnswerMap,
  };

  return topLevelQsMap;
}

export function useReportProviderState() {
  const navigate = useNavigate();
  const params = usePaSurveyParams();
  const { shortId, orgId } = params;
  const [, { setLocale }] = useAdminAppState();

  const [surveyState, setSurveyState] = useRecoilState(PaSurveyState);
  const [reportState, setReportState] = useRecoilState(PaReportState);

  const consolidatedAnswerMap = reportState.treeStates.reduce((p, i) => {
    if (i.treeKey != null && i.answerMap != null) {
      p[i.treeKey] = i.answerMap;
    }

    return p;
  }, {} as ValueMap);

  // useEffect(() => {
  //   if (!noRedirect && isEmpty(reportState.treeStates)) {
  //     navigate(`/r/${orgId}/${shortId}`);
  //   }
  // }, [reportState.treeStates, noRedirect]);

  function startOver() {
    setSurveyState(
      produce(surveyState, (draft) => {
        draft.curTreeKey = undefined;
      }),
    );

    setReportState(
      produce(reportState, (draft) => {
        draft.treeStates = [];
        draft.ccKey = undefined;
        draft.presentIllness = [];
      }),
    );

    setLocale(null);

    navigate(`/s/${orgId}/${shortId}`);
  }

  return [
    { consolidatedAnswerMap, reportState, surveyState, params },
    { navigate, setReportState, setSurveyState, startOver },
  ] as const;
}

export function useReportAnswers() {
  const [reportState, setReportState] = useRecoilState(PaReportState);
  const params = usePaSurveyParams();
  const { treeKey } = params;

  const { treeStates } = reportState;

  const treeIndex = treeStates.findIndex((treeAnswer) => treeAnswer.treeKey === treeKey);

  /* function addAnswerForQuestionKey({ */
  /*   treeKey, */
  /*   questionKey, */
  /*   answerKeys, */
  /*   answerValues, */
  /* }: { */
  /*   treeKey: string; */
  /*   questionKey: string; */
  /*   answerKeys?: Keys; */
  /*   answerValues?: Values; */
  /* }) { */
  /*   // */
  /* } */

  return [{ reportState, treeIndex }, { setReportState }] as const;
}

export function useReportState({
  treeKey,
  defaultState = PaReportState,
}: {
  treeKey?: string;
  defaultState?: RecoilState<ReportStateType>;
}) {
  const [reportState, setReportState] = useRecoilState(defaultState);

  const [
    {
      dataState: { survey },
    },
    { getQuestionByKey, getTreeByKey },
  ] = useData();

  const treeIndex = reportState.treeStates.findIndex((treeS) => treeS.treeKey === treeKey);
  const treeState = reportState.treeStates[treeIndex] as TreeState | undefined;

  const tree = treeKey ? getTreeByKey(treeKey) : null;

  // init tree state
  useEffect(() => {
    if (treeState === undefined && treeKey) {
      const existingTreeState = reportState.treeStates.find((ts) => ts.treeKey === treeKey);

      if (tree && !existingTreeState) {
        const topLevelQsMap = getTopLevelQsState({
          reportState,
          survey,
        });

        setReportState(
          produce(reportState, (draft) => {
            // get top level question answer maps
            // get previous answers for the same
            const answerMap = pick(topLevelQsMap, tree?.entryQuestionKeys) || {};

            const newTreeState = {
              answerMap,
              tree,
              treeKey,
            };

            // new tree state
            draft.treeStates.push(newTreeState);
          }),
        );
      }
    }
  }, [treeKey, tree, treeState]);

  function onUiMetadataChange({
    uiMetadata,
    questionKey,
  }: {
    uiMetadata: UIMetadataT;
    questionKey: Key;
  }) {
    // answer states
    setReportState((st) => {
      return produce(st, (draft) => {
        if (draft.treeStates[treeIndex].answerMap[questionKey] != null) {
          // @ts-ignore
          draft.treeStates[treeIndex].answerMap[questionKey].uiMetadata = uiMetadata;
        }
      });
    });
  }

  const updateAnswer: UpdateAnswerHandler = (params) => {
    const { questionKey, level } = params;

    const qs = getQuestionByKey(questionKey);
    const isGlobal = !!qs?.global;
    const systemKey = qs?.systemKey;

    if (level === 0) {
      // console.log('updater', questionKey, 'level', level, {
      //   answerValues,
      //   answerKeys,
      // });
      // top level qs answer
      // set global state
    }

    // answer states
    setReportState((st) => {
      return produce(st, (draft) => {
        const { answerMap } = draft.treeStates[treeIndex];

        if (answerMap[questionKey] == null) {
          answerMap[questionKey] = {
            answerKeys: [],
            answerValues: [],
          };
        }

        answerMap[questionKey] = {
          ...answerMap[questionKey],
          ...params,
        };

        if (isGlobal && answerMap[questionKey]) {
          draft.globalAnswerMap = {
            ...draft.globalAnswerMap,
            [questionKey]: answerMap[questionKey],
          };
        }
        if (systemKey) {
          if (!draft?.systemValueMap) draft.systemValueMap = {};
          const systemValue = trim(getTextAnswer(answerMap[questionKey]));

          draft.systemValueMap = {
            ...draft?.systemValueMap,
            [systemKey]: systemValue,
          };
        }
      });
    });
  };

  const updateAnswerValue: UpdateAnswerValueHandler = ({ questionKey, answerValue }) => {
    // answer states
    setReportState((st) => {
      return produce(st, (draft) => {
        const { answerMap } = draft.treeStates[treeIndex];
        answerMap[questionKey] = answerValue;
      });
    });
  };

  const selectedSxTrees = reportState.treeStates
    .map((trs) => {
      if (isEmpty(trs.answerMap)) return null;

      if (trs.tree?.treeType === Enum_Questiontree_Treetype.Symptom) {

        // Don't consider a symptom selected if the user opened it but didn't actually
        // answer any of the questions
        const answeredQuestion = trs.tree?.entryQuestionKeys.some((qsKey) => {
          const answerMap = trs.answerMap[qsKey];
          return (!isEmpty(answerMap?.answerKeys) || !isEmpty(answerMap?.answerValues) || !isEmpty(answerMap?.answerMaps));
        });
        if (!answeredQuestion) {
          return null;
        }


        return trs.tree;
      }
      return null;
    })
    .filter(notNull);

  const sxKeys = selectedSxTrees.map((str) => str.treeKey).filter(notNull);
  usePatientTransData({
    keys: sxKeys,
  });

  return [
    { selectedSxTrees, reportState, treeState },
    { updateAnswer, updateAnswerValue, setReportState, onUiMetadataChange },
  ] as const;
}

const HiddenKeys = atomFamily<Keys, Key | undefined>({
  key: 'HiddenKeys',
  default: [],
});

export function useQsVisibilitySync({
  treeState,
  forSxModal,
  suppressSxCollectors
}: {
  treeState?: TreeState | null;
  forSxModal?: boolean;
  suppressSxCollectors?: boolean;
}) {
  const treeKey = treeState?.treeKey;
  const [reportState, setReportState] = useRecoilState(PaReportState);
  const setModalSxState = useSetRecoilState(ModalSxState(treeKey));
  const [hiddenKeys, setHiddenKeys] = useRecoilState(HiddenKeys(treeKey));
  const { treeStates } = reportState;

  const [{ isValid, qsErrorMap, qsKeysNotAnswered }, { getHiddenQsKeys }] = useTreeHelpers({
    treeState,
    treeOptions: { suppressSxCollectors }
  });

  function unsetHiddenQuestion({
    treeState: treeStateT,
    keys,
  }: {
    treeState: TreeState;
    keys: Keys;
  }) {
    const nState = produce(treeStateT, (draft) => {
      const { answerMap } = draft;

      keys?.forEach((hiddenQsKey) => {
        if (answerMap[hiddenQsKey]) {
          delete answerMap[hiddenQsKey];
        }
      });
    });

    return nState;
  }

  // unset hidden questions
  useEffect(() => {
    // for main report
    if (!forSxModal && treeState) {
      const hKeys = getHiddenQsKeys();
      if (!isEqual(hiddenKeys, hKeys)) {
        // update state
        const nState = unsetHiddenQuestion({ treeState, keys: hKeys });

        const treeIndex = treeStates.findIndex((treeAnswer) => treeAnswer.treeKey === treeKey);

        // debugger;
        setReportState(
          produce(reportState, (draft) => {
            draft.treeStates[treeIndex] = nState;
          }),
        );

        setHiddenKeys(hKeys);
      }
    }

    // for sx modal state
    if (forSxModal && treeState) {
      const hKeys = getHiddenQsKeys();

      if (!isEqual(hiddenKeys, hKeys)) {
        // update state
        const nState = unsetHiddenQuestion({ treeState, keys: hKeys });
        setModalSxState(nState);

        setHiddenKeys(hKeys);
      }
    }
    // for SX modal
  }, [hiddenKeys, treeState]);

  return [{ qsErrorMap, isValid, qsKeysNotAnswered }] as const;
}
