import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useReportRecoilState } from '../patient/hooks/use-report-recoil-state';
import { useSurveyState } from '../patient/hooks/use-survey-state';
import { usePaSurveyParams } from '../report/use-pa-survey-params';

import hipaa from '../assets/ill/hipaa-badge.svg';
import config from '../config';

export default function Footer() {
  const [, { setReportState }] = useReportRecoilState();
  const [, { updateVisit }] = useSurveyState();
  const nav = useNavigate();
  const { shortId, orgId } = usePaSurveyParams();

  return (
    <footer className={classNames('footer flex p-4 gap-3 text-xs')}>
      <div className='flex-1 flex-col opacity-80'>
        <div
          className='w-full flex justify-start'
          onClick={async () => {
            const nState = {
              globalAnswerMap: {},
              nextTreeKeys: [],
              presentIllness: [],
              treeStates: [],
              systemValueMap: {},
            };
            // reset state
            await setReportState(nState);

            updateVisit(nState);

            nav(`/s/${orgId}/${shortId}`);
          }}
        >
          <p>© 2024 CyrenCare Inc.</p>
        </div>
        {/* <div> */
        /*   <img src={hipaa} className='w-16' /> */
        /* </div> */}
        <div className='w-full flex justify-start flex-wrap gap-3'>
          <a className='link' href={config.termsOfService} target='_blank' rel='noreferrer'>
            Terms of use
          </a>
          <a className='link' href={config.privacyPolicy} target='_blank' rel='noreferrer'>
            Privacy policy
          </a>
        </div>
      </div>
      <div
        className='cursor-pointer'
        onClick={() => {
          window.open('https://www.accountablehq.com/', '_blank');
        }}
      >
        <img src={hipaa} />
      </div>
    </footer>
  );
}
