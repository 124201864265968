import { Box, ThemeProvider, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { patientTheme } from "../app/mui-theme";
import { QRCodeSVG } from "qrcode.react";
import { useLocation } from "react-router";
import { isEmpty } from "lodash";
import { genVisitId } from "../admin/nav-utils";
import { FaCopy, FaPrint, FaRotate } from "react-icons/fa6";
import { toast } from 'react-toastify';

export type PrintableUniqueQRCodeData = {
  landingUrl: string;
  idParameter: string;
}

function PrintableUniqueQRCode() {


  useEffect(() => {
    // Inject the CSS into the popup window's document head
    const styleElement = window.document.createElement("style");
    styleElement.textContent = `
@media print {
  @page {
    size: 3in 4in;
    margin: 0;
  }

  body {
    margin: 0;
    width: 3in;
    height: 4in;
    overflow: hidden; /* Prevent content overflow */
  }

  .label-content {
    width: 100%;
    height: 100%;
    /*transform: scale(50%);*/ /* Automatically scale to fit width */
    /*transform-origin: top left;*/ /* Keep the scaling aligned to the top-left */
  }
}
`;
    window.document.head.appendChild(styleElement);

    // Cleanup the CSS when the component unmounts
    return () => {
      window.document.head.removeChild(styleElement);
    };
  }, []);

  function addUrlParameter(url: string, paramName: string, paramValue: string) {
    const urlObj = new URL(url); // Parse the URL
    urlObj.searchParams.set(paramName, paramValue); // Add or update the parameter
    return urlObj.toString(); // Convert back to string
  }

  const query = new URLSearchParams(useLocation().search);
  let data: PrintableUniqueQRCodeData | null = null;
  let showError = false;
  try {
    // Attempt to decode the string to see if it's a proper base64 encoded value
    data = JSON.parse(atob(query.get('data') || ""));
  } catch (error) {
    showError = true;
  }

  let qrLink = data?.landingUrl || "";

  if (!/^https?:\/\//i.test(qrLink)) {
    // Construct a full URL based on the current site
    qrLink = new URL(qrLink, window.location.origin).toString();
  }

  const [randomVisitId, setRandomVisitId] = useState(genVisitId());

  const idParameter = data?.idParameter || "";
  if (isEmpty(qrLink) || isEmpty(idParameter)) {
    showError = true;
  }
  else {
    qrLink = addUrlParameter(qrLink, idParameter, btoa(randomVisitId));
  }
  showError = showError || isEmpty(qrLink);

  return (
    <ThemeProvider theme={patientTheme}>
      {showError ? (
        <div className="text-red-600 bg-red-100 border border-red-400 p-3 rounded">
          Invalid QRCode Data
        </div>
      ) : (
        <Box className="label-content">
          <Box className="flex justify-end gap-2 print:hidden p-2">
            <button
              title="Copy to Clipboard"
              className='btn'
              onClick={() => {
                navigator.clipboard.writeText(qrLink).then(() => {
                  // Show snackbar on successful copy
                  toast.success("Copied");
                }).catch(err => {
                  toast.error(`Failed to copy text: ${err} `);
                });
              }}
            >
              <FaCopy></FaCopy>
            </button>
            <button
              title="Regenerate"
              className='btn'
              onClick={() => {
                setRandomVisitId(genVisitId());
              }}
            >
              <FaRotate></FaRotate>
            </button>
            <button
              title="Print"
              className='btn'
              onClick={() => {
                window.print();
              }}
            >
              <FaPrint></FaPrint>
            </button>
          </Box>
          <Box sx={{ width: "400px", height: "533px", position: "relative" }}>
            <Box className="text-center" sx={{ position: "absolute", width: "100%", top: "0px", left: "0px" }}>
              <Typography fontSize="small" className="p-1">Use your phone to scan this QR code.</Typography>
              <Typography fontSize="small" className="p-1">Complete the form to tell us what brought you to the Emergency Department.</Typography>
            </Box>
            <Box className="p-5" sx={{ position: "absolute", width: "100%", top: "65px", left: "0px" }}>
              <QRCodeSVG value={qrLink} className='w-full' size={250} />
            </Box>
            <Box
              sx={{ position: "absolute", width: "100%", top: "345px", left: "0px" }}
              className="py-2 px-4"
            >
              <Box className="border-4 border-black rounded-lg p-1 text-center">
                <Typography fontSize="large">Visit ID:</Typography>
                <Typography fontSize="3rem" fontWeight={700}>{randomVisitId.split('').join(' ')}</Typography>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", width: "100%", top: "475px", left: "0px" }}>
              <Typography fontSize="small" className="p-1 text-center">Keep this sticker.  Show this code to your provider so they can access the information you provided.</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  )
}

export default memo(PrintableUniqueQRCode);
