import { useEffect } from 'react';
import { useBlocker } from 'react-router';

// NOTE
// https://github.com/remix-run/react-router/blob/main/examples/navigation-blocking/src/app.tsx
export function useOnNavChange({
  block,
  onChange,
}: {
  block?: boolean;
  // eslint-disable-next-line
  onChange?: (p?: any) => void;
}) {
  const shouldBlock = !!block;
  const blocker = useBlocker(shouldBlock);

  // console.log('shouldBlock', shouldBlock);
  // console.log('blocker', blocker);

  useEffect(() => {
    if (shouldBlock === true && blocker.state === 'blocked') {
      onChange?.();
      blocker?.reset?.();
    }

    if (shouldBlock !== true) {
      blocker?.reset?.();
    }
  }, [blocker.state, shouldBlock]);

  useEffect(() => {
    if (!shouldBlock) blocker.reset?.();
  }, [shouldBlock]);

  // console.log('blocker', blocker);

  return { blocker } as const;
}
