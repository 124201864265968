import countries from './countries.json';
export function getCountriesOptions() {
    return countries.map((item) => {
        return {
            value: item.code,
            label: item.name,
        };
    });
}
export function getLabelForCountryKey(key) {
    return (countries.find((item) => {
        return item.code === key;
    })?.name || null);
}
