import { DeeplLanguagesAll } from './langs-deepl';
import { GoogleLanguagesAll } from './langs-google';
export const cyrenLocales = [
    { key: 'english', code: 'en', label: 'english' },
    { key: 'spanish', code: 'es', label: 'español' },
    { key: 'chinese', code: 'zh', label: '中文' },
    { key: 'tagalog', code: 'tl', label: 'Tagalog' },
    { key: 'vietnamese', code: 'vi', label: 'Tiếng Việt' },
    { key: 'arabic', code: 'ar', label: 'العربية' },
    { key: 'french', code: 'fr', label: 'français' },
    { key: 'korean', code: 'ko', label: '한국어' },
    { key: 'russian', code: 'ru', label: 'русский' },
    { key: 'german', code: 'de', label: 'Deutsch' },
    { key: 'haitian creole', code: 'ht', label: 'Kreyòl Ayisyen' },
    { key: 'hindi', code: 'hi', label: 'हिन्दी' },
    { key: 'portuguese', code: 'pt', label: 'português' },
    { key: 'italian', code: 'it', label: 'italiano' },
    { key: 'polish', code: 'pl', label: 'polski' },
    { key: 'yiddish', code: 'yi', label: 'ייִדיש' },
    { key: 'japanese', code: 'ja', label: '日本語' },
    { key: 'persian', code: 'fa', label: 'فارسی' },
    { key: 'bengali', code: 'bn', label: 'বাংলা' },
    { key: 'thai', code: 'th', label: 'ไทย' },
    { key: 'urdu', code: 'ur', label: 'اردو' },
    { key: 'greek', code: 'el', label: 'ελληνικά' },
    { key: 'punjabi', code: 'pa', label: 'ਪੰਜਾਬੀ' },
    { key: 'armenian', code: 'hy', label: 'հայերեն' },
    // converted to croatian
    { key: 'croatian', code: 'hr', label: 'hrvatski' },
    { key: 'hebrew', code: 'he', label: 'עברית' },
    { key: 'gujarati', code: 'gu', label: 'ગુજરાતી' },
    { key: 'telugu', code: 'te', label: 'తెలుగు' },
    { key: 'tamil', code: 'ta', label: 'தமிழ்' },
    { key: 'hmong', code: 'hmn', label: 'Hmoob' },
    { key: 'khmer', code: 'km', label: 'ភាសាខ្មែរ' },
    // no support from any trans api
    // { name: 'navajo', code: 'nv', label: 'Diné bizaad' },
];
export const cyrenRtlLocales = cyrenLocales
    .filter((lang) => ['ar', 'fa', 'ur', 'yi', 'he'].includes(lang.code))
    .map((lang) => lang.key);
export const cyrenLocaleKeys = cyrenLocales.map((loc) => loc.key);
export function convLocaleToCode({ locale }) {
    const loc = cyrenLocales.find((l) => l.key === locale);
    return loc?.code;
}
export function getCodeForLocale({ locale }) {
    const loc = cyrenLocales.find((l) => l.key === locale);
    return loc?.code;
}
export function getSupportedKeysForService({ service }) {
    if (service === 'google') {
        return getGooTransKeys();
    }
    if (service === 'deepl') {
        return getGooTransKeys();
    }
    return [];
}
export function findBestApiServiceForLocale({ locale }) {
    const code = getCodeForLocale({ locale });
    if (!code)
        return null;
    if (isCodeSupportedByService({ service: 'deepl', code })) {
        return 'deepl';
    }
    return 'google';
}
export function findBestApiServiceForCode({ code }) {
    if (isCodeSupportedByService({ service: 'deepl', code })) {
        return 'deepl';
    }
    return 'google';
}
export function isCodeSupportedByService({ code, service, }) {
    if (service === 'google') {
        return getGooTransCodes().includes(code);
    }
    if (service === 'deepl') {
        return getDeepLCodes().includes(code);
    }
    return false;
}
export function getDeepLCodes() {
    return DeeplLanguagesAll.map((i) => i.code);
}
export function getGooTransCodes() {
    return GoogleLanguagesAll.map((i) => i.code);
}
export function getDeepLKeys() {
    const codes = getDeepLCodes();
    const locs = cyrenLocales.filter((loc) => codes.includes(loc.code));
    return locs.map((loc) => loc.key);
}
export function getGooTransKeys() {
    const codes = getGooTransCodes();
    const locs = cyrenLocales.filter((loc) => codes.includes(loc.code));
    return locs.map((loc) => loc.key);
}
