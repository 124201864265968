import { isEmpty, keys, startsWith, trim } from 'lodash';
import sxConsti from '../../../assets/files/sx-tree-constitutional.json';
import sxGastro from '../../../assets/files/sx-tree-gastrointestinal.json';
import sxStartFb from '../../../assets/files/sx-tree-start-fb.json';
import sxStartInjury from '../../../assets/files/sx-tree-start-injury.json';
import sxStartMedial from '../../../assets/files/sx-tree-start-medical.json';
import sxStartSubstance from '../../../assets/files/sx-tree-start-substance.json';
import sxRosOther from '../../../assets/files/sx-ros-other.json';
import sxStartAller from '../../../assets/files/sx-tree-allergic.json';
import sxStartCardio from '../../../assets/files/sx-tree-cardiovascular.json';
import sxStartEnt from '../../../assets/files/sx-tree-ent.json';
import sxStartEyes from '../../../assets/files/sx-tree-eyes.json';
import sxStartGenitourF from '../../../assets/files/sx-tree-genitourinary-f.json';
import sxStartGenitourM from '../../../assets/files/sx-tree-genitourinary-m.json';
import sxStartGenitour from '../../../assets/files/sx-tree-genitourinary.json';
import sxStartIntegum from '../../../assets/files/sx-tree-integumentary.json';
import sxStartMuscul from '../../../assets/files/sx-tree-musculoskeletal.json';
import sxStartNerolo from '../../../assets/files/sx-tree-neurological.json';
import sxStartPsych from '../../../assets/files/sx-tree-psychiatric.json';
import sxStartRespira from '../../../assets/files/sx-tree-respiratory.json';
import { notNull } from '../../utils';
export function fetchSxStartTree() {
    return [
        // ...fetchSxTreeByJson(sxTree),
        ...fetchSxTreeByJson(sxStartInjury),
        ...fetchSxTreeByJson(sxStartFb),
        ...fetchSxTreeByJson(sxConsti),
        ...fetchSxTreeByJson(sxStartMedial),
        ...fetchSxTreeByJson(sxStartSubstance),
        ...fetchSxTreeByJson(sxGastro),
        ...fetchSxTreeByJson(sxStartAller),
        ...fetchSxTreeByJson(sxStartCardio),
        ...fetchSxTreeByJson(sxStartEnt),
        ...fetchSxTreeByJson(sxStartEyes),
        ...fetchSxTreeByJson(sxStartGenitourF),
        ...fetchSxTreeByJson(sxStartGenitourM),
        ...fetchSxTreeByJson(sxStartGenitour),
        ...fetchSxTreeByJson(sxStartIntegum),
        ...fetchSxTreeByJson(sxStartNerolo),
        ...fetchSxTreeByJson(sxStartRespira),
        ...fetchSxTreeByJson(sxStartMuscul),
        ...fetchSxTreeByJson(sxStartPsych),
        ...fetchSxTreeByJson(sxRosOther),
    ];
}
const list = fetchSxStartTree();
export function getTreesByKey(key) {
    return list.filter((t) => t.treeKey === key) || [];
}
export function getTreeByKey(key) {
    return list.find((t) => t.treeKey === key) || null;
}
// const duplicTrees = fetchSxStartTree()
//   .filter((t) => {
//     const tlist = getTreesByKey(t.treeKey);
//     return size(tlist) > 1 ? t : null;
//   })
//   .filter(notNull);
// console.log('duplicTrees', duplicTrees);
export function fetchSxTreeByJson(rows) {
    const qsKeys = keys(rows[0])
        // get question column names
        .filter((key) => startsWith(key, 'q-'))
        .filter(notNull);
    const rowTrees = rows
        .map((item) => {
        const entryQuestionKeys = qsKeys.filter((qsKey) => {
            // @ts-ignore
            return trim(item[qsKey] || '') === '1';
        });
        return {
            treeKey: item.symptom,
            entryQuestionKeys,
        };
    })
        .filter((item) => {
        return !isEmpty(item.treeKey);
    });
    return rowTrees;
}
