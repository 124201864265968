import React from 'react';

interface MyIconProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number;  // Optional width
  height?: string | number; // Optional height
  fill?: string;            // Optional fill color
}

const FaProPlusCircle: React.FC<MyIconProps> = (props) => {
  const { width = '24', height = '24', fill = 'currentColor', ...restProps } = props;

  return (
    <>
      {/*<!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.-->*/}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={width}  // width and height can now be passed as props
        height={height}
        fill={fill}    // fill can be customized via props
        {...restProps} // Spread the rest of the props (like className, onClick, etc.)
      >
        <path d="M384 250v12c0 6.6-5.4 12-12 12h-98v98c0 6.6-5.4 12-12 12h-12c-6.6 0-12-5.4-12-12v-98h-98c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h98v-98c0-6.6 5.4-12 12-12h12c6.6 0 12 5.4 12 12v98h98c6.6 0 12 5.4 12 12zm120 6c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z"/>
      </svg>
    </>
  );
};

export default FaProPlusCircle;


