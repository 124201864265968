import classNames from 'classnames';
import produce from 'immer';
import { difference, first, keys, uniq } from 'lodash';
import { memo, useRef } from 'react';
import { getMutualExclusiveAnswerKeys } from '../../../utils/models/question';
import { useTranslations } from '../../hooks/use-translations';
import { QuestionProps } from '../../pa-prop-types';
import { Keys } from '../../patient-types';
import QsTextInput from './QsTextInput';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';

function focusWithId(el: HTMLElement, id: string) {
  const extNoteEl = first(el.getElementsByClassName(id));
  // @ts-ignore
  extNoteEl?.focus();
}

function focusWithIdTimeOut(el: HTMLElement, id: string, time: number) {
  const trashCanEl = first(el.getElementsByClassName('qs-item-trashcan'));
  const fakeInput = document.createElement('input');
  fakeInput.setAttribute('type', 'text');
  fakeInput.style.display = 'inline-block';
  fakeInput.style.opacity = '0';
  fakeInput.style.height = '0';
  fakeInput.style.width = '0';
  fakeInput.style.fontSize = '16px'; // disable auto zoom

  // you may need to append to another element depending on the browser's auto
  // zoom/scroll behavior
  trashCanEl?.appendChild(fakeInput);

  // focus so that subsequent async focus will work
  fakeInput.focus();

  setTimeout(() => {
    focusWithId(el, id);
    // cleanup
    // fakeInput.remove();
  }, time);
}

function QsInputSelector(props: QuestionProps) {
  const { locale, question, updateAnswer, treeState, updateValue } = props;

  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();

  const ref = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  // console.log('treeState', treeState);
  // console.log('question', question);

  if (!question) {
    return null;
  }

  const { questionKey, answerKeys } = question;

  const answerOptionsMap = question?.answerOptionsMap || {};
  const { answerMap } = treeState || {};

  const radio = question?.typeOptions?.min === '1' && question?.typeOptions?.max === '1';
  const multi = !radio;

  const qAnswerMap = answerMap?.[questionKey];
  const { notesForKeys } = qAnswerMap || {};
  const selectedKeys = qAnswerMap?.answerKeys || [];

  const mutExcAnswerKeys = getMutualExclusiveAnswerKeys({ question });

  return (
    <>
      <div ref={ref} className='t_QsInputSelectorV2 flex-col gap-2'>
        {multi &&
          answerKeys?.map((answerKey) => {
            const optionChecked = selectedKeys?.includes(answerKey);

            const extNoteRequired = answerOptionsMap?.[answerKey]?.requireNote;
            const showExtNoteInput = optionChecked && answerOptionsMap?.[answerKey]?.requireNote;

            const extNoteId = `${questionKey}-ext-${answerKey}`;

            const isMutExcl = mutExcAnswerKeys.includes(answerKey);

            return (
              <div key={answerKey}>
                <label className='cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'>
                  <div className='flex-1 w-full flex-row gap-4'>
                    <input
                      value={answerKey}
                      type='checkbox'
                      name={questionKey}
                      checked={optionChecked}
                      className={'checkbox checkbox-lg checkbox-primary'}
                      onChange={(v) => {
                        if (!updateAnswer) return;

                        const { value, checked } = v.target;

                        let nKeys: Keys = [];

                        if (checked) {
                          if (isMutExcl) {
                            nKeys = uniq([value]);
                          } else {
                            nKeys = uniq([...selectedKeys, value]);
                            nKeys = nKeys?.filter((k) => {
                              return !mutExcAnswerKeys.includes(k);
                            });
                          }
                        } else {
                          nKeys = selectedKeys.filter((nv) => nv !== value);
                        }

                        // removing ext notes for deleted keys
                        const currKeys = keys(notesForKeys);
                        const nNotesForKeys = produce(notesForKeys, (draft) => {
                          const removingKeys = difference(currKeys, nKeys);
                          removingKeys?.forEach((rk) => {
                            delete draft?.[rk];
                          });
                        });

                        updateAnswer({
                          questionKey,
                          answerKeys: nKeys,
                          notesForKeys: nNotesForKeys,
                        });

                        if (extNoteRequired && v.target.checked && ref.current) {
                          focusWithId(ref.current, extNoteId);
                          focusWithIdTimeOut(ref.current, extNoteId, 400);
                        }
                      }}
                    />
                    <span className='pt-[0.2rem]'>{t(answerKey, {})}</span>
                  </div>
                  {showExtNoteInput && (
                    <label key={`${answerKey}-note`} className='flex flex-col'>
                      <QsTextInput
                        updateValue={updateValue}
                        locale={locale}
                        placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
                        value={notesForKeys?.[answerKey] || ''}
                        className={classNames(
                          extNoteId,
                          'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
                        )}
                        onUpdate={(value) => {
                          if (!updateAnswer) return;
                          updateAnswer({
                            questionKey,
                            notesForKeys: {
                              ...notesForKeys,
                              [answerKey]: value,
                            },
                          });
                        }}
                      />
                    </label>
                  )}
                </label>

                <div className={classNames('qs-item-trashcan', `h-0 w-0 overflow-hidden`)}></div>
              </div>
            );
          })}
        {radio &&
          answerKeys?.map((answerKey) => {
            const optionChecked = selectedKeys?.includes(answerKey);

            const extNoteRequired = answerOptionsMap?.[answerKey]?.requireNote;
            const showExtNoteInput = optionChecked && answerOptionsMap?.[answerKey]?.requireNote;

            const extNoteId = `${questionKey}-ext-${answerKey}`;

            return (
              <div key={answerKey}>
                <label
                  key={answerKey}
                  className='cursor-pointer border-1_5 border-black p-3 rounded-xl border-opacity-10 flex-col gap-3'
                >
                  <div className='flex-1 w-full flex-row gap-4 items-start'>
                    <input
                      id={answerKey}
                      key={answerKey}
                      value={answerKey}
                      type='radio'
                      checked={optionChecked}
                      className={'radio radio-lg radio-primary'}
                      onChange={(v) => {
                        if (!updateAnswer) return;
                        updateAnswer({
                          questionKey,
                          answerKeys: [v.target.value],
                        });

                        if (extNoteRequired && v.target.checked && ref.current) {
                          focusWithId(ref.current, extNoteId);
                          focusWithIdTimeOut(ref.current, extNoteId, 400);
                        }
                      }}
                    />
                    <span className='pt-[0.2rem]'>{t(answerKey)}</span>
                  </div>

                  {showExtNoteInput && (
                    <label key={`${answerKey}-note`} className='flex flex-col'>
                      <QsTextInput
                        {...props}
                        locale={locale}
                        placeholder={sltStr({ key: 'label-describe-in-detail', upper: true })}
                        value={notesForKeys?.[answerKey] || ''}
                        className={classNames(
                          extNoteId,
                          'input w-full border-1_5 border-black border-opacity-10 rounded-xl'
                        )}
                        onUpdate={(value) => {
                          if (!updateAnswer) return;
                          updateAnswer({
                            questionKey,
                            notesForKeys: {
                              ...notesForKeys,
                              [answerKey]: value,
                            },
                          });
                        }}
                      />
                    </label>
                  )}
                </label>

                <div className={classNames('qs-item-trashcan', `h-0 w-0 overflow-hidden`)}></div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default memo(QsInputSelector);
