import { atom, useRecoilState } from 'recoil';

const AppConfigState = atom<{
  authLoading?: boolean;
  authInit?: boolean;
} | null>({
  key: 'AppConfigState',
  default: { authInit: false, authLoading: false },
});

export default function useAppConfig() {
  const [appConfig, setAppConfig] = useRecoilState(AppConfigState);

  return [appConfig, setAppConfig] as const;
}
