import { formatDate } from '@cyren/common-lib';

// @ts-ignore
export const dateConfigPreset = {
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
  month: {
    format: 'MM',
    caption: 'Month',
    step: 1,
  },
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
} as JSON;

// @ts-ignore
export const timeConfigPreset = {
  hour: {
    format: (value: Date) => {
      return formatDate(value, { formatStr: 'hh aa' });
    },
    caption: 'Hour',
    step: 1,
  },
  minute: {
    format: 'mm',
    caption: 'Min',
    step: 1,
  },
} as JSON;

export const datetimeConfigPreset = {
  ...dateConfigPreset,
  ...timeConfigPreset,
} as JSON;
