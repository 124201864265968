
export const systemKeyMap:Record<string, string>  = {
  firstName: 'First Name',
  lastName: 'Last Name',
  cc: 'Credit Card',
  sex: 'Sex',
  dob: 'Date of Birth',
  phone: 'Phone Number',
  custom1: 'Custom Field 1',
  custom2: 'Custom Field 2',
  custom3: 'Custom Field 3',
  address_1: 'Address Line 1',
  address_2: 'Address Line 2',
  city: 'City',
  state: 'State',
  zipcode: 'Zip Code',
  country: 'Country',
  age: 'Age',
  anonymized_id: 'Anonymized ID'
};
