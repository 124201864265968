import classNames from 'classnames';
import { InputHTMLAttributes, memo } from 'react';

function QsBloodPressure(
  props: InputHTMLAttributes<HTMLInputElement> & {
    values?: string[];
    onUpdate: (values: string[]) => void;
  }
) {
  const { className, onUpdate, values } = props;

  const [value1, value2] = values || [];

  return (
    <div className='t_QsBloodPressure flex-center-y'>
      <input
        type='number'
        placeholder='SYSTOLIC'
        className={classNames('input input-bordered w-full', className)}
        value={value1}
        onChange={(e) => {
          onUpdate([e.target.value, value2]);
        }}
      />
      <span className='text-3xl px-2'>/</span>
      <input
        type='nubmer'
        placeholder='DIASTOLIC'
        value={value2}
        className={classNames('input input-bordered w-full', className)}
        onChange={(e) => {
          onUpdate([value1, e.target.value]);
        }}
      />
    </div>
  );
}

export default memo(QsBloodPressure);
