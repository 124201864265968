import classNames from 'classnames';
import { first, isEmpty } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { Outlet } from 'react-router';
import useScrollToTop from '../common/hooks/use-scroll-to-top';
import LoadingAnim from '../components/LoadingAnim';
import { useData } from '../report/use-data';
import ModalLocale from './ModalLocale';
import useAppState from './use-app-state';

function PatientLayout({ children }: { children?: ReactNode }) {
  useEffect(() => {
    const el = first(window.document.getElementsByTagName('html'));
    el?.setAttribute('data-theme', 'patientLight');
  }, []);

  const [
    {
      dataState: { survey, init, errors },
    },
  ] = useData({ initData: true, disableRedirectOnInvalidReport: true });

  const [menuOpen, setMenuOpen] = useState(false);
  useScrollToTop();

  const errored = !isEmpty(errors);

  const [
    {
      dir,
      appState: { openLocale },
    },
  ] = useAppState({ requireLocaleInit: true });

  function renderContent() {
    if (!init) {
      <div className='flex flex-1 w-full py-10'>
        <LoadingAnim className='h-full p-5 w-full' />
      </div>;
    }

    if (survey?.deleted) {
      return (
        <div className='flex-col gap-2 items-start p-5'>
          <div className=''>
            <div className=''>Questionnaire had been deleted.</div>
            <div className='font-bold'>Please talk to the staff</div>
          </div>

          <button
            className='btn btn-primary'
            onClick={() => {
              window.location.reload();
            }}
          >
            Retry
          </button>
        </div>
      );
    }

    if (errored) {
      return (
        <div className='flex-col gap-2 items-start p-5'>
          <div className=''>
            <div className=''>Failed to load the questionnaire.</div>
            <div className='font-bold'>Please try again later.</div>
          </div>
          <button
            className='btn btn-primary'
            onClick={() => {
              window.location.reload();
            }}
          >
            Retry
          </button>
          <div className='font-bold opacity-60 text-sm'>
            {"We've notified the team to resolve potential issues."}
          </div>

          <div className='opacity-60 text-base'>
            <div className='font-bold'>Possible causes</div>
            <div className=''>Network issue</div>
            <div className=''></div>
          </div>
        </div>
      );
    }

    return children || <Outlet />;
  }

  return (
    <div dir={dir} className='h-full'>
      {openLocale && <ModalLocale />}
      <div
        className={classNames(
          'container m-auto flex-col h-full min-h-full',
          //
          'max-w-xl',
          'lg:py-4',
          'py-auto',
        )}
      >
        <div
          className={classNames(
            'flex-col flex-1',
            'lg:border-black lg:border-[1rem] lg:rounded-3xl',
          )}
        >
          {/* contents */}
          <div className='py-3 flex-1 flex'>{renderContent()}</div>
        </div>

        <div
          className={classNames(
            'lg:hidden',
            'top-0 left-0 absolute w-full h-full bg-black bg-opacity-40',
            menuOpen ? '' : 'hidden',
          )}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div
            className={classNames('absolute bg-white h-full w-[200px] right-0')}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className='flex justify-end mb-2'>
              <button
                className='btn btn-ghost pt-6 pr-6'
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                <GrClose size={33} />
              </button>
            </div>
            <ul className='flex flex-col'>
              {/* <!-- Sidebar content here --> */}
              <li className='p-5'>
                <a className='justify-end text-lg'>Sidebar Item 1</a>
              </li>
              <li className='p-5'>
                <a className='justify-end text-lg'>Sidebar Item 1</a>
              </li>
              <li className='p-5'>
                <a className='justify-end text-lg'>Sidebar Item 1</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientLayout;
