import { useApolloClient } from '@apollo/client';
import {
  Enum_Staff_Jobfunction,
  Enum_Staff_Role,
  MeQuery,
  notNull,
  OrgConfigurationValue,
  useLogoutMutation,
  useMeLazyQuery,
} from '@cyren/common-lib';
import produce from 'immer';
import { first } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useAppConfig from '../app/use-app-config';
import { AppStateCookie } from '../app/use-app-state';
import { convStaff } from '../gql-tools/utils/convert';
import { setAccessToken } from '../graphql/client';
import { useAdminParams } from '../survey/use-admin-params';
import { onError } from '../utils/apollo-utils';
import { AuthState } from './auth-state';

export default function useAuth({
  navigateToAuthed,
  navigateToUnAuthed,
}: {
  navigateToAuthed?: string;
  navigateToUnAuthed?: string;
} = {}) {
  const loc = useLocation();
  const [sParams] = useSearchParams();
  const { orgIdParam } = useAdminParams();

  const client = useApolloClient();
  const nav = useNavigate();

  const [appConfig, setAppConfig] = useAppConfig();
  const { authLoading, authInit } = appConfig || {};
  const [authState, setAuthState] = useRecoilState(AuthState);
  const { profiles, selectedProfile, user, jobFunctionOverride } = authState || {};
  const setAppStateCookie = useSetRecoilState(AppStateCookie);

  const [logoutMutation] = useLogoutMutation({
    onError,
    onCompleted: () => {
      client.clearStore();
    },
  });

  /**
   * Navigate to user to somewhere when authed
   */
  useEffect(() => {
    if (navigateToAuthed && authInit && authState?.jwt) {
      nav(`${navigateToAuthed}${sParams ? `?${sParams}` : ''}`);
    }

    if (navigateToUnAuthed && authInit && !authState?.jwt) {
      nav(`${navigateToUnAuthed}${sParams ? `?${sParams}` : ''}`);
    }
  }, [authInit, authState?.jwt, navigateToAuthed, navigateToUnAuthed]);

  const handleLogin = async (resp: MeQuery['meV2']) => {
    const { jwt, user: nUser, staffs: rStaff, orgConfigurations } = resp || {};

    setAccessToken(jwt || authState?.jwt);

    const nStaffs = rStaff?.data?.map(convStaff).filter(notNull);
    const urlSelectedProfile = nStaffs?.find((st) => st.organization?.id === orgIdParam);
    const nProfile = urlSelectedProfile || first(nStaffs);

    setAuthState({
      jwt: jwt || authState?.jwt,
      user: nUser,
      profiles: nStaffs,
      selectedProfile: nProfile,
      orgConfigurationValues: (orgConfigurations as OrgConfigurationValue[]) || []
    });

    if (['/admin', '/admin/'].includes(loc.pathname)) {
      nav(`/admin/${nProfile?.organization?.id}`);
    }
  };

  const [fetchMe, { loading }] = useMeLazyQuery({
    fetchPolicy: 'cache-first',
    // this line triggers refetching every time gql query contains UserPermissionsUser fragment.
    // fetchPolicy: 'cache-and-network',
    onCompleted: async (data) => {
      setAppConfig({ authInit: true, authLoading: false });

      if (data?.meV2) {
        await handleLogin(data.meV2);
      }
    },
  });

  // auth initialize
  useEffect(() => {
    if (!loading && !authLoading && !authInit) {
      setAppConfig({ authLoading: true, authInit: false });

      fetchMe({
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [setAuthState, authLoading, authInit, loading]);

  const handleLogout = useCallback(async () => {
    await logoutMutation({
      onCompleted: () => {
        setAccessToken(null);
        client.clearStore();

        setAppConfig({ authLoading: false, authInit: false });
        setAuthState(null);

        setAppStateCookie((st) =>
          produce(st, (dr) => {
            dr.msAuth = false;
          })
        );
      },
    });
  }, [client, setAccessToken, setAppConfig, setAuthState, logoutMutation]);

  const setSelectedProfile = (
    profileId: string,
    { basePath = '/admin' }: { basePath?: string } = {}
  ) => {
    const nSelectedProfile = profiles?.find((p) => p.id === profileId);
    const orgId = nSelectedProfile?.organization?.id;

    setAuthState({
      ...authState,
      selectedProfile: nSelectedProfile,
    });

    nav(`${basePath}/${orgId}`);
  };

  const setJobFunctionOverride = (
    newJobFunctionOverride: Enum_Staff_Jobfunction
  ) => {
    setAuthState({
      ...authState,
      jobFunctionOverride: newJobFunctionOverride,
    });
  };


  const getOrgConfigurationValue = (key: string) => {
    return authState?.orgConfigurationValues?.filter(config => config.orgId === selectedProfile?.organization?.id)
    .find(config => config.key === key);
  }

  return [
    {
      isLoggedIn: !!authState?.jwt,
      authInit,
      authLoading,
      selectedProfile,
      selectedOrg: selectedProfile?.organization,
      orgId: selectedProfile?.organization?.id,
      isAdmin: selectedProfile?.role === Enum_Staff_Role.Admin,
      isSuperAdmin: user?.superAdmin === true,
      jobFunctionOverride,
      jobFunction: jobFunctionOverride || selectedProfile?.jobFunction,
      userId: user?.id,
      profiles,
      loading: authLoading || !authInit || loading,
      ...authState,
      auth: authState?.user,
      authState,
    },
    {
      setSelectedProfile,
      setJobFunctionOverride,
      handleLogin,
      handleLogout,
      navigate: nav,
      nav,
      setAuthState,
      setAppConfig,
      getOrgConfigurationValue,
    },
  ] as const;
}
